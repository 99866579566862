export class UValueBasedElementPreparer {
  static MAX_WIDTH = 50;

  static prepare = element => {
    const heatTransferResistance = 1 / element.u0;
    if (element.width === -1) {
      if (element.uValue >= 0) {
        if (element.uValue > 0) {
          let ratio = 1.0 / element.uValue - heatTransferResistance;
          if (ratio <= 0) {
            element.width = 0;
          } else {
            element.width = Math.round(
              Math.min(
                element.heatTransfer * ratio * 100,
                element.getMaxWidth()
              )
            );
          }
        } else {
          element.width = element.getMaxWidth();
        }
      }
    }

    if (element.uValue === -1 && element.width >= 0) {
      element.uValue =
        1 /
        (heatTransferResistance + element.width / (100 * element.heatTransfer));
    }
    return element;
  };
}

export class EnergyClass {
  _fromValue;
  _toValue;
  _code;
  _description;
  _color;
  _separateIconUrl;

  constructor(fromValue, toValue, code, description, color, separateIconUrl) {
    this._fromValue = fromValue;
    this._toValue = toValue;
    this._code = code;
    this._description = description;
    this._color = color;
    this._separateIconUrl = separateIconUrl;
  }

  contains = value => value >= this._fromValue && value < this._toValue;

  get fromValue() {
    return this._fromValue;
  }

  get toValue() {
    return this._toValue;
  }

  get code() {
    return this._code;
  }

  get description() {
    return this._description;
  }

  get color() {
    return this._color;
  }

  get separateIconUrl() {
    return this._separateIconUrl;
  }
}

import { DataContextValidator } from "../DataContextValidator";
import { StateConstants } from "../../constants/StateConstants";
import { StateDataContext } from "../StateDataContext";
import { FlatDataContextBuilder } from "./FlatDataContextBuilder";

export class CachedDataContextManager {
  static UNIQUE_NAME = "pl.znr.heatmaster.cookie";

  cacheWritten = false;

  calculationStateController;
  dataContextValidator = new DataContextValidator();

  constructor(calculationStateController) {
    this.calculationStateController = calculationStateController;
    this.registerClassAliases();
  }

  clearCache = () => {
    localStorage.clear();
  };

  readCache = () => {
    const state = localStorage.getItem("state");
    const environmentalData = localStorage.getItem("environmentalData");
    const flatDataContextRef = localStorage.getItem("ekodom");
    const flatDataContextNew = localStorage.getItem("ekodomNew");
    if (flatDataContextRef == null && flatDataContextNew == null) {
      return null;
    }

    return this.initStateContext(
      flatDataContextRef,
      flatDataContextNew,
      state,
      environmentalData
    );
  };

  initStateContext = (
    flatDataContextRef,
    flatDataContextNew,
    state,
    environmentalData
  ) => {
    let result = new StateDataContext();

    result.referenceDataContext = this.validateAndGetDataContext(
      JSON.parse(flatDataContextRef)
    );
    result.newDataContext =
      flatDataContextNew != null
        ? this.validateAndGetDataContext(JSON.parse(flatDataContextNew))
        : null;
    result.state = state;
    result.environmentalData = environmentalData;
    return result;
  };

  writeCache = () => {
    const flatDataContext = FlatDataContextBuilder.buildFlatDataContext();
    if (this.calculationStateController.isInReferenceOrInitialState()) {
      localStorage.setItem("ekodom", JSON.stringify(flatDataContext));
    } else {
      localStorage.setItem("ekodomNew", JSON.stringify(flatDataContext));
    }
    localStorage.setItem(
      "state",
      this.calculationStateController.getCurrentState()
    );

    this.cacheWritten = true;
  };

  writeDataToBothCache = data => {
    let flatDataContextRef = JSON.parse(localStorage.getItem("ekodom"));
    let flatDataContextNew = JSON.parse(localStorage.getItem("ekodomNew"));
    const state = localStorage.getItem("state");
    const environmentalData = localStorage.getItem("environmentalData");
    flatDataContextRef = JSON.stringify({ ...flatDataContextRef, ...data });
    if (flatDataContextNew != null) {
      flatDataContextNew = JSON.stringify({ ...flatDataContextNew, ...data });
      localStorage.setItem("ekodomNew", flatDataContextNew);
    }
    localStorage.setItem("ekodom", flatDataContextRef);
    const results = this.initStateContext(
      flatDataContextRef,
      flatDataContextNew,
      state,
      environmentalData
    );
    this.calculationStateController.initStateController(results);
  };

  writeToSelectedStateCache = (data, state) => {
    let flatDataContextRef = localStorage.getItem("ekodom");
    let flatDataContextNew = localStorage.getItem("ekodomNew");
    let environmentalData = localStorage.getItem("environmentalData");

    if (StateConstants.isInitialOrReference(state)) {
      flatDataContextRef = JSON.parse(flatDataContextRef);
      flatDataContextRef = JSON.stringify({ ...flatDataContextRef, ...data });

      localStorage.setItem("ekodom", flatDataContextRef);
    } else {
      flatDataContextNew = JSON.parse(flatDataContextNew);
      flatDataContextNew = JSON.stringify({ ...flatDataContextNew, ...data });
      localStorage.setItem("ekodomNew", flatDataContextNew);
    }
    const results = this.initStateContext(
      flatDataContextRef,
      flatDataContextNew,
      state,
      environmentalData
    );
    this.calculationStateController.initStateController(results);
  };

  writeEnvironmentalData = environmentalData => {
    localStorage.setItem(
      "environmentalData",
      JSON.stringify(environmentalData)
    );
  };

  writeState = state => {
    localStorage.setItem("state", state);
  };

  isCacheWritten = () => {
    return this.cacheWritten;
  };

  validateAndGetDataContext = flatDataContext => {
    return FlatDataContextBuilder.buildDataContext(flatDataContext);
  };

  registerClassAliases = () => {};
}

export class BusinessConfiguration {
  _countryItems;
  _dictionaryConfig;
  _polishExchangeRate;

  _countryRegionCode;
  _regionItem;
  _countryItem;

  get countryItems() {
    return this._countryItems;
  }

  set countryItems(value) {
    this._countryItems = value;
  }

  get dictionaryConfig() {
    return this._dictionaryConfig;
  }

  set dictionaryConfig(value) {
    this._dictionaryConfig = value;
  }

  get polishExchangeRate() {
    return this._polishExchangeRate;
  }

  set polishExchangeRate(value) {
    this._polishExchangeRate = value;
  }

  get countryRegionCode() {
    return this._countryRegionCode;
  }

  set countryRegionCode(value) {
    this._countryRegionCode = value;
  }

  get regionItem() {
    return this._regionItem;
  }

  set regionItem(value) {
    this._regionItem = value;
  }

  get countryItem() {
    return this._countryItem;
  }

  set countryItem(value) {
    this._countryItem = value;
  }
}

export class HeatingSourceData {
  _heatingSource;
  _heatingType;
  _heatingSystem;
  _heatingFuel;
  _heatingEfficiency;
  heatingSystemEfficiency;
  _coolingEfficiency;
  _subdevicesEnergyConsumption;
  _waterSubdevicesEnergyConsumption;

  _heatingTypeId;

  _pricePerKWh;
  _naturalUnitPrice;
  _naturalUnit;
  _calorificValue;
  _calorificValueUnit;
  _heatingValueEditable;
  _showNaturalUnit;

  _waterHeatingType;
  _waterHeatingPlacement;
  _waterHeatingTypeId;
  _waterHeatingSource;
  _waterHeatingEfficiency;
  _waterPricePerKWh;

  _finalToPrimaryCoefficient;
  _warmWaterFinalToPrimaryCoefficient;

  _emissionCoefficient;
  _waterEmissionCoefficient;
  _waterNaturalUnitPrice;
  _waterHeatingFuel;
  _waterSavings;

  _electricityPricePerKWh;

  get heatingTypeId() {
    return this._heatingTypeId;
  }

  set heatingTypeId(value) {
    this._heatingTypeId = value;
  }

  get heatingType() {
    return this._heatingType;
  }

  set heatingType(value) {
    this._heatingType = value;
  }

  get heatingEfficiency() {
    return this._heatingEfficiency;
  }

  set heatingEfficiency(value) {
    this._heatingEfficiency = value;
  }

  get pricePerKWh() {
    return this._pricePerKWh;
  }

  set pricePerKWh(value) {
    this._pricePerKWh = value;
  }

  get waterHeatingType() {
    return this._waterHeatingType;
  }

  set waterHeatingType(value) {
    this._waterHeatingType = value;
  }

  get waterHeatingPlacement() {
    return this._waterHeatingPlacement;
  }

  set waterHeatingPlacement(value) {
    this._waterHeatingPlacement = value;
  }

  get waterHeatingEfficiency() {
    return this._waterHeatingEfficiency;
  }

  set waterHeatingEfficiency(value) {
    this._waterHeatingEfficiency = value;
  }

  get waterPricePerKWh() {
    return this._waterPricePerKWh;
  }

  set waterPricePerKWh(value) {
    this._waterPricePerKWh = value;
  }

  get finalToPrimaryCoefficient() {
    return this._finalToPrimaryCoefficient;
  }

  set finalToPrimaryCoefficient(value) {
    this._finalToPrimaryCoefficient = value;
  }

  get warmWaterFinalToPrimaryCoefficient() {
    return this._warmWaterFinalToPrimaryCoefficient;
  }

  set warmWaterFinalToPrimaryCoefficient(value) {
    this._warmWaterFinalToPrimaryCoefficient = value;
  }

  get emissionCoefficient() {
    return this._emissionCoefficient;
  }

  set emissionCoefficient(value) {
    this._emissionCoefficient = value;
  }

  get waterEmissionCoefficient() {
    return this._waterEmissionCoefficient;
  }

  set waterEmissionCoefficient(value) {
    this._waterEmissionCoefficient = value;
  }

  get naturalUnitPrice() {
    return this._naturalUnitPrice;
  }

  set naturalUnitPrice(value) {
    this._naturalUnitPrice = value;
  }

  get naturalUnit() {
    return this._naturalUnit;
  }

  set naturalUnit(value) {
    this._naturalUnit = value;
  }

  get calorificValue() {
    return this._calorificValue;
  }

  set calorificValue(value) {
    this._calorificValue = value;
  }

  get calorificValueUnit() {
    return this._calorificValueUnit;
  }

  set calorificValueUnit(value) {
    this._calorificValueUnit = value;
  }

  get heatingSource() {
    return this._heatingSource;
  }

  set heatingSource(value) {
    this._heatingSource = value;
  }

  get heatingSystem() {
    return this._heatingSystem;
  }

  set heatingSystem(value) {
    this._heatingSystem = value;
  }

  get heatingFuel() {
    return this._heatingFuel;
  }

  set heatingFuel(value) {
    this._heatingFuel = value;
  }

  get coolingEfficiency() {
    return this._coolingEfficiency;
  }

  set coolingEfficiency(value) {
    this._coolingEfficiency = value;
  }

  get waterHeatingTypeId() {
    return this._waterHeatingTypeId;
  }

  set waterHeatingTypeId(value) {
    this._waterHeatingTypeId = value;
  }

  get waterHeatingSource() {
    return this._waterHeatingSource;
  }

  set waterHeatingSource(value) {
    this._waterHeatingSource = value;
  }

  get heatingValueEditable() {
    return this._heatingValueEditable;
  }

  set heatingValueEditable(value) {
    this._heatingValueEditable = value;
  }

  get showNaturalUnit() {
    return this._showNaturalUnit;
  }

  set showNaturalUnit(value) {
    this._showNaturalUnit = value;
  }

  get subdevicesEnergyConsumption() {
    return this._subdevicesEnergyConsumption;
  }

  set subdevicesEnergyConsumption(value) {
    this._subdevicesEnergyConsumption = value;
  }

  get waterSubdevicesEnergyConsumption() {
    return this._waterSubdevicesEnergyConsumption;
  }

  set waterSubdevicesEnergyConsumption(value) {
    this._waterSubdevicesEnergyConsumption = value;
  }

  get waterNaturalUnitPrice() {
    return this._waterNaturalUnitPrice;
  }

  set waterNaturalUnitPrice(value) {
    this._waterNaturalUnitPrice = value;
  }

  get waterHeatingFuel() {
    return this._waterHeatingFuel;
  }

  set waterHeatingFuel(value) {
    this._waterHeatingFuel = value;
  }

  get waterSavings() {
    return this._waterSavings;
  }

  set waterSavings(value) {
    this._waterSavings = value;
  }

  get electricityPricePerKWh() {
    return this._electricityPricePerKWh;
  }

  set electricityPricePerKWh(value) {
    this._electricityPricePerKWh = value;
  }
}

import {
  UPDATE_HEATING,
  UPDATE_HEATING_BY_ID,
  UPDATE_HEATING_AND_WATER,
  UPDATE_WATER,
  UPDATE_COLLECTORS,
  UPDATE_HEATING_ALL,
  UPDATE_DISTRIBUTION,
} from "../actionTypes";

const initialState = {
  heatingSource: "hm.dropdown.heating.source.solid_fuel_boiler",
  heatingType: "hm.dropdown.heating.source.new_type",
  heatingSystem: "hm.label.heating.distribution.heater",
  heatingFuel: "hm.dropdown.heating.fuel.coal_better",
  heatingEfficiency: 70,
  heatingSystemEfficiency: 70,
  coolingEfficiency: null,

  subdevicesEnergyConsumption: 1,

  heatingTypeId: "hm.heating-source.boiler_gas_condensing",
  calorificValue: 26,
  calorificValueUnit: "MJ/kg",
  heatingValueEditable: true,
  showNaturalUnit: true,
  naturalUnit: "hm.natural_unit.tonne",
  naturalUnitPrice: 218.208267669,
  naturalUnitPriceLocal: 900,
  pricePerKWh: 0.12461538461538461,
  finalToPrimaryCoefficient: 1.1,
  emissionCoefficient: 0.338,

  waterHeatingTypeId: "hm.heating-source.boiler_gas_condensing",
  waterHeatingSource: "hm.dropdown.heating.source.the_same",
  waterHeatingPlacement: "hm.dropdown.heating.location.bathroom",
  waterHeatingEfficiency: 65,
  waterHeatingFuel: "hm.dropdown.heating.fuel.coal_better",
  waterPricePerKWh: 0.12461538461538461,
  warmWaterFinalToPrimaryCoefficient: 1.1,
  waterEmissionCoefficient: 0.338,

  waterSubdevicesEnergyConsumption: 0,

  warmWaterDistributionId: "hm.water-distribution.well_insulated_pipes",
  warmWaterStorageId: "hm.water-storage.heat_node_without_storage",
  distributorEfficiency: 0.95,
  storeEfficiency: 1,

  consumptionIntensityId: "hm.warmwater.consuption.standard",
  waterSavings: false,

  collectorsTypeId: "hm.solar-collector.none",
  collectorsSurface: 4,
  collectorsCount: 4,

  includeWarmWater: true,

  maxPower: 1600,

  electricityPricePerKWh: 0.65,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_HEATING: {
      const heatingData = action.payload.heatingData;
      return {
        ...state,
        heatingTypeId: heatingData.heatingType.id,
        calorificValue: heatingData.calorificValue,
        calorificValueUnit: heatingData.calorificValueUnit,
        naturalUnitPrice: heatingData.naturalUnitPrice,
        heatingEfficiency: heatingData.heatingEfficiency,
        pricePerKWh: heatingData.pricePerKWh,
        finalToPrimaryCoefficient: heatingData.finalToPrimaryCoefficient,
        emissionCoefficient: heatingData.emissionCoefficient,
      };
    }
    case UPDATE_HEATING_ALL: {
      const heatingData = action.payload.heatingData;
      return {
        ...state,
        ...heatingData,
      };
    }
    case UPDATE_WATER: {
      const heatingData = action.payload.heatingData;
      return {
        ...state,
        waterHeatingTypeId: heatingData.waterHeatingType.id,
        waterHeatingEfficiency: heatingData.waterHeatingEfficiency,
        waterPricePerKWh: heatingData.waterPricePerKWh,
        warmWaterFinalToPrimaryCoefficient:
          heatingData.warmWaterFinalToPrimaryCoefficient,
        waterEmissionCoefficient: heatingData.waterEmissionCoefficient,
      };
    }
    case UPDATE_COLLECTORS: {
      const collectorsData = action.payload.collectorsData;
      return {
        ...state,
        collectorsTypeId: collectorsData.collectorType.id,
        collectorsSurface: collectorsData.surface,
        collectorsCount: collectorsData.count,
      };
    }
    case UPDATE_HEATING_AND_WATER: {
      const heatingData = action.payload.heatingData;
      return {
        ...state,
        heatingTypeId: heatingData.heatingType.id,
        calorificValue: heatingData.calorificValue,
        calorificValueUnit: heatingData.calorificValueUnit,
        naturalUnitPrice: heatingData.naturalUnitPrice,
        heatingEfficiency: heatingData.heatingEfficiency,
        pricePerKWh: heatingData.pricePerKWh,
        finalToPrimaryCoefficient: heatingData.finalToPrimaryCoefficient,
        emissionCoefficient: heatingData.emissionCoefficient,

        waterHeatingTypeId: heatingData.waterHeatingType.id,
        waterHeatingEfficiency: heatingData.waterHeatingEfficiency,
        waterPricePerKWh: heatingData.waterPricePerKWh,
        warmWaterFinalToPrimaryCoefficient:
          heatingData.warmWaterFinalToPrimaryCoefficient,
        waterEmissionCoefficient: heatingData.waterEmissionCoefficient,
      };
    }
    case UPDATE_DISTRIBUTION: {
      const warmWaterData = action.payload.heatingData;
      return {
        ...state,
        warmWaterDistributionId: warmWaterData.warmWaterDistribution,
        warmWaterStorageId: warmWaterData.warmWaterStorage,
        distributorEfficiency: warmWaterData.distributorEfficiency,
        storeEfficiency: warmWaterData.storeEfficiency,
      };
    }
    case UPDATE_HEATING_BY_ID: {
      const { id, value } = action.payload;
      return {
        ...state,
        [id]: value,
      };
    }
    default:
      return state;
  }
}

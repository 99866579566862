import {
  DELETE_HOUSE_WALL,
  UPDATE_HOUSE,
  UPDATE_HOUSE_BY_ID,
  UPDATE_SURFACE,
  ADD_HOUSE_WALL,
  UPDATE_HOUSE_WALL,
  UPDATE_HOUSE_ROOF_BY_ID,
  UPDATE_HOUSE_ROOF,
  UPDATE_HOUSE_BASEMENT_BY_ID,
  UPDATE_HOUSE_BASEMENT,
  REPLACE_HOUSE_WALLS,
} from "../actionTypes";
import { BuildingAge } from "../../config/dictionary/model/BuildingAge";
import { Directions } from "../../constants/Directions";
import { RoofType } from "../../constants/RoofType";
import { BasementType } from "../../constants/BasementType";

const initialState = {
  buildingType: "current",
  country: "pl",
  region: false,
  location: false,
  houseType: 1,
  standardTypeId: "hm.standard_type.typical",
  buildingAgeId: BuildingAge.BETWEEN_1984_1993,
  showDetailedData: false,
  detailedDataChanged: false,
  inHouseTemp: 20,
  surfaceData: {
    floorCount: 1,
    floorHeight: 2.7,
    totalSurface: 120,

    floorArea: 120 * 1.12,
    floorSurface: 120 * 1.12,
    floorSurfaceC: 92.0275944121938,
    floorSurfaceR: 42.3724055878062,

    roofSurface: 120 * 1.12,
    atticSurface: 120 * 1.12,
    wallSurface: 99.696,
    wallsCount: 4,

    atticWallSurface: 0,
    roofWallSurface: 0,
    insulatedWallSurface: 99.696,
    nonInsulatedWallSurface: 0,
    insulatedAtticWall: 0,
    nonInsulatedAtticWall: 0,

    windowsSurface: 26.88,
    windowsSurfaceS: 12.096,
    windowsSurfaceWE: 10.752,
    windowsSurfaceN: 4.032,
  },
  wallsData: [
    {
      width: 36.288,
      direction: Directions.NORTH,
      window: 4.032,
      insulated: true,
    },
    {
      width: 27,
      direction: Directions.EAST,
      window: 5.376,
      insulated: true,
    },
    {
      width: 36.288,
      direction: Directions.SOUTH,
      window: 12.096,
      insulated: true,
    },
    {
      width: 27,
      direction: Directions.WEST,
      window: 5.376,
      insulated: true,
    },
  ],
  roofData: {
    type: RoofType.GABLE,
    direction: Directions.SOUTH,
    angle: 35,
    attic: 0,
    kneewall: 1.3,
  },
  basement: {
    enabled: false,
    type: BasementType.HEATED,
    height: 0,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SURFACE: {
      const surfaceData = action.payload;
      return {
        ...state,
        surfaceData: surfaceData,
      };
    }
    case UPDATE_HOUSE: {
      const houseData = action.payload.houseData;
      return {
        ...state,
        ...houseData,
      };
    }
    case UPDATE_HOUSE_BY_ID: {
      const { id, value } = action.payload;
      return {
        ...state,
        [id]: value,
      };
    }
    case UPDATE_HOUSE_ROOF_BY_ID: {
      const { id, value } = action.payload;
      return {
        ...state,
        roofData: {
          ...state.roofData,
          [id]: value,
        },
      };
    }
    case UPDATE_HOUSE_ROOF: {
      const roofData = action.payload.roofData;
      return {
        ...state,
        roofData: {
          ...state.roofData,
          ...roofData,
        },
      };
    }
    case UPDATE_HOUSE_BASEMENT_BY_ID: {
      const { id, value } = action.payload;
      return {
        ...state,
        basement: {
          ...state.basement,
          [id]: value,
        },
      };
    }
    case UPDATE_HOUSE_BASEMENT: {
      const basementData = action.payload.basementData;
      return {
        ...state,
        basement: {
          ...state.basement,
          ...basementData,
        },
      };
    }
    case ADD_HOUSE_WALL: {
      return {
        ...state,
        detailedDataChanged: true,
        wallsData: [
          ...state.wallsData,
          {
            width: 15,
            direction: Directions.WEST,
            window: 0,
            insulated: true,
          },
        ],
      };
    }
    case UPDATE_HOUSE_WALL: {
      const { index, direction, width, window, insulated } = action.payload;
      return {
        ...state,
        detailedDataChanged: true,
        wallsData: state.wallsData.map((wall, i) =>
          i === index
            ? {
                ...wall,
                direction: direction,
                width: width,
                window: window,
                insulated: insulated,
              }
            : wall
        ),
      };
    }
    case DELETE_HOUSE_WALL: {
      const { index } = action.payload;
      return {
        ...state,
        detailedDataChanged: true,
        wallsData: state.wallsData.filter(
          item => item !== state.wallsData[index]
        ),
      };
    }
    case REPLACE_HOUSE_WALLS: {
      const { wallsData } = action.payload;
      return {
        ...state,
        wallsData: wallsData,
      };
    }

    default:
      return state;
  }
}

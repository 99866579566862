import { InsolationData } from "./InsolationData";

export class EnvironmentalData {
  _insolationData = new InsolationData();
  _minCalcTemp;
  _temperatures = [];
  _groundTemperatures = [];
  _tAvg = -1;
  _tMin = -1;
  _tMax = -1;
  _tSigma = -1;

  static copy(envData) {
    return Object.assign({}, envData);
  }

  get insolationData() {
    return this._insolationData;
  }

  set insolationData(value) {
    this._insolationData = value;
  }

  get temperatures() {
    return this._temperatures;
  }

  set temperatures(value) {
    this._temperatures = value;
  }

  get groundTemperatures() {
    return this._groundTemperatures;
  }

  set groundTemperatures(value) {
    this._groundTemperatures = value;
  }

  get tAvg() {
    return this._tAvg;
  }

  set tAvg(value) {
    this._tAvg = value;
  }

  get tMin() {
    return this._tMin;
  }

  set tMin(value) {
    this._tMin = value;
  }

  get tMax() {
    return this._tMax;
  }

  set tMax(value) {
    this._tMax = value;
  }

  get tSigma() {
    return this._tSigma;
  }

  set tSigma(value) {
    this._tSigma = value;
  }
}

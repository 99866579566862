import React from "react";
import { FormattedMessage } from "react-intl";

export const DropDownList = React.memo(props => {
  const { selectedIndex, disabled, dataProvider, id } = props;

  return (
    <select
      id={id}
      className={"form-control pointer"}
      onChange={e => {
        props.onChange(e.target.value);
      }}
      value={selectedIndex}
      disabled={disabled}
    >
      {dataProvider.map(value => (
        <FormattedMessage id={value.name} key={value.name}>
          {message => <option value={value.data.id}>{message}</option>}
        </FormattedMessage>
      ))}
    </select>
  );
});

DropDownList.displayName = "DropDownList";

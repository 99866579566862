import { OptimizerResultsItem } from "./OptimizerResultsItem";

export class OptimizerResults {
  heatingSource = new OptimizerResultsItem();
  waterHeatingSource = new OptimizerResultsItem();
  heatingFuel = new OptimizerResultsItem();
  doorType = new OptimizerResultsItem();
  foundationType = new OptimizerResultsItem();
  ventilationType = new OptimizerResultsItem();
  airTightness = new OptimizerResultsItem();
  waterDistribution = new OptimizerResultsItem();
  waterStorage = new OptimizerResultsItem();
  windows = new OptimizerResultsItem();
  thermalBridges = new OptimizerResultsItem();
  roofInsulation = new OptimizerResultsItem();
  wallInsulation = new OptimizerResultsItem();
  floorInsulation = new OptimizerResultsItem();
  basementFloorInsulation = new OptimizerResultsItem();
  basementWallInsulation = new OptimizerResultsItem();
  pvType = new OptimizerResultsItem();
  shutters = new OptimizerResultsItem();
  airConditioning = new OptimizerResultsItem();
  ventilation = new OptimizerResultsItem();
  collectors = new OptimizerResultsItem();
  waterSavings = new OptimizerResultsItem();
  sum = 0;
}

import { HouseType } from "../constants/combo/HouseType";

export class HouseTypeHelper {
  static blockTypes = [
    HouseType.BLOCK_CENTER,
    HouseType.BLOCK_EDGE_RIGHT,
    HouseType.BLOCK_EDGE_LEFT,
    HouseType.BLOCK_CENTER_UPPER,
    HouseType.BLOCK_CENTER_LOWER,
    HouseType.BLOCK_UPPER_RIGHT,
    HouseType.BLOCK_UPPER_LEFT,
    HouseType.BLOCK_LOWER_LEFT,
    HouseType.BLOCK_LOWER_RIGHT,

    HouseType.BLOCK_DOUBLE_CENTER,
    HouseType.BLOCK_DOUBLE_EDGE_RIGHT,
    HouseType.BLOCK_DOUBLE_EDGE_LEFT,
    HouseType.BLOCK_DOUBLE_CENTER_UPPER,
    HouseType.BLOCK_DOUBLE_CENTER_LOWER,
    HouseType.BLOCK_DOUBLE_UPPER_RIGHT,
    HouseType.BLOCK_DOUBLE_UPPER_LEFT,
    HouseType.BLOCK_DOUBLE_LOWER_LEFT,
    HouseType.BLOCK_DOUBLE_LOWER_RIGHT,
  ];

  static multiFamilyTypes = [
    HouseType.MULTI_FAMILY_CENTER,
    HouseType.MULTI_FAMILY_FIRST_FLOOR,
    HouseType.MULTI_FAMILY_UPPER,
  ];

  static terraceTypes = [
    HouseType.TERRACED_CENTER,
    HouseType.TERRACED_LEFT,
    HouseType.TERRACED_RIGHT,
  ];

  static detachedTypes = [HouseType.DETACHED];

  static isBlockType = type =>
    type === HouseType.BLOCK_CENTER ||
    type === HouseType.BLOCK_CENTER_UPPER ||
    type === HouseType.BLOCK_CENTER_LOWER ||
    type === HouseType.BLOCK_EDGE_RIGHT ||
    type === HouseType.BLOCK_EDGE_LEFT ||
    type === HouseType.BLOCK_LOWER_RIGHT ||
    type === HouseType.BLOCK_LOWER_LEFT ||
    type === HouseType.BLOCK_UPPER_RIGHT ||
    type === HouseType.BLOCK_UPPER_LEFT ||
    type === HouseType.BLOCK_UPPER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_CENTER ||
    type === HouseType.BLOCK_DOUBLE_CENTER_UPPER ||
    type === HouseType.BLOCK_DOUBLE_CENTER_LOWER ||
    type === HouseType.BLOCK_DOUBLE_EDGE_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_EDGE_LEFT ||
    type === HouseType.BLOCK_DOUBLE_LOWER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_LOWER_LEFT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_LEFT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_RIGHT;

  static isThinBlock = type =>
    type === HouseType.BLOCK_CENTER ||
    type === HouseType.BLOCK_CENTER_UPPER ||
    type === HouseType.BLOCK_CENTER_LOWER ||
    type === HouseType.BLOCK_EDGE_RIGHT ||
    type === HouseType.BLOCK_EDGE_LEFT ||
    type === HouseType.BLOCK_LOWER_RIGHT ||
    type === HouseType.BLOCK_LOWER_LEFT ||
    type === HouseType.BLOCK_UPPER_RIGHT ||
    type === HouseType.BLOCK_UPPER_LEFT ||
    type === HouseType.BLOCK_UPPER_RIGHT;

  static isDoubleBlock = type =>
    type === HouseType.BLOCK_DOUBLE_CENTER ||
    type === HouseType.BLOCK_DOUBLE_CENTER_UPPER ||
    type === HouseType.BLOCK_DOUBLE_CENTER_LOWER ||
    type === HouseType.BLOCK_DOUBLE_EDGE_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_EDGE_LEFT ||
    type === HouseType.BLOCK_DOUBLE_LOWER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_LOWER_LEFT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_LEFT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_RIGHT;

  static isGroundType = type =>
    type === HouseType.BLOCK_CENTER_LOWER ||
    type === HouseType.BLOCK_LOWER_LEFT ||
    type === HouseType.BLOCK_LOWER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_CENTER_LOWER ||
    type === HouseType.BLOCK_DOUBLE_LOWER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_LOWER_RIGHT ||
    type === HouseType.TERRACED_CENTER ||
    type === HouseType.TERRACED_LEFT ||
    type === HouseType.TERRACED_RIGHT ||
    type === HouseType.MULTI_FAMILY_FIRST_FLOOR ||
    type === HouseType.DETACHED;

  static isSkyType = type =>
    type === HouseType.BLOCK_CENTER_UPPER ||
    type === HouseType.BLOCK_UPPER_LEFT ||
    type === HouseType.BLOCK_UPPER_LEFT ||
    type === HouseType.BLOCK_DOUBLE_CENTER_UPPER ||
    type === HouseType.BLOCK_UPPER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_RIGHT ||
    type === HouseType.TERRACED_CENTER ||
    type === HouseType.TERRACED_LEFT ||
    type === HouseType.TERRACED_RIGHT ||
    type === HouseType.MULTI_FAMILY_UPPER ||
    type === HouseType.DETACHED;

  static hasOnlyWEWindows = type =>
    type === HouseType.BLOCK_CENTER ||
    type === HouseType.BLOCK_CENTER_LOWER ||
    type === HouseType.BLOCK_CENTER_UPPER;

  static isCenterThinBlock = type =>
    type === HouseType.BLOCK_CENTER ||
    type === HouseType.BLOCK_CENTER_LOWER ||
    type === HouseType.BLOCK_CENTER_UPPER;

  static hasOnlyOneOuterWall = type =>
    type === HouseType.BLOCK_DOUBLE_CENTER ||
    type === HouseType.BLOCK_DOUBLE_CENTER_LOWER ||
    type === HouseType.BLOCK_DOUBLE_CENTER_UPPER;

  static hasTwoOuterWalls = type =>
    type === HouseType.BLOCK_CENTER ||
    type === HouseType.BLOCK_CENTER_LOWER ||
    type === HouseType.BLOCK_CENTER_UPPER ||
    (this.isDoubleBlock(type) && !this.hasOnlyOneOuterWall(type));

  static hasThreeOuterWalls = type =>
    this.isThinBlock(type) && !this.isCenterThinBlock(type);

  static isBlockWithOuterRightWall = type =>
    type === HouseType.BLOCK_EDGE_RIGHT ||
    type === HouseType.BLOCK_UPPER_RIGHT ||
    type === HouseType.BLOCK_LOWER_RIGHT;

  static isBlockWithOuterWall = type =>
    type === HouseType.BLOCK_EDGE_RIGHT ||
    type === HouseType.BLOCK_UPPER_RIGHT ||
    type === HouseType.BLOCK_LOWER_RIGHT ||
    type === HouseType.BLOCK_EDGE_LEFT ||
    type === HouseType.BLOCK_UPPER_LEFT ||
    type === HouseType.BLOCK_LOWER_LEFT;

  static isDoubleBlockWithOuterRightWall = type =>
    type === HouseType.BLOCK_DOUBLE_EDGE_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_LOWER_RIGHT;

  static isDoubleBlockWithOuterWall = type =>
    type === HouseType.BLOCK_DOUBLE_EDGE_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_LOWER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_EDGE_LEFT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_LEFT ||
    type === HouseType.BLOCK_DOUBLE_LOWER_LEFT;

  static hasNeighboursUp = type =>
    type === HouseType.BLOCK_CENTER_LOWER ||
    type === HouseType.BLOCK_LOWER_LEFT ||
    type === HouseType.BLOCK_LOWER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_CENTER_LOWER ||
    type === HouseType.BLOCK_DOUBLE_LOWER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_LOWER_RIGHT ||
    type === HouseType.BLOCK_CENTER ||
    type === HouseType.BLOCK_DOUBLE_CENTER ||
    type === HouseType.BLOCK_EDGE_LEFT ||
    type === HouseType.BLOCK_EDGE_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_EDGE_LEFT ||
    type === HouseType.BLOCK_DOUBLE_EDGE_RIGHT ||
    type === HouseType.MULTI_FAMILY_FIRST_FLOOR ||
    type === HouseType.MULTI_FAMILY_CENTER;

  static hasNeighboursDown = type =>
    type === HouseType.BLOCK_CENTER_UPPER ||
    type === HouseType.BLOCK_UPPER_LEFT ||
    type === HouseType.BLOCK_UPPER_LEFT ||
    type === HouseType.BLOCK_DOUBLE_CENTER_UPPER ||
    type === HouseType.BLOCK_UPPER_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_UPPER_RIGHT ||
    type === HouseType.BLOCK_CENTER ||
    type === HouseType.BLOCK_DOUBLE_CENTER ||
    type === HouseType.BLOCK_EDGE_LEFT ||
    type === HouseType.BLOCK_EDGE_RIGHT ||
    type === HouseType.BLOCK_DOUBLE_EDGE_LEFT ||
    type === HouseType.BLOCK_DOUBLE_EDGE_RIGHT ||
    type === HouseType.MULTI_FAMILY_UPPER ||
    type === HouseType.MULTI_FAMILY_CENTER;

  static hasFlatRoof = type => this.isSkyType(type) && this.isBlockType(type);
  static isBlockTop = type => this.isSkyType(type) && this.isBlockType(type);
  static isTerraceCenter = type => type === HouseType.TERRACED_CENTER;

  static hasRegularRoof = type =>
    this.isSkyType(type) && !this.isBlockType(type);

  static hasRoof = type =>
    (this.isSkyType(type) && this.isBlockType(type)) ||
    (this.isSkyType(type) && !this.isBlockType(type));

  static isMultiFamilyType = type => this.multiFamilyTypes.includes(type);

  static isTerraceType = type => this.terraceTypes.includes(type);

  static isDetachedType = type => this.detachedTypes.includes(type);
}

import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import cx from "classnames";

export const LabelSystemBaloon = ({ name, headerText, icon, message }) => {
  const [showBaloon, handleBaloonClick] = useState(window.innerWidth >= 1024);
  return (
    <div
      className={cx(
        "baloon  popover  d-flex align-items-center",
        { hidden: !showBaloon },
        { "living_needs-baloon bs-popover-left": name === "living_needs" },
        {
          "ventilation_electric_system-baloon bs-popover-right":
            name === "ventilation_electric_system",
        },
        {
          "heat_electric_system-baloon bs-popover-right":
            name === "heat_electric_system",
        }
      )}
    >
      <FormattedMessage id={headerText} defaultMessage="">
        {message => (
          <span
            title={message}
            className={"baloon-icon"}
            onClick={() => handleBaloonClick(!showBaloon)}
          >
            <img size={22} src={icon} alt="" className={"as-svg"} />
          </span>
        )}
      </FormattedMessage>

      <div className="arrow m-0" />
      <div className={"popover-body"}>
        <div className={"content"}>
          <div className={"d-flex align-items-center"}>
            <img
              className={"custom-icon mr-2 as-svg"}
              alt=""
              size={16}
              src={icon}
            />
            <FormattedMessage id={headerText} defaultMessage="">
              {message => <h3 className={"mb-0 px-0 col-10"}> {message}</h3>}
            </FormattedMessage>
          </div>
        </div>
        {/* <Popup
          message={message}
          placement={cx({
            left: name === ("ventilation_electric_system" || "living_needs"),
            right: name === "heat_electric_system",
          })}
        /> */}
      </div>
    </div>
  );
};

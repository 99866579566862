export class WarmWaterConsumptionConfig {
  _consumptionIntensities;
  _selectedIndex;

  constructor(consumptionIntensities) {
    this._consumptionIntensities = consumptionIntensities;
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  set selectedIndex(value) {
    this._selectedIndex = value;
  }

  get consumptionIntensities() {
    return this._consumptionIntensities;
  }

  set consumptionIntensities(value) {
    this._consumptionIntensities = value;
  }
}

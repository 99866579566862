export class BaseComboObject {
  _id;
  _labelKey;
  _name;
  _enName;
  _plName;
  _price = 0;
  _priceUnit;
  _work = 0;
  _workUnit;
  _obsolescence = 25;

  constructor(id) {
    this._id = id;
  }

  get id() {
    return this._id;
  }

  get priceUnit() {
    return this._priceUnit;
  }

  set priceUnit(value) {
    this._priceUnit = value;
  }

  get workUnit() {
    return this._workUnit;
  }

  set workUnit(value) {
    this._workUnit = value;
  }

  get price() {
    return this._price;
  }

  set price(value) {
    this._price = value;
  }

  get work() {
    return this._work;
  }

  set work(value) {
    this._work = value;
  }

  get obsolescence() {
    return this._obsolescence;
  }

  set obsolescence(value) {
    this._obsolescence = value;
  }

  set id(value) {
    this._id = value;
  }

  get labelKey() {
    return this._labelKey;
  }

  set labelKey(value) {
    this._labelKey = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get enName() {
    return this._enName;
  }

  set enName(value) {
    this._enName = value;
  }

  get plName() {
    return this._plName;
  }

  set plName(value) {
    this._plName = value;
  }
}

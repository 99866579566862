import { WaterStorageDistributionConfig } from "../WaterStorageDistributionConfig";
import { AbstractDictionaryReader } from "./AbstractDictionaryReader";
import { WarmWaterStorage } from "../model/WarmWaterStorage";
import { WarmWaterDistribution } from "../model/WarmWaterDistribution";

export class WaterStorageDistributionConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    let warmWaterDistributionTypes = this.readWaterDistributionTypes(
      dictionaryXML.warm_water_distribution_types
    );
    let warmWaterStorageTypes = this.readWaterStorageTypes(
      dictionaryXML.warm_water_storage_types
    );

    let waterStorageDistributionConfiguration = new WaterStorageDistributionConfig();
    waterStorageDistributionConfiguration.waterDistributionTypes = warmWaterDistributionTypes;
    waterStorageDistributionConfiguration.waterStorageTypes = warmWaterStorageTypes;
    waterStorageDistributionConfiguration.storageSelectedIndex = this.getSelectedIndex(
      dictionaryXML.warm_water_storage_types
    );
    waterStorageDistributionConfiguration.distributionSelectedIndex = this.getSelectedIndex(
      dictionaryXML.warm_water_distribution_types
    );

    dictionaryConfig.waterStorageDistributionConfiguration = waterStorageDistributionConfiguration;
    return dictionaryConfig;
  };

  readWaterDistributionTypes = waterDistributionTypesXML => {
    let waterDistributionTypes = [];
    waterDistributionTypesXML.warm_water_distribution_type.forEach(
      waterDistributionXML => {
        waterDistributionTypes.push(
          this.readWaterDistributionType(waterDistributionXML)
        );
      }
    );

    return waterDistributionTypes;
  };

  readWaterStorageTypes = waterStorageTypesXML => {
    let waterStorageTypes = [];
    waterStorageTypesXML.warm_water_storage_type.forEach(waterStorageXML => {
      waterStorageTypes.push(this.readWaterStorageType(waterStorageXML));
    });

    return waterStorageTypes;
  };

  readWaterDistributionType = waterDistributionXML => {
    const id = waterDistributionXML.id;
    const efficiency = Number(waterDistributionXML.efficiency);

    let warmWaterDistribution = new WarmWaterDistribution(id, efficiency);
    this.fillBaseProperties(waterDistributionXML, warmWaterDistribution);

    return warmWaterDistribution;
  };

  readWaterStorageType = waterStorageXML => {
    const id = waterStorageXML.id;
    const efficiency = Number(waterStorageXML.efficiency);
    const usage = waterStorageXML.usage;
    let warmWaterStorage = new WarmWaterStorage(id, efficiency, usage);
    this.fillBaseProperties(waterStorageXML, warmWaterStorage);

    return warmWaterStorage;
  };
}

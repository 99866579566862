export class WindowTypeConfig {
  _windowTypes;
  _selectedIndex;

  constructor(windowTypes) {
    this._windowTypes = windowTypes;
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  set selectedIndex(value) {
    this._selectedIndex = value;
  }

  get windowTypes() {
    return this._windowTypes;
  }

  set windowTypes(value) {
    this._windowTypes = value;
  }
}

import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";
import { BasementType } from "../../../constants/BasementType";

export class BasementEnergyCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, dataContext, monthInputData) => {
    let houseData = dataContext.houseData;
    let floor = houseData.basementFloorElement;
    let walls = houseData.basementWallElement;
    let wallsData = houseData.wallsData;
    let basementData = houseData.basement;
    let tAvg = dataContext.environmentalData.tAvg;

    energyData.enBasement = 0;
    if (basementData.enabled && basementData.type === BasementType.HEATED) {
      let enBasementFloor = EnergyCalcHelper.calcHeatTransfer(
        floor.uValue,
        houseData.surfaceData.floorSurface,
        houseData.tIn,
        monthInputData.tGround
      );
      let enBasementWalls = EnergyCalcHelper.calcHeatTransfer(
        walls.uValue,
        this.calcWallSurface(wallsData) * basementData.height,
        houseData.tIn,
        tAvg
      );
      energyData.enBasement = enBasementFloor + enBasementWalls;
    }
    return energyData;
  };

  calcWallSurface = wallsData => {
    let wallSurface = 0;
    wallsData.map(item => {
      wallSurface += item.width;
      return item;
    });
    return wallSurface;
  };
}

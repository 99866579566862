import { ThermalBridgesTypeConfig } from "../ThermalBridgesTypeConfig";
import { AbstractDictionaryReader } from "./AbstractDictionaryReader";
import { ThermalBridgesType } from "../model/ThermalBridgesType";

export class ThermalBridgesTypeConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    dictionaryConfig.thermalBridgesTypeConfig = this.readThermalBridgesTypeConfig(
      dictionaryXML.thermal_bridges_types
    );
    return dictionaryConfig;
  };

  readThermalBridgesTypeConfig = thermalBridgesTypesXML => {
    let thermalBridgesTypeConfig = new ThermalBridgesTypeConfig();

    thermalBridgesTypeConfig.selectedIndex = Number(
      thermalBridgesTypesXML.selected_index
    );
    let thermalBridgesTypes = [];
    thermalBridgesTypesXML.thermal_bridges_type.forEach(
      thermalBridgesTypeXML => {
        thermalBridgesTypes.push(
          this.readThermalBridgesType(thermalBridgesTypeXML)
        );
      }
    );

    thermalBridgesTypeConfig.thermalBridgesTypes = thermalBridgesTypes;
    return thermalBridgesTypeConfig;
  };

  readThermalBridgesType = thermalBridgesTypeXML => {
    let max = this.readBoolean(thermalBridgesTypeXML, "max");
    let thermalBridgesType = new ThermalBridgesType(
      thermalBridgesTypeXML.id,
      Number(thermalBridgesTypeXML.u_value),
      max
    );
    this.fillBaseProperties(thermalBridgesTypeXML, thermalBridgesType);
    return thermalBridgesType;
  };
}

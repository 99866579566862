export class FoundationsTypeConfig {
  _foundationsType;

  constructor(foundationsType) {
    this._foundationsType = foundationsType;
  }

  get foundationsType() {
    return this._foundationsType;
  }

  set foundationsType(value) {
    this._foundationsType = value;
  }
}

import { StateConstants } from "../../constants/StateConstants";
import { FlatDataContextBuilder } from "../cache/FlatDataContextBuilder";

export class CalculationStateController {
  state = StateConstants.INITIAL_STATE;

  heatMasterController;
  cacheManager;

  calculationStateListeners = [];

  referenceDataContext;
  newStateDataContext;

  referenceProcessingResult;
  newProcessingResult;

  constructor(heatMasterController) {
    this.heatMasterController = heatMasterController;
  }

  initStateController = stateDataContext => {
    this.referenceDataContext = stateDataContext.referenceDataContext;
    this.newStateDataContext = stateDataContext.newDataContext;
    this.setStateAndPropagateStateSwitch(stateDataContext.state);
  };

  freezeReferenceState = dataContext => {
    this.referenceDataContext = dataContext;
    this.newStateDataContext = this.copyDataContext(this.referenceDataContext);
    this.state = StateConstants.NEW_SWITCHED;
    this.cacheManager.writeState(this.state);
    this.heatMasterController.resetDataContext(this.newStateDataContext);
    this.cacheManager.writeCache();
  };

  switchToReferenceState = () => {
    if (this.state === StateConstants.REFERENCE_SWITCHED) {
      return;
    }
    this.state = StateConstants.REFERENCE_SWITCHED;
    this.cacheManager.writeState(this.state);

    this.updateStoreWithDataContext(this.referenceDataContext);

    this.heatMasterController.calculateAndSetViewState(
      this.referenceDataContext
    );
  };

  switchToNewState = () => {
    if (this.state === StateConstants.NEW_SWITCHED) {
      return;
    }
    this.state = StateConstants.NEW_SWITCHED;
    this.cacheManager.writeState(this.state);
    this.updateStoreWithDataContext(this.newStateDataContext);

    this.heatMasterController.calculateAndSetViewState(
      this.newStateDataContext
    );
  };

  addCalculationStateListener = listener => {
    this.calculationStateListeners.push(listener);
  };

  isInReferenceOrInitialState = () => {
    return StateConstants.isInitialOrReference(this.state);
  };

  isInReferenceState = () => {
    return StateConstants.isInReferenceState(this.state);
  };

  getCurrentDataContext = () => {
    if (
      StateConstants.isInitialOrReference(this.state) ||
      this.newStateDataContext == null
    ) {
      return this.referenceDataContext;
    }
    return this.newStateDataContext;
  };

  getPreviousDataContext = () => {
    if (
      this.newStateDataContext == null ||
      this.state === StateConstants.INITIAL_STATE ||
      this.state === StateConstants.NEW_SWITCHED
    ) {
      return this.referenceDataContext;
    }
    return this.newStateDataContext;
  };

  getReferenceDataContext = () => this.referenceDataContext;
  getNewStateDataContext = () => this.newStateDataContext;

  getCurrentState = () => {
    return this.state;
  };

  getPreviousState = () => {
    if (this.isInInitialState()) {
      return StateConstants.INITIAL_STATE;
    }
    if (this.isInReferenceOrInitialState()) {
      return StateConstants.NEW_SWITCHED;
    }
    return StateConstants.REFERENCE_SWITCHED;
  };

  isInInitialState = () => {
    return this.state === StateConstants.INITIAL_STATE;
  };

  isInNewState = () => {
    return this.state === StateConstants.NEW_SWITCHED;
  };

  setCacheManager = cacheManager => {
    this.cacheManager = cacheManager;
  };

  setStateAndPropagateStateSwitch = state => {
    this.state = state;
    if (this.state === StateConstants.NEW_SWITCHED) {
      this.propagateStateSwitched(true);
    }
  };

  propagateStateSwitched = stateFrozen => {
    for (let i = 0; i < this.calculationStateListeners.length; i++) {
      let listener = this.calculationStateListeners[i];
      listener.calculationStateSwitched(stateFrozen);
    }
  };

  copyDataContext = dataContext => {
    return FlatDataContextBuilder.buildDataContext(
      FlatDataContextBuilder.buildFlatDataContext(dataContext)
    );
  };

  updateStoreWithDataContext = dataContext => {
    return FlatDataContextBuilder.updateStoreWithDataContext(dataContext);
  };
}

import React from "react";
import { Image } from "./Image";

export const LoadHeatingDeviceImg = React.memo(props => {
  const {
    heatingSource,
    heatingType,
    heatingFuel,
    airconditioning,
    waterHeatingSource,
    includeWarmWater,
    wallInsulation,
    isGrounded,
  } = props;
  let fuel = "";
  let type = "";
  let source = heatingSource.split(".");
  source = source[source.length - 1];
  let name = source;
  if (typeof heatingType !== "undefined") {
    type = heatingType.split(".");
    type = type[type.length - 1];
    name += "-" + type;
  }

  if (typeof heatingFuel !== "undefined") {
    fuel = heatingFuel.split(".");
    name += "-" + fuel[fuel.length - 1];
  }

  return (
    <>
      <Image
        id={name}
        className={`heating-device ${source} ${type}`}
        visible={type !== "air_water"}
        src={"./assets/images/heating/" + name + ".svg"}
      />
      <Image
        id={"heating-device-glycol_water"}
        className={"heating-device heating-device-glycol_water"}
        visible={type === "glycol_water" && isGrounded}
        src={"./assets/images/heating/rury-pompy-gruntowej.svg"}
      />
      <Image
        id={"heating-device-air_air"}
        className={"heating-device heating-device-air_air"}
        visible={type === "air_air" || airconditioning}
        src={"./assets/images/heating/wiatrak-klima.svg"}
        style={{ transform: `translateX(${wallInsulation}px)` }}
      />
      <Image
        id={"heat_pump-glycol_water-electric"}
        className={"heating-device heating-device-air_air"}
        visible={
          type === "air_air" &&
          includeWarmWater &&
          waterHeatingSource === "hm.dropdown.heating.source.the_same"
        }
        src={"./assets/images/heating/heat_pump-glycol_water-electric.svg"}
      />
      <Image
        id={"heat_pump-air_water-electric"}
        className={"heating-device heat_pump-air_water-electric"}
        visible={type === "air_water"}
        src={"./assets/images/heating/heat_pump-air_water-electric.svg"}
        style={{ transform: `translateX(${wallInsulation}px)` }}
      />
      <Image
        id={"pompa-ciepla-wiatraczki"}
        className={"heating-device pompa-ciepla-wiatraczki"}
        visible={
          type === "air_air" &&
          includeWarmWater &&
          waterHeatingSource === "hm.dropdown.heating.source.the_same"
        }
        src={"./assets/images/heating/pompa-ciepla-wiatraczki.svg"}
        style={{ transform: `translateX(${wallInsulation}px)` }}
      />
      <Image
        id={"heat_pump-air_air-electric"}
        className={"heating-device heating-device-air_air"}
        visible={airconditioning}
        src={"./assets/images/heating/heat_pump-air_air-electric.svg"}
      />
      <Image
        id={"heating-device-pipe1"}
        className={"heating-device heating-device-pipe1"}
        visible={source === "heating_network" && isGrounded}
        src={"./assets/images/heating/rura-cieplowni-pietro.svg"}
      />
      <Image
        id={"heating-device-pipe2"}
        className={"heating-device heating-device-pipe2"}
        visible={source === "heating_network" && isGrounded}
        src={"./assets/images/heating/rura2.svg"}
      />
    </>
  );
});

LoadHeatingDeviceImg.displayName = "LoadHeatingDeviceImg";

import { InsulationElementType } from "../../../../constants/combo/InsulationElementType";
import { FloorElement } from "../../../../core/model/FloorElement";
import { WallElement } from "../../../../core/model/WallElement";
import { RoofElement } from "../../../../core/model/RoofElement";
import { InsulationElement } from "../../../../core/model/InsulationElement";
import { BasementFloorElement } from "../../../../core/model/BasementFloorElement";
import { BasementWallElement } from "../../../../core/model/BasementWallElement";

export class InsulationElementPreparer {
  static prepare = element => {
    if (element === InsulationElementType.FLOOR) {
      return new FloorElement();
    }
    if (element === InsulationElementType.WALL) {
      return new WallElement();
    }
    if (element === InsulationElementType.ROOF) {
      return new RoofElement();
    }
    if (element === InsulationElementType.BASEMENT_FLOOR) {
      return new BasementFloorElement();
    }
    if (element === InsulationElementType.BASEMENT_WALL) {
      return new BasementWallElement();
    }
    return new InsulationElement();
  };
}

export class TightnessConfig {
  _tightnessItems;
  _selectedIndex;

  constructor(tightnessItems) {
    this._tightnessItems = tightnessItems;
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  set selectedIndex(value) {
    this._selectedIndex = value;
  }

  get tightnessItems() {
    return this._tightnessItems;
  }

  set tightnessItems(value) {
    this._tightnessItems = value;
  }
}

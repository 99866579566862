import { AbstractDictionaryReader } from "./AbstractDictionaryReader";

export class ShadingConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    dictionaryConfig.shadingConfiguration = this.readShadingTypes(
      dictionaryXML.shading_types
    );
    return dictionaryConfig;
  };

  readShadingTypes = shadingItemsXML => {
    return shadingItemsXML;
  };

  readShadingType = foundationTypeXML => {};
}

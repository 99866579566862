import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { HouseTypeHelper } from "../../../util/HouseTypeHelper";

export class FoundationsEnergyCalculator extends IMonthEnergyCalculator {
  calcEnergy(energyData, contextData, monthInput) {
    let houseData = contextData.houseData;
    let tAvg = contextData.environmentalData.tAvg;

    let sideLength = Math.sqrt(houseData.surfaceData.floorSurface);
    let housePerimeter = 4 * sideLength;

    let enFloorCenter = 0;
    let enFloorEdge = 0;
    let isGroundType = HouseTypeHelper.isGroundType(houseData.houseType);
    let foundationType = houseData.foundationType;

    let foundationsEnabled =
      isGroundType && foundationType.isFoundationsEnabled();
    if (foundationsEnabled) {
      let foundationsUValue = isNaN(foundationType.uValue)
        ? 0.75
        : foundationType.uValue;
      enFloorEdge =
        foundationsUValue *
          (houseData.tIn - monthInput.tGround) *
          housePerimeter +
        foundationsUValue * (houseData.tIn - tAvg) * 0.5 * housePerimeter;
    }

    energyData.enFoundations = enFloorCenter + enFloorEdge;
    return energyData;
  }
}

import { BaseComboObject } from "./BaseComboObject";

export class VentilationMethod extends BaseComboObject {
  get versions() {
    return this._versions;
  }

  set versions(value) {
    this._versions = value;
  }
  get multiHouse() {
    return this._multiHouse;
  }

  set multiHouse(value) {
    this._multiHouse = value;
  }

  get multiHouseAggregated() {
    return this._multiHouseAggregated;
  }

  set multiHouseAggregated(value) {
    this._multiHouseAggregated = value;
  }

  static valuesMap = [];
  _type;
  _efficiency = 0;
  _naturalRegulated;
  _humidification = false;
  _defrosting = false;
  _recuperator = false;
  _multiHouse = false;
  _multiHouseAggregated = false;
  _versions = [];

  constructor(
    id,
    type,
    efficiency,
    naturalRegulated,
    recuperator,
    humidification,
    defrosting,
    multiHouse,
    multiHouseAggregated,
    versions
  ) {
    super(id);
    this._type = type;
    this._efficiency = efficiency;
    this._naturalRegulated = naturalRegulated;
    this._recuperator = recuperator;
    this._humidification = humidification;
    this._defrosting = defrosting;
    this._multiHouse = multiHouse;
    this._multiHouseAggregated = multiHouseAggregated;
    this._versions = versions;
    VentilationMethod.valuesMap[id] = this;
  }

  get type() {
    return this._type;
  }

  get efficiency() {
    return this._efficiency;
  }

  get naturalRegulated() {
    return this._naturalRegulated;
  }

  get humidification() {
    return this._humidification;
  }

  get defrosting() {
    return this._defrosting;
  }

  get recuperator() {
    return this._recuperator;
  }

  static GRAVITATIONAL = 1;
  static MECHANICAL = 2;

  static getVentilationMethodById = id => {
    return VentilationMethod.valuesMap[id];
  };
}

export class RegionItem {
  _code;
  _localeCode;
  _regionCode;
  _name;

  _temperatures;
  _groundTemperatures;
  _insolationS;
  _insolationWE;
  _insolationN;
  _optimalInsolation;

  _country;

  get code() {
    return this._code;
  }

  set code(value) {
    this._code = value;
  }

  get localeCode() {
    return this._localeCode;
  }

  set localeCode(value) {
    this._localeCode = value;
  }

  get regionCode() {
    return this._regionCode;
  }

  set regionCode(value) {
    this._regionCode = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get temperatures() {
    return this._temperatures;
  }

  set temperatures(value) {
    this._temperatures = value;
  }

  get groundTemperatures() {
    return this._groundTemperatures;
  }

  set groundTemperatures(value) {
    this._groundTemperatures = value;
  }

  get insolationS() {
    return this._insolationS;
  }

  set insolationS(value) {
    this._insolationS = value;
  }

  get insolationWE() {
    return this._insolationWE;
  }

  set insolationWE(value) {
    this._insolationWE = value;
  }

  get insolationN() {
    return this._insolationN;
  }

  set insolationN(value) {
    this._insolationN = value;
  }

  get optimalInsolation() {
    return this._optimalInsolation;
  }

  set optimalInsolation(value) {
    this._optimalInsolation = value;
  }

  get country() {
    return this._country;
  }

  set country(value) {
    this._country = value;
  }
}

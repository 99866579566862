export class DictionaryConfig {
  _doorTypeConfig;
  _insulationMaterialTypeConfig;
  _solarCollectorsTypeConfig;
  _thermalBridgesTypeConfig;
  _heatingSourceTypeConfiguration;
  _waterStorageDistributionConfiguration;
  _waterConsumptionConfiguration;
  _ventilationTypeConfiguration;
  _windowTypeConfiguration;
  _tightnessConfiguration;
  _foundationConfiguration;
  _houseStandardTypeConfiguration;
  _shadingConfiguration;

  get doorTypeConfig() {
    return this._doorTypeConfig;
  }

  set doorTypeConfig(value) {
    this._doorTypeConfig = value;
  }

  get insulationMaterialTypeConfig() {
    return this._insulationMaterialTypeConfig;
  }

  set insulationMaterialTypeConfig(value) {
    this._insulationMaterialTypeConfig = value;
  }

  get solarCollectorsTypeConfig() {
    return this._solarCollectorsTypeConfig;
  }

  set solarCollectorsTypeConfig(value) {
    this._solarCollectorsTypeConfig = value;
  }

  get thermalBridgesTypeConfig() {
    return this._thermalBridgesTypeConfig;
  }

  set thermalBridgesTypeConfig(value) {
    this._thermalBridgesTypeConfig = value;
  }

  get heatingSourceTypeConfiguration() {
    return this._heatingSourceTypeConfiguration;
  }

  set heatingSourceTypeConfiguration(value) {
    this._heatingSourceTypeConfiguration = value;
  }

  get waterStorageDistributionConfiguration() {
    return this._waterStorageDistributionConfiguration;
  }

  set waterStorageDistributionConfiguration(value) {
    this._waterStorageDistributionConfiguration = value;
  }

  get waterConsumptionConfiguration() {
    return this._waterConsumptionConfiguration;
  }

  set waterConsumptionConfiguration(value) {
    this._waterConsumptionConfiguration = value;
  }

  get ventilationTypeConfiguration() {
    return this._ventilationTypeConfiguration;
  }

  set ventilationTypeConfiguration(value) {
    this._ventilationTypeConfiguration = value;
  }

  get windowTypeConfiguration() {
    return this._windowTypeConfiguration;
  }

  set windowTypeConfiguration(value) {
    this._windowTypeConfiguration = value;
  }

  get tightnessConfiguration() {
    return this._tightnessConfiguration;
  }

  set tightnessConfiguration(value) {
    this._tightnessConfiguration = value;
  }

  get foundationConfiguration() {
    return this._foundationConfiguration;
  }

  set foundationConfiguration(value) {
    this._foundationConfiguration = value;
  }

  get houseStandardTypeConfiguration() {
    return this._houseStandardTypeConfiguration;
  }

  set houseStandardTypeConfiguration(value) {
    this._houseStandardTypeConfiguration = value;
  }

  get shadingConfiguration() {
    return this._shadingConfiguration;
  }

  set shadingConfiguration(value) {
    this._shadingConfiguration = value;
  }
}

export class GaussianDistributor {
  meanVal;
  sigmaVal;
  rangeVal;

  ratios = [];

  constructor(meanVal, sigmaVal) {
    this.meanVal = meanVal;
    this.sigmaVal = sigmaVal;
    this.rangeVal = this.getRange(sigmaVal);

    this.createDistribution();
  }

  apply = resultProvider => {
    for (
      let val = this.meanVal - this.rangeVal;
      val <= this.meanVal + this.rangeVal;
      val++
    ) {
      resultProvider.applyRatio(val, this.ratios[val]);
    }
  };

  getRange = sigmaVal => {
    return 5 * sigmaVal;
  };

  createDistribution = () => {
    let sumProbability = 0,
      chunkRatio,
      val;

    for (
      val = this.meanVal - this.rangeVal;
      val <= this.meanVal + this.rangeVal;
      val++
    ) {
      chunkRatio =
        (1 / (this.sigmaVal * Math.sqrt(2 * Math.PI))) *
        Math.exp(
          (-(val - this.meanVal) * (val - this.meanVal)) /
            (2 * this.sigmaVal * this.sigmaVal)
        );
      sumProbability += chunkRatio;
    }

    for (
      val = this.meanVal - this.rangeVal;
      val <= this.meanVal + this.rangeVal;
      val++
    ) {
      let ratio =
        ((1 / (this.sigmaVal * Math.sqrt(2 * Math.PI))) *
          Math.exp(
            (-(val - this.meanVal) * (val - this.meanVal)) /
              (2 * this.sigmaVal * this.sigmaVal)
          )) /
        sumProbability;
      this.ratios[val] = ratio;
    }
  };
}

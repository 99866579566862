export class WarmWaterData {
  _warmWaterDistribution;
  _warmWaterStorage;

  _heatingSourceType;
  _distributorEfficiency;
  _storeEfficiency;
  _consumptionIntensity;

  get warmWaterDistribution() {
    return this._warmWaterDistribution;
  }

  set warmWaterDistribution(value) {
    this._warmWaterDistribution = value;
  }

  get warmWaterStorage() {
    return this._warmWaterStorage;
  }

  set warmWaterStorage(value) {
    this._warmWaterStorage = value;
  }

  get heatingSourceType() {
    return this._heatingSourceType;
  }

  set heatingSourceType(value) {
    this._heatingSourceType = value;
  }

  get distributorEfficiency() {
    return this._distributorEfficiency;
  }

  set distributorEfficiency(value) {
    this._distributorEfficiency = value;
  }

  get storeEfficiency() {
    return this._storeEfficiency;
  }

  set storeEfficiency(value) {
    this._storeEfficiency = value;
  }

  get consumptionIntensity() {
    return this._consumptionIntensity;
  }

  set consumptionIntensity(value) {
    this._consumptionIntensity = value;
  }
}

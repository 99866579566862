import { UPDATE_COSTS } from "../actionTypes";

const initialState = {
  airConditioning_material_changed: false,
  airConditioning_work_changed: false,
  doorType_material_changed: false,
  doorType_work_changed: false,
  floorInsulation_material_changed: false,
  floorInsulation_work_changed: false,
  foundationType_material_changed: false,
  foundationType_work_changed: false,
  pvType_material_changed: false,
  pvType_work_changed: false,
  roofInsulation_material_changed: false,
  roofInsulation_work_changed: false,
  shutters_material_changed: false,
  shutters_work_changed: false,
  thermalBridges_material_changed: false,
  thermalBridges_work_changed: false,
  ventilation_material_changed: false,
  ventilation_work_changed: false,
  wallInsulation_material_changed: false,
  wallInsulation_work_changed: false,
  windows_material_changed: false,
  windows_work_changed: false,
  collectors_material_changed: false,
  collectors_work_changed: false,

  waterHeatingSource_work_changed: false,
  waterHeatingSource_material_changed: false,
  heatingSource_work_changed: false,
  heatingSource_material_changed: false,

  chimney_changed: false,
  storeroom_changed: false,
  system_changed: false,
  heatingNetwork_changed: false,
  gasTerminal_changed: false,
  storage_changed: false,

  co2Sensor_changed: false,
  tightness_changed: false,

  waterSavings_material_changed: false,
  waterSavings_work_changed: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_COSTS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
      };
    }

    default:
      return state;
  }
}

import { BaseComboObject } from "./BaseComboObject";

export class HeatingSourceTypeItem extends BaseComboObject {
  _type;
  _pricePerKWh;
  _naturalUnitPrice;
  _heatingValueMJ;
  static valuesMap = [];

  constructor(id, type, pricePerKWh = -1) {
    super(id);
    this._type = type;
    if (pricePerKWh === -1) {
      this._pricePerKWh = type.pricePerKWh;
    } else {
      this._pricePerKWh = pricePerKWh;
    }
    this._naturalUnitPrice = type.naturalUnitPrice;
    this._heatingValueMJ = type.heatingValueMJ;
    this._pricePerKWh = pricePerKWh;
    HeatingSourceTypeItem.valuesMap[id] = this;
  }

  resetOriginalPrice = ratio => {
    this._pricePerKWh = this.type.pricePerKWh * ratio;
    this._naturalUnitPrice = this.type.naturalUnitPrice * ratio;
  };

  static getHeatingSourceTypeItemById = id => {
    return this.valuesMap[id];
  };

  get type() {
    return this._type;
  }

  set type(value) {
    this._type = value;
  }

  get pricePerKWh() {
    return this._pricePerKWh;
  }

  set pricePerKWh(value) {
    this._pricePerKWh = value;
  }

  get naturalUnitPrice() {
    return this._naturalUnitPrice;
  }

  set naturalUnitPrice(value) {
    this._naturalUnitPrice = value;
  }

  get heatingValueMJ() {
    return this._heatingValueMJ;
  }

  set heatingValueMJ(value) {
    this._heatingValueMJ = value;
  }
}

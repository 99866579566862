export class HeatingFuelsTypeConfig {
  _heatingFuelTypes;

  _electricityHeatingFuelType;

  get heatingFuelTypes() {
    return this._heatingFuelTypes;
  }

  set heatingFuelTypes(value) {
    this._heatingFuelTypes = value;
  }

  get electricityHeatingFuelType() {
    return this._electricityHeatingFuelType;
  }

  set electricityHeatingFuelType(value) {
    this._electricityHeatingFuelType = value;
  }
}

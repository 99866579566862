export class GlobalValues {
  static START_MONTH = 0;
  static INITIAL_PERSON_NUMBER = 4;

  static PRICE_PELLETS_KWH_DEFAULT = 0.22;
  static PRICE_WOOD_KWH_DEFAULT = 0.12;
  static PRICE_ELECTRICITY_KWH_DEFAULT = 0.58;
  static PRICE_WOOD_CHIPS_KWH_DEFAULT = 0.12;
  static PRICE_GAS_KWH_DEFAULT = 0.18;
  static PRICE_COAL_KWH_DEFAULT = 0.12;
  static PRICE_OIL_KWH_DEFAULT = 0.4;
  static PRICE_HEAT_KWH_COMBINED = 0.22;

  static getLongestStringLength = strings => {
    let longestLength = 0;
    for (let i = 0; i < strings.length; i++) {
      let s = strings[i];
      if (longestLength < s.length) {
        longestLength = s.length;
      }
    }
    return longestLength;
  };

  static parseIntValue = value => {
    let result = parseInt(value);
    if (isNaN(result)) {
      return 0;
    }
    return result;
  };

  static toPrecision = (value, precision) => {
    return this.parseFloatValue(value).toFixed(precision);
  };

  static parseFloatValue = value => {
    let result = parseFloat(value);
    if (isNaN(result)) {
      return 0;
    }
    return result;
  };

  static formatFloatValue = value => {
    let number = Math.round(value);
    return number.toFixed(2);
  };
}

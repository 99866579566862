import { FloorSurfaceData } from "../../../../core/model/FloorSurfaceData";
import { HouseTypeHelper } from "../../../../util/HouseTypeHelper";

export class AbstractSurfaceCalculator {
  calcFloorSurfaceData = (floorArea, type) => {
    let a = Math.sqrt(floorArea);
    let b = a;
    let maxWidth = this.getMaxWidth(type);
    if (b > maxWidth) {
      b = maxWidth;
      a = floorArea / b;
    }
    return new FloorSurfaceData(a, b);
  };

  doCalculation = (inputSurface, type, roofData) => inputSurface;

  getMaxWidth = type => {
    if (HouseTypeHelper.isDoubleBlock(type)) {
      return 5;
    }
    return 10;
  };

  getSupportedHouseTypes = () => [];

  doFinalCalculation = surfaceData => surfaceData;

  uiSet = uiValue => uiValue >= 0;

  calcSurface = (surfaceData, type, roofData) => {
    surfaceData = this.doCalculation(surfaceData, type, roofData);
    surfaceData = this.doFinalCalculation(surfaceData);
    return surfaceData;
  };

  supportsHouseType = type => false;

  degreesToRadians = degrees => (degrees * Math.PI) / 180;
  calcRoofHeight = (a, degrees) => a * Math.tan(this.degreesToRadians(degrees));
}

import { BaseComboObject } from "./BaseComboObject";

export class AirTightness extends BaseComboObject {
  static valuesMap = [];
  _value;
  _ventNatural;

  constructor(id, value, ventNatural) {
    super(id);
    AirTightness.valuesMap[id] = this;
    this._value = value;
    this._ventNatural = ventNatural;
  }

  static getAirTightnessById = id => {
    return this.valuesMap[id];
  };

  get value() {
    return this._value;
  }

  get ventNatural() {
    return this._ventNatural;
  }
}

export class ProcessingResult {
  _dataContext;

  _conversionData;

  _monthlyConvertedData = [];
  _yearlyAggregatedConvertedData;
  _wattsEnergyResult;
  _classAwareWattsEnergyResult;

  _userEnergyConsumption;
  _finalEnergyConsumption;
  _primaryEnergyConsumption;
  _unitSurfaceUserEnergyConsumption;
  _unitSurfaceFinalEnergyConsumption;
  _unitSurfacePrimaryEnergyConsumption;

  _unitSurfaceFinalEnergyClassConsumption;
  _unitSurfacePrimaryEnergyClassConsumption;

  _yearlyCost;
  _yearlyHeatingCost;
  _yearlyElectricCost;
  _yearlyWaterHeatingCost;

  get dataContext() {
    return this._dataContext;
  }

  set dataContext(value) {
    this._dataContext = value;
  }

  get conversionData() {
    return this._conversionData;
  }

  set conversionData(value) {
    this._conversionData = value;
  }

  get monthlyConvertedData() {
    return this._monthlyConvertedData;
  }

  set monthlyConvertedData(value) {
    this._monthlyConvertedData = value;
  }

  get yearlyAggregatedConvertedData() {
    return this._yearlyAggregatedConvertedData;
  }

  set yearlyAggregatedConvertedData(value) {
    this._yearlyAggregatedConvertedData = value;
  }

  get wattsEnergyResult() {
    return this._wattsEnergyResult;
  }

  set wattsEnergyResult(value) {
    this._wattsEnergyResult = value;
  }

  get classAwareWattsEnergyResult() {
    return this._classAwareWattsEnergyResult;
  }

  set classAwareWattsEnergyResult(value) {
    this._classAwareWattsEnergyResult = value;
  }

  get userEnergyConsumption() {
    return this._userEnergyConsumption;
  }

  set userEnergyConsumption(value) {
    this._userEnergyConsumption = value;
  }

  get finalEnergyConsumption() {
    return this._finalEnergyConsumption;
  }

  set finalEnergyConsumption(value) {
    this._finalEnergyConsumption = value;
  }

  get primaryEnergyConsumption() {
    return this._primaryEnergyConsumption;
  }

  set primaryEnergyConsumption(value) {
    this._primaryEnergyConsumption = value;
  }

  get unitSurfaceUserEnergyConsumption() {
    return this._unitSurfaceUserEnergyConsumption;
  }

  set unitSurfaceUserEnergyConsumption(value) {
    this._unitSurfaceUserEnergyConsumption = value;
  }

  get unitSurfaceFinalEnergyConsumption() {
    return this._unitSurfaceFinalEnergyConsumption;
  }

  set unitSurfaceFinalEnergyConsumption(value) {
    this._unitSurfaceFinalEnergyConsumption = value;
  }

  get unitSurfacePrimaryEnergyConsumption() {
    return this._unitSurfacePrimaryEnergyConsumption;
  }

  set unitSurfacePrimaryEnergyConsumption(value) {
    this._unitSurfacePrimaryEnergyConsumption = value;
  }

  get unitSurfaceFinalEnergyClassConsumption() {
    return this._unitSurfaceFinalEnergyClassConsumption;
  }

  set unitSurfaceFinalEnergyClassConsumption(value) {
    this._unitSurfaceFinalEnergyClassConsumption = value;
  }

  get unitSurfacePrimaryEnergyClassConsumption() {
    return this._unitSurfacePrimaryEnergyClassConsumption;
  }

  set unitSurfacePrimaryEnergyClassConsumption(value) {
    this._unitSurfacePrimaryEnergyClassConsumption = value;
  }

  get yearlyCost() {
    return this._yearlyCost;
  }

  set yearlyCost(value) {
    this._yearlyCost = value;
  }

  get yearlyHeatingCost() {
    return this._yearlyHeatingCost;
  }

  set yearlyHeatingCost(value) {
    this._yearlyHeatingCost = value;
  }

  get yearlyElectricCost() {
    return this._yearlyElectricCost;
  }

  set yearlyElectricCost(value) {
    this._yearlyElectricCost = value;
  }

  get yearlyWaterHeatingCost() {
    return this._yearlyWaterHeatingCost;
  }

  set yearlyWaterHeatingCost(value) {
    this._yearlyWaterHeatingCost = value;
  }
}

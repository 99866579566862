import { ProcessingResult } from "../ProcessingResult";
import { RatioClusterFactory } from "../converter/RatioClusterFactory";
import { ConverterService } from "../converter/ConverterService";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";
import { BalanceValueHelper } from "./utils/BalanceValueHelper";
import { BusinessConfigurationReaderListener } from "../../config/BusinessConfigurationReaderListener";
import { ConversionData } from "../converter/ConversionData";

export class ReportDataCalculator extends BusinessConfigurationReaderListener {
  converterService = new ConverterService();
  electricityHeatingSourceType;

  configurationRead = businessConfiguration => {
    this.electricityHeatingSourceType =
      businessConfiguration.dictionaryConfig.heatingDeviceTypeConfiguration.electricityHeatingSourceType;
    this.converterService.configurationRead(businessConfiguration);
  };

  calcReportValues = (dataContext, processingResult, conversionData) => {
    let energyConvertedResult;
    if (ConversionUnits.isEnergyUnit(conversionData.selectedUnit)) {
      energyConvertedResult = processingResult;
    } else {
      energyConvertedResult = new ProcessingResult();
      energyConvertedResult.wattsEnergyResult =
        processingResult.wattsEnergyResult;
      let energyConversionData = ConversionData.buildConversionDataFromConversionData(
        conversionData,
        ConversionUnits.ENERGY_KWH_PER_MONTH
      );
      energyConvertedResult = this.converterService.convert(
        energyConvertedResult,
        energyConversionData,
        RatioClusterFactory.getMonthlyWattsRatioCluster(energyConversionData)
      );
    }
    let totalSurface = dataContext.houseData.surfaceData.totalSurface;
    let referenceEnergyConsumption = BalanceValueHelper.calcYearBalanceValue(
      processingResult
    );

    processingResult.userEnergyConsumption = referenceEnergyConsumption;
    processingResult.unitSurfaceUserEnergyConsumption =
      referenceEnergyConsumption / totalSurface;

    let totalFinalEnergyConsumption = BalanceValueHelper.calcEfficiencyAwareYearBalanceValue(
      processingResult
    );
    let totalFinalEnergyClassAwareConsumption = BalanceValueHelper.calcEfficiencyAndClassEnergyAwareYearBalanceValue(
      energyConvertedResult
    );

    let heatingFinalEnergyConsumption = BalanceValueHelper.calcEfficiencyAwareHeatingYearBalanceValue(
      processingResult
    );
    let warmWaterFinalEnergyConsumption = BalanceValueHelper.calcEfficiencyAwareWarmWaterYearBalanceValue(
      processingResult
    );
    let warmWaterFinalEnergyClassAwareConsumption = BalanceValueHelper.calcEfficiencyAwareAndClassEnergyWarmWaterYearBalanceValue(
      energyConvertedResult
    );
    let enElectricDevices = BalanceValueHelper.calcBalanceElectricValue(
      processingResult
    );

    let primaryEnergyConsumption = this.calcPrimaryEnergyConsumptionFromFinalValues(
      heatingFinalEnergyConsumption,
      warmWaterFinalEnergyConsumption,
      enElectricDevices,
      conversionData
    );
    let primaryEnergyClassAwareConsumption = this.calcPrimaryEnergyConsumption(
      heatingFinalEnergyConsumption,
      warmWaterFinalEnergyClassAwareConsumption,
      enElectricDevices,
      conversionData
    );

    processingResult.finalEnergyConsumption = totalFinalEnergyConsumption;

    processingResult.primaryEnergyConsumption = primaryEnergyConsumption;

    processingResult.yearlyWaterHeatingCost = BalanceValueHelper.calcEfficiencyAwareWarmWaterYearBalanceValue(
      processingResult
    );
    processingResult.yearlyHeatingCost = BalanceValueHelper.calcEfficiencyAwareHeatingYearBalanceValue(
      processingResult
    );
    processingResult.yearlyElectricCost = BalanceValueHelper.calcBalanceElectricValue(
      processingResult
    );
    processingResult.finalYearlyCost =
      processingResult.yearlyHeatingCost +
      processingResult.yearlyWaterHeatingCost +
      processingResult.yearlyElectricCost;
    processingResult.finalUnitSurfaceYearlyCost =
      processingResult.finalYearlyCost / totalSurface;

    processingResult.userYearlyWaterHeatingCost = BalanceValueHelper.calcBalanceWarmWaterValue(
      processingResult
    );
    processingResult.userYearlyHeatingCost = BalanceValueHelper.calcHeatingBalance(
      processingResult
    );
    processingResult.userYearlyElectricCost = BalanceValueHelper.calcBalanceElectricValue(
      processingResult
    );
    processingResult.userYearlyCost =
      processingResult.userYearlyHeatingCost +
      processingResult.userYearlyWaterHeatingCost +
      processingResult.userYearlyElectricCost;
    processingResult.unitSurfaceUserYearlyCost =
      processingResult.userYearlyCost / totalSurface;

    processingResult.unitSurfaceFinalEnergyConsumption =
      totalFinalEnergyConsumption / totalSurface;
    processingResult.unitSurfacePrimaryEnergyConsumption =
      primaryEnergyConsumption / totalSurface;
    processingResult.unitSurfaceFinalEnergyClassConsumption =
      totalFinalEnergyClassAwareConsumption / totalSurface;
    processingResult.unitSurfacePrimaryEnergyClassConsumption =
      primaryEnergyClassAwareConsumption / totalSurface;

    return processingResult;
  };

  calcPrimaryEnergyConsumption = (
    heatingEnergyConsumption,
    enRecuperator,
    warmWaterEnergyConsumption,
    conversionData
  ) => {
    let result =
      (heatingEnergyConsumption / conversionData.houseHeatingEfficiency) *
      conversionData.finalToPrimaryCoefficient;
    result =
      result +
      (warmWaterEnergyConsumption / conversionData.warmWaterHeatingEfficiency) *
        conversionData.warmWaterFinalToPrimaryCoefficient;
    result =
      result +
      enRecuperator *
        (this.electricityHeatingSourceType.efficiency / 100) *
        this.electricityHeatingSourceType.finalToPrimaryCoefficient;
    return result;
  };

  calcPrimaryEnergyConsumptionFromFinalValues = (
    heatingFinalValue,
    warmWaterFinalValue,
    enRecuperator,
    conversionData
  ) => {
    let result = heatingFinalValue * conversionData.finalToPrimaryCoefficient;
    result =
      result +
      warmWaterFinalValue * conversionData.warmWaterFinalToPrimaryCoefficient;
    result =
      result +
      enRecuperator *
        this.electricityHeatingSourceType.finalToPrimaryCoefficient;
    return result;
  };

  buildConversionData = (conversionData, unit) => {
    let result = ConversionData.buildConversionData(
      unit,
      conversionData.pricePerKWh
    );
    result.waterPricePerKWh = conversionData.waterPricePerKWh;
    result.houseHeatingEfficiency = conversionData.houseHeatingEfficiency;
    result.warmWaterHeatingEfficiency =
      conversionData.warmWaterHeatingEfficiency;
    result.electricityPricePerKWh = conversionData.electricityPricePerKWh;
    result.pvPricePerKWh = conversionData.pvPricePerKWh;
    return result;
  };
}

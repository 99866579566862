import { BaseComboObject } from "./BaseComboObject";

export class FoundationsType extends BaseComboObject {
  static TRADITIONAL_TYPE = 1;
  static SLAB_TYPE = 3;
  static TRADITIONAL_INSULATED = 2;

  static valuesMap = [];

  _uValue;
  _fType;
  _type;

  constructor(id, fType, uValue) {
    super(id);
    this._fType = fType;
    this._type = fType;
    this._uValue = uValue;
    FoundationsType.valuesMap[id] = this;
  }

  static getUValueForTraditionalType = type => {
    if (type === FoundationsType.TRADITIONAL_TYPE) {
      return 0.75;
    }
    if (type === FoundationsType.TRADITIONAL_INSULATED) {
      return 0.5;
    }
    return 0.0;
  };

  static getTypeForUValue = uValue => {
    if (uValue === 0.75) {
      return FoundationsType.TRADITIONAL_TYPE;
    }
    if (uValue === 0.5) {
      return FoundationsType.TRADITIONAL_INSULATED;
    }
    return FoundationsType.SLAB;
  };

  isFoundationsEnabled = () => {
    return (
      this.fType === FoundationsType.TRADITIONAL_TYPE ||
      this.fType === FoundationsType.TRADITIONAL_INSULATED
    );
  };

  static getFoundationById = id => {
    return FoundationsType.valuesMap[id];
  };

  static getFoundationTypeById = id => {
    return FoundationsType.valuesMap[id].type;
  };

  static isFoundationsInsulated = id => {
    return (
      FoundationsType.valuesMap[id].type !== FoundationsType.TRADITIONAL_TYPE
    );
  };

  get fType() {
    return this._fType;
  }

  get type() {
    return this._type;
  }

  get uValue() {
    return this._uValue;
  }
  static isFoundationsEnabledForType = fType =>
    fType === FoundationsType.TRADITIONAL_TYPE ||
    fType === FoundationsType.TRADITIONAL_INSULATED;
}

import React from "react";
import { FormattedMessage } from "react-intl";
import ThermodermRow from "./ThermodermRow";

export function Thermoderm(props) {
  const { data, className, type } = props;
  let cost = 0;
  return (
    <div className={"thermoderm row pt-2 mt-2 " + className}>
      <h3 className={"col-12 mb-2"}>
        <FormattedMessage
          id={"hm.optimizer.thermoderm.cost"}
          defaultMessage=""
          tagName={"span"}
        />
      </h3>
      {data.map((item, i) => {
        if (item.costView) {
          cost += parseInt(item.costView, 10);
        }
        return (
          <ThermodermRow
            key={`${type}-${i}`}
            item={item}
            writeCache={() => props.writeCache()}
          />
        );
      })}
      <div className={"col-12"}>
        <FormattedMessage
          id={"hm.optimizer.thermoderm.investment.cost"}
          defaultMessage=""
          tagName={"span"}
        >
          {message => <span className={"cost-header"}>{message}:</span>}
        </FormattedMessage>
        <span className={"mx-1 amount-currency"}>{cost}</span>
        <FormattedMessage
          id={"hm.optimizer.thermoderm.cost.material.unit.zl"}
          defaultMessage=""
          tagName={"span"}
        >
          {message => <span className={"cost-currency"}>{message}</span>}
        </FormattedMessage>
      </div>
    </div>
  );
}

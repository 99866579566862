export class ConvertedValue {
  _zlotyValue;
  _wattsValue;
  _kWhValue;
  _gjValue;
  _emissionValue;
  _waterEmissionValue;

  get zlotyValue() {
    return this._zlotyValue;
  }

  set zlotyValue(value) {
    this._zlotyValue = value;
  }

  get wattsValue() {
    return this._wattsValue;
  }

  set wattsValue(value) {
    this._wattsValue = value;
  }

  get kWhValue() {
    return this._kWhValue;
  }

  set kWhValue(value) {
    this._kWhValue = value;
  }

  get gjValue() {
    return this._gjValue;
  }

  set gjValue(value) {
    this._gjValue = value;
  }

  get emissionValue() {
    return this._emissionValue;
  }

  set emissionValue(value) {
    this._emissionValue = value;
  }

  get waterEmissionValue() {
    return this._waterEmissionValue;
  }

  set waterEmissionValue(value) {
    this._waterEmissionValue = value;
  }
}

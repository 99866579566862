import { FlatDataContext } from "./FlatDataContext";
import { DataContext } from "../DataContext";
import { HeatingSourceData } from "../model/HeatingSourceData";
import { HeatingSourceType } from "../../config/dictionary/model/HeatingSourceType";
import { SolarCollectorData } from "../model/SolarCollectorData";
import { InsulationElement } from "../model/InsulationElement";
import { WindowElement } from "../model/WindowElement";
import { SurfaceData } from "../model/SurfaceData";
import { SolarCollectorType } from "../../config/dictionary/model/SolarCollectorType";
import { BuildingAge } from "../../config/dictionary/model/BuildingAge";
import { DoorType } from "../../config/dictionary/model/DoorType";
import { FoundationsType } from "../../config/dictionary/model/FoundationsType";
import { HouseStandardTypeItem } from "../../config/dictionary/model/HouseStandardTypeItem";
import { WarmWaterDistribution } from "../../config/dictionary/model/WarmWaterDistribution";
import { WarmWaterStorage } from "../../config/dictionary/model/WarmWaterStorage";
import { WarmWaterConsumptionType } from "../../config/dictionary/model/WarmWaterConsumptionType";
import { VentilationMethod } from "../../config/dictionary/model/VentilationMethod";
import { AirTightness } from "../../config/dictionary/model/AirTightness";
import { WindowsType } from "../../config/dictionary/model/WindowsType";
import { ThermalBridgesType } from "../../config/dictionary/model/ThermalBridgesType";

import store from "../../redux/store";
import { WindowElementPreparer } from "../../ui/builder/prepare/element/WindowElementPreparer";
import {
  updateAirconditioning,
  updateCollectors,
  updateConversion,
  updateDistribution,
  updateHeatingById,
  updateHouse,
  updateInsulation,
  updatePersons,
  updatePhotovoltaic,
  updateVentilation,
  updateWindows,
  updateInsolation,
  updateHeatingAll,
  updateReports,
  updateCosts,
} from "../../redux/actions";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

export class FlatDataContextBuilder {
  constructor(props) {
    this.props = props;
  }

  static buildFlatDataContext = () => {
    let flatDataContext = new FlatDataContext();

    let conversionData = store.getState().conversion;
    let heatingData = store.getState().heating;
    flatDataContext.pvData = store.getState().photovoltaic;

    flatDataContext.costs = store.getState().costs;

    let irr = store.getState().reports;
    flatDataContext.irrData = irr;
    flatDataContext.irrData2 = irr;

    flatDataContext.currencyLocaleCode = conversionData.currencyLocaleCode;
    flatDataContext.currencyName = conversionData.currencyName;
    flatDataContext.localCurrency = conversionData.localCurrency;
    flatDataContext.conversionPvPricePerKWh = conversionData.pvPricePerKWh;
    flatDataContext.conversionElectricityPricePerKWh =
      conversionData.electricityPricePerKWh;
    flatDataContext.electricityEmissionCoefficient =
      conversionData.electricityEmissionCoefficient;
    flatDataContext.conversionIsElectric = conversionData.isElectric;
    flatDataContext.conversionIsWaterElectric = conversionData.isWaterElectric;
    flatDataContext.conversionDataEmissionCoefficient =
      heatingData.emissionCoefficient;
    flatDataContext.conversionDataFinalToPrimaryCoefficient =
      heatingData.finalToPrimaryCoefficient;
    flatDataContext.houseHeatingEfficiency =
      heatingData.heatingEfficiency * heatingData.heatingSystemEfficiency;
    flatDataContext.conversionPricePerKWh = conversionData.pricePerKWh;
    flatDataContext.conversionDataNaturalUnitPrice =
      conversionData.naturalUnitPrice;
    flatDataContext.conversionDataNaturalUnitPriceLocal =
      conversionData.naturalUnitPriceLocal;
    flatDataContext.conversionDataNaturalUnitPriceChanged =
      conversionData.naturalUnitPriceChanged;
    flatDataContext.selectedUnit = conversionData.selectedUnit;
    flatDataContext.shortUnitName = conversionData.shortUnitName;
    flatDataContext.toPLNCurrencyExchangeRate =
      conversionData.toPLNCurrencyExchangeRate;
    flatDataContext.unitName = conversionData.unitName;
    flatDataContext.conversionDataWarmWaterFinalToPrimaryCoefficient =
      heatingData.warmWaterFinalToPrimaryCoefficient;
    flatDataContext.warmWaterHeatingEfficiency =
      heatingData.waterHeatingEfficiency;
    flatDataContext.conversionDataWaterEmissionCoefficient =
      heatingData.waterEmissionCoefficient;
    flatDataContext.conversionWaterPricePerKWh =
      conversionData.waterPricePerKWh;
    flatDataContext.conversionData = conversionData;

    flatDataContext.heatingSource = heatingData.heatingSource;
    flatDataContext.heatingType = heatingData.heatingType;
    flatDataContext.heatingSystem = heatingData.heatingSystem;
    flatDataContext.heatingFuel = heatingData.heatingFuel;
    flatDataContext.heatingEfficiency = heatingData.heatingEfficiency;
    flatDataContext.heatingEfficiency = heatingData.heatingEfficiency;
    flatDataContext.heatingSystemEfficiency =
      heatingData.heatingSystemEfficiency;
    flatDataContext.coolingEfficiency = heatingData.coolingEfficiency;
    flatDataContext.subdevicesEnergyConsumption =
      heatingData.subdevicesEnergyConsumption;
    flatDataContext.waterSubdevicesEnergyConsumption =
      heatingData.waterSubdevicesEnergyConsumption;

    flatDataContext.heatingTypeId = heatingData.heatingTypeId;
    flatDataContext.pricePerKWh = parseFloat(heatingData.pricePerKWh);
    flatDataContext.naturalUnit = heatingData.naturalUnit;
    flatDataContext.heatingValueEditable = heatingData.heatingValueEditable;
    flatDataContext.showNaturalUnit = heatingData.showNaturalUnit;
    flatDataContext.naturalUnitPrice = heatingData.naturalUnitPrice;
    flatDataContext.calorificValue = heatingData.calorificValue;
    flatDataContext.calorificValueUnit = heatingData.calorificValueUnit;

    flatDataContext.emissionCoefficient = heatingData.emissionCoefficient;
    flatDataContext.finalToPrimaryCoefficient =
      heatingData.finalToPrimaryCoefficient;
    flatDataContext.includeWarmWater = heatingData.includeWarmWater;
    flatDataContext.waterPricePerKWh = parseFloat(heatingData.waterPricePerKWh);
    flatDataContext.electricityPricePerKWh = parseFloat(
      heatingData.electricityPricePerKWh
    );
    flatDataContext.warmWaterFinalToPrimaryCoefficient =
      heatingData.warmWaterFinalToPrimaryCoefficient;
    flatDataContext.waterEmissionCoefficient =
      heatingData.waterEmissionCoefficient;
    flatDataContext.waterHeatingEfficiency = heatingData.waterHeatingEfficiency;
    flatDataContext.waterHeatingTypeId = heatingData.waterHeatingTypeId;
    flatDataContext.waterHeatingSource = heatingData.waterHeatingSource;
    flatDataContext.waterHeatingPlacement = heatingData.waterHeatingPlacement;
    flatDataContext.waterHeatingType = heatingData.waterHeatingType;
    flatDataContext.waterHeatingFuel = heatingData.waterHeatingFuel;

    flatDataContext.consumptionIntensityId = heatingData.consumptionIntensityId;
    flatDataContext.waterSavings = heatingData.waterSavings;
    flatDataContext.distributorEfficiency = heatingData.distributorEfficiency;
    flatDataContext.storeEfficiency = heatingData.storeEfficiency;
    flatDataContext.warmWaterDistributionId =
      heatingData.warmWaterDistributionId;
    flatDataContext.warmWaterStorageId = heatingData.warmWaterStorageId;

    flatDataContext.collectorTypeId = heatingData.collectorsTypeId;
    flatDataContext.surface = heatingData.collectorsSurface;
    flatDataContext.count = heatingData.collectorsCount;

    let houseData = store.getState().house;

    flatDataContext.countryCode = houseData.country;
    flatDataContext.houseStandardTypeId = houseData.standardTypeId;
    flatDataContext.houseType = houseData.houseType;
    flatDataContext.buildingType = houseData.buildingType;
    flatDataContext.selectedCountryRegion = houseData.region;
    flatDataContext.selectedStation = houseData.location;
    flatDataContext.showDetailedData = houseData.showDetailedData;
    flatDataContext.detailedDataChanged = houseData.detailedDataChanged;

    flatDataContext.surfaceData = houseData.surfaceData;
    flatDataContext.tIn = houseData.inHouseTemp;

    flatDataContext.roofData = houseData.roofData;
    flatDataContext.basement = houseData.basement;

    flatDataContext.wallsData = houseData.wallsData;

    flatDataContext.buildingAgeId = houseData.buildingAgeId.id;

    let insulationData = store.getState().insulation;

    flatDataContext.wallWidth = insulationData.wall.width;
    flatDataContext.wallUValue = insulationData.wall.uValue;
    flatDataContext.wallMaterial = insulationData.wall.material;
    flatDataContext.wallHeatTransfer = insulationData.wall.heatTransfer;
    flatDataContext.doorTypeId = insulationData.wall.doorType;
    flatDataContext.wall = insulationData.wall;

    flatDataContext.roofWidth = insulationData.roof.width;
    flatDataContext.roofUValue = insulationData.roof.uValue;
    flatDataContext.roofMaterial = insulationData.roof.material;
    flatDataContext.roofHeatTransfer = insulationData.roof.heatTransfer;
    flatDataContext.roof = insulationData.roof;

    flatDataContext.floorWidth = insulationData.floor.width;
    flatDataContext.floorUValue = insulationData.floor.uValue;
    flatDataContext.floorMaterial = insulationData.floor.material;
    flatDataContext.floorHeatTransfer = insulationData.floor.heatTransfer;
    flatDataContext.foundationsTypeId = insulationData.floor.foundations;
    flatDataContext.floor = insulationData.floor;

    flatDataContext.basementWallWidth = insulationData.basement.wall.width;
    flatDataContext.basementWallUValue = insulationData.basement.wall.uValue;
    flatDataContext.basementWallMaterial =
      insulationData.basement.wall.material;
    flatDataContext.basementWallHeatTransfer =
      insulationData.basement.wall.heatTransfer;

    flatDataContext.basementFloorWidth = insulationData.basement.floor.width;
    flatDataContext.basementFloorUValue = insulationData.basement.floor.uValue;
    flatDataContext.basementFloorMaterial =
      insulationData.basement.floor.material;
    flatDataContext.basementFloorHeatTransfer =
      insulationData.basement.floor.heatTransfer;

    flatDataContext.personNumber = store.getState().persons.persons;
    flatDataContext.livingNeeds = store.getState().persons.livingNeeds;
    flatDataContext.livingNeedsChanged = store.getState().persons.livingNeedsChanged;

    let ventilationData = store.getState().ventilation;

    flatDataContext.gwcSet = ventilationData.gwcSet;
    flatDataContext.ventilation = ventilationData.ventilation;
    flatDataContext.airTightness = ventilationData.airTightness;
    flatDataContext.co2Sensor = ventilationData.co2Sensor;
    flatDataContext.isMechanical = ventilationData.isMechanical;
    flatDataContext.ventilationFreq = ventilationData.ventilationFreq;
    flatDataContext.recuperator = ventilationData.recuperator;
    flatDataContext.recuperatorLocation = ventilationData.recuperatorLocation;
    flatDataContext.humidification = ventilationData.humidification;
    flatDataContext.patency = ventilationData.patency;
    flatDataContext.n50 = ventilationData.n50;

    let windowElement = store.getState().windows;

    flatDataContext.windowsTypeId = windowElement.window;
    flatDataContext.shutters = windowElement.shutters;
    flatDataContext.thermalBridge = windowElement.thermalBridge;

    let ac = store.getState().airconditioning;
    flatDataContext.acData = ac;

    let insolation = store.getState().insolation;
    flatDataContext.insolationData = insolation;

    return flatDataContext;
  };

  static buildDataContext = flatDataContext => {
    let dataContext = new DataContext();
    dataContext.includeWarmWater = flatDataContext._includeWarmWater;
    dataContext.selectedCountryRegion = flatDataContext._selectedCountryRegion;
    dataContext.selectedStation = flatDataContext._selectedStation;
    dataContext.countryCode = flatDataContext._countryCode;
    dataContext.currencyLocaleCode = flatDataContext._currencyLocaleCode;
    dataContext.localCurrency = flatDataContext._localCurrency;

    let conversionData = dataContext.conversionData;

    try {
      conversionData.selectedUnit = flatDataContext._selectedUnit;
      conversionData.unitName = flatDataContext._unitName;
      conversionData.shortUnitName = flatDataContext._shortUnitName;
      conversionData.pricePerKWh = flatDataContext._conversionPricePerKWh;

      conversionData.waterPricePerKWh =
        flatDataContext._conversionWaterPricePerKWh;
      conversionData.naturalUnitPrice =
        flatDataContext._conversionDataNaturalUnitPrice;
      conversionData.naturalUnitPriceLocal =
        flatDataContext._conversionDataNaturalUnitPriceLocal;
      conversionData.naturalUnitPriceChanged =
        flatDataContext._conversionDataNaturalUnitPriceChanged;
      conversionData.pvPricePerKWh = flatDataContext._conversionPvPricePerKWh;
      conversionData.electricityPricePerKWh =
        flatDataContext._conversionElectricityPricePerKWh;
      conversionData.electricityPricePerKWhLocal =
        flatDataContext.conversionData.electricityPricePerKWhLocal;
      conversionData.electricityEmissionCoefficient =
        flatDataContext._electricityEmissionCoefficient;
      conversionData.isElectric = flatDataContext._conversionIsElectric;
      conversionData.isWaterElectric =
        flatDataContext._conversionIsWaterElectric;
      conversionData.houseHeatingEfficiency =
        flatDataContext._houseHeatingEfficiency / 100 / 100;
      conversionData.warmWaterHeatingEfficiency =
        flatDataContext._warmWaterHeatingEfficiency / 100;
      conversionData.finalToPrimaryCoefficient =
        flatDataContext._conversionDataFinalToPrimaryCoefficient;
      conversionData.warmWaterFinalToPrimaryCoefficient =
        flatDataContext._conversionDataWarmWaterFinalToPrimaryCoefficient;
      conversionData.emissionCoefficient =
        flatDataContext._conversionDataEmissionCoefficient;
      conversionData.waterEmissionCoefficient =
        flatDataContext._conversionDataWaterEmissionCoefficient;
      conversionData.toPLNCurrencyExchangeRate =
        flatDataContext._toPLNCurrencyExchangeRate;
      conversionData.currencyLocaleCode = flatDataContext._currencyLocaleCode;
      conversionData.currencyName = flatDataContext._currencyName;

      conversionData.heatingSource = flatDataContext._heatingSource;
      conversionData.waterHeatingSource = flatDataContext._waterHeatingSource;
      conversionData.waterHeatingPlacement =
        flatDataContext._waterHeatingPlacement;
      conversionData.includeWarmWater = flatDataContext._includeWarmWater;
      conversionData.warmWaterStorageId = flatDataContext._warmWaterStorageId;
      conversionData.airConditioning = flatDataContext.acData.airconditioning;
    } catch (e) {
      console.log(e);
    }
    dataContext.conversionData = conversionData;

    let heatingData = new HeatingSourceData();

    try {
      heatingData.heatingSource = flatDataContext._heatingSource;
      heatingData.heatingType = flatDataContext._heatingType;
      heatingData.heatingSystem = flatDataContext._heatingSystem;
      heatingData.heatingFuel = flatDataContext._heatingFuel;
      heatingData.heatingEfficiency = flatDataContext._heatingEfficiency;
      heatingData.coolingEfficiency = flatDataContext._coolingEfficiency;
      heatingData.heatingSystemEfficiency =
        flatDataContext.heatingSystemEfficiency;
      heatingData.subdevicesEnergyConsumption =
        flatDataContext._subdevicesEnergyConsumption;
      heatingData.waterSubdevicesEnergyConsumption =
        flatDataContext._waterSubdevicesEnergyConsumption;

      heatingData.heatingTypeId = HeatingSourceType.getHeatingSourceTypeById(
        flatDataContext._heatingTypeId
      );

      heatingData.pricePerKWh = flatDataContext._pricePerKWh;
      heatingData.naturalUnitPrice = flatDataContext._naturalUnitPrice;
      heatingData.naturalUnit = flatDataContext._naturalUnit;
      heatingData.heatingValueEditable = flatDataContext._heatingValueEditable;
      heatingData.showNaturalUnit = flatDataContext._showNaturalUnit;
      heatingData.calorificValue = flatDataContext._calorificValue;
      heatingData.calorificValueUnit = flatDataContext._calorificValueUnit;
      heatingData.waterHeatingSource = flatDataContext._waterHeatingSource;
      heatingData.waterHeatingPlacement =
        flatDataContext._waterHeatingPlacement;
      heatingData.waterHeatingType = flatDataContext._waterHeatingType;
      heatingData.waterHeatingFuel = flatDataContext._waterHeatingFuel;
      heatingData.waterHeatingEfficiency =
        flatDataContext._waterHeatingEfficiency;
      heatingData.waterPricePerKWh = flatDataContext._waterPricePerKWh;
      heatingData.electricityPricePerKWh =
        flatDataContext._electricityPricePerKWh;
      heatingData.finalToPrimaryCoefficient =
        flatDataContext._finalToPrimaryCoefficient;
      heatingData.warmWaterFinalToPrimaryCoefficient =
        flatDataContext._warmWaterFinalToPrimaryCoefficient;
      heatingData.emissionCoefficient = flatDataContext._emissionCoefficient;
      heatingData.waterEmissionCoefficient =
        flatDataContext._waterEmissionCoefficient;
      heatingData.waterSavings = flatDataContext._waterSavings;
    } catch (e) {
      console.log(e);
    }

    dataContext.heatingData = heatingData;

    let solarCollectorData = new SolarCollectorData();

    try {
      solarCollectorData.collectorType = SolarCollectorType.getSolarCollectorTypeById(
        flatDataContext._collectorTypeId
      );
      solarCollectorData.surface = flatDataContext._surface;
      solarCollectorData.count = flatDataContext._count;
    } catch (e) {
      console.log(e);
    }

    dataContext.solarCollectorData = solarCollectorData;

    let houseData = dataContext.houseData;

    try {
      houseData.houseType = flatDataContext._houseType;
      houseData.showDetailedData = flatDataContext.showDetailedData;
      houseData.detailedDataChanged = flatDataContext.detailedDataChanged;

      houseData.buildingType = flatDataContext._buildingType;
      houseData.buildingAge = BuildingAge.getBuildingAgeById(
        flatDataContext._buildingAgeId
      );
      houseData.doorType = DoorType.getDoorTypeById(
        flatDataContext._doorTypeId
      );
      houseData.personNumber = flatDataContext._personNumber;
      houseData.livingNeeds = flatDataContext._livingNeeds;
      houseData.livingNeedsChanged = flatDataContext._livingNeedsChanged;
      houseData.foundationType = FoundationsType.getFoundationById(
        flatDataContext._foundationsTypeId
      );
      houseData.tIn = flatDataContext._tIn;
      houseData.standardType = HouseStandardTypeItem.getHouseStandardTypeItemById(
        flatDataContext._houseStandardTypeId
      );
    } catch (e) {
      console.log(e);
    }

    let warmWaterData = houseData.warmWaterData;

    try {
      warmWaterData.warmWaterDistribution = WarmWaterDistribution.getWarmWaterDistributionById(
        flatDataContext._warmWaterDistributionId
      );
      warmWaterData.warmWaterStorage = WarmWaterStorage.getWarmWaterStorageById(
        flatDataContext._warmWaterStorageId
      );
      warmWaterData.distributorEfficiency =
        flatDataContext._distributorEfficiency;
      warmWaterData.storeEfficiency = flatDataContext._storeEfficiency;
      warmWaterData.consumptionIntensityType = WarmWaterConsumptionType.getWarmWaterConsumptionForIntensity(
        flatDataContext._consumptionIntensityId
      );
    } catch (e) {
      console.log(e);
    }

    let ventilationData = houseData.ventilationData;

    try {
      ventilationData.gwcSet = flatDataContext._gwcSet;
      ventilationData.ventilationMethodObject = VentilationMethod.getVentilationMethodById(
        flatDataContext._ventilation
      );
      ventilationData.ventilationFreq = flatDataContext._ventilationFreq;
      ventilationData.co2Sensor = flatDataContext._co2Sensor;
      ventilationData.airTightness = AirTightness.getAirTightnessById(
        flatDataContext._airTightness
      );
      ventilationData.humidification = flatDataContext._humidification;
      ventilationData.patency = flatDataContext._patency;
      ventilationData.recuperator = flatDataContext._recuperator;
      ventilationData.isMechanical = flatDataContext._isMechanical;
      ventilationData.recuperatorLocation =
        flatDataContext._recuperatorLocation;
      ventilationData.n50 = flatDataContext._n50;
    } catch (e) {
      console.log(e);
    }

    houseData.ventilationData = ventilationData;

    houseData.wallElement = flatDataContext._wall;
    houseData.roofElement = flatDataContext._roof;

    let basementFloorElement = new InsulationElement();

    try {
      basementFloorElement.width = flatDataContext._basementFloorWidth;
      basementFloorElement.uValue = flatDataContext._basementFloorUValue;
      basementFloorElement.material = flatDataContext._basementFloorMaterial;
      basementFloorElement.heatTransfer =
        flatDataContext._basementFloorHeatTransfer;
    } catch (e) {
      console.log(e);
    }

    houseData.basementFloorElement = basementFloorElement;

    let basementWallElement = new InsulationElement();

    try {
      basementWallElement.width = flatDataContext._basementWallWidth;
      basementWallElement.uValue = flatDataContext._basementWallUValue;
      basementWallElement.material = flatDataContext._basementWallMaterial;
      basementWallElement.heatTransfer =
        flatDataContext._basementWallHeatTransfer;
    } catch (e) {
      console.log(e);
    }

    houseData.basementWallElement = basementWallElement;
    houseData.floorElement = flatDataContext._floor;

    let windowElement = new WindowElement(
      WindowsType.getWindowsTypeById(flatDataContext._windowsTypeId)
    );
    let windowElementPreparer = new WindowElementPreparer();
    windowElement = windowElementPreparer.prepare(windowElement);

    try {
      windowElement.shutters = flatDataContext._shutters;
      windowElement.thermalBridgesType = ThermalBridgesType.getThermalBridgesTypeById(
        flatDataContext._thermalBridge
      );
    } catch (e) {
      console.log(e);
    }

    houseData.windowElement = windowElement;

    let surfaceData = new SurfaceData(
      flatDataContext._floorCount,
      flatDataContext._floorHeight,
      flatDataContext._totalSurface,
      flatDataContext._atticSurface
    );

    try {
      surfaceData = flatDataContext.surfaceData;
    } catch (e) {
      console.log(e);
    }

    houseData.surfaceData = surfaceData;
    houseData.roofData = flatDataContext.roofData;
    houseData.basement = flatDataContext._basement;
    houseData.wallsData = flatDataContext.wallsData;

    houseData.pvData = flatDataContext._pvData;
    houseData.acData = flatDataContext.acData;
    houseData.insolationData = flatDataContext._insolationData;
    houseData.irrData = flatDataContext._irrData;
    houseData.irrData2 = flatDataContext.irrData2;

    dataContext.costs = flatDataContext.costs;
    return dataContext;
  };

  static updateStoreWithDataContext = flatDataContext => {
    let dataContext = new DataContext();

    let heatingData = {};
    try {
      heatingData.calorificValue = flatDataContext._heatingData._calorificValue;
      heatingData.calorificValueUnit =
        flatDataContext._heatingData._calorificValueUnit;
      heatingData.coolingEfficiency =
        flatDataContext._heatingData._coolingEfficiency;
      heatingData.subdevicesEnergyConsumption =
        flatDataContext._heatingData._subdevicesEnergyConsumption;
      heatingData.waterSubdevicesEnergyConsumption =
        flatDataContext._heatingData._waterSubdevicesEnergyConsumption;
      heatingData.emissionCoefficient =
        flatDataContext._heatingData._emissionCoefficient;
      heatingData.finalToPrimaryCoefficient =
        flatDataContext._heatingData._finalToPrimaryCoefficient;
      heatingData.heatingEfficiency =
        flatDataContext._heatingData._heatingEfficiency;
      heatingData.heatingSystemEfficiency =
        flatDataContext._heatingData.heatingSystemEfficiency;
      heatingData.heatingFuel = flatDataContext._heatingData._heatingFuel;

      heatingData.heatingSource = flatDataContext._heatingData._heatingSource;
      heatingData.heatingType = flatDataContext._heatingData._heatingType;
      heatingData.heatingSystem = flatDataContext._heatingData._heatingSystem;

      heatingData.heatingTypeId = flatDataContext._heatingData._heatingTypeId;
      heatingData.naturalUnitPrice =
        flatDataContext._heatingData._naturalUnitPrice;
      heatingData.naturalUnit = flatDataContext._heatingData._naturalUnit;
      heatingData.heatingValueEditable =
        flatDataContext._heatingData._heatingValueEditable;
      heatingData.showNaturalUnit =
        flatDataContext._heatingData._showNaturalUnit;
      heatingData.pricePerKWh = flatDataContext._heatingData._pricePerKWh;
      heatingData.waterPricePerKWh =
        flatDataContext._heatingData._waterPricePerKWh;
      heatingData.electricityPricePerKWh =
        flatDataContext._heatingData._electricityPricePerKWh;
      heatingData.warmWaterFinalToPrimaryCoefficient =
        flatDataContext._heatingData._warmWaterFinalToPrimaryCoefficient;
      heatingData.waterEmissionCoefficient =
        flatDataContext._heatingData._waterEmissionCoefficient;
      heatingData.waterHeatingEfficiency =
        flatDataContext._heatingData._waterHeatingEfficiency;
      heatingData.waterHeatingSource =
        flatDataContext._heatingData._waterHeatingSource;
      heatingData.waterHeatingPlacement =
        flatDataContext._heatingData._waterHeatingPlacement;
      heatingData.waterHeatingType =
        flatDataContext._heatingData._waterHeatingType;
      heatingData.waterHeatingFuel =
        flatDataContext._heatingData._waterHeatingFuel;
      heatingData.waterSavings = flatDataContext._heatingData._waterSavings;

      store.dispatch(
        updateHeatingAll({
          heatingData: heatingData,
        })
      );

      dataContext.heatingData = heatingData;
    } catch (e) {
      console.log(e);
    }

    let solarCollectorData = new SolarCollectorData();

    try {
      solarCollectorData.collectorType =
        flatDataContext._solarCollectorData._collectorType;
      solarCollectorData.surface = flatDataContext._solarCollectorData._surface;
      solarCollectorData.count = flatDataContext._solarCollectorData._count;
      store.dispatch(
        updateCollectors({
          collectorsData: solarCollectorData,
        })
      );
    } catch (e) {
      console.log(e);
    }

    let houseData = {};

    houseData.houseType = flatDataContext._houseData._houseType;
    houseData.buildingType = flatDataContext._houseData._buildingType;
    houseData.buildingAgeId = BuildingAge.getBuildingAgeById(
      flatDataContext._houseData._buildingAge.id
    );
    houseData.standardTypeId = flatDataContext._houseData._standardType.id;
    houseData.country = flatDataContext._countryCode;
    houseData.inHouseTemp = flatDataContext._houseData._tIn;
    houseData.location = flatDataContext._selectedStation;
    houseData.region = flatDataContext._selectedCountryRegion;
    houseData.showDetailedData = flatDataContext._houseData.showDetailedData;
    houseData.detailedDataChanged =
      flatDataContext._houseData.detailedDataChanged;
    houseData.surfaceData = flatDataContext._houseData.surfaceData;

    houseData.roofData = flatDataContext._houseData.roofData;
    houseData.basement = flatDataContext._houseData._basement;
    houseData.wallsData = flatDataContext._houseData.wallsData;

    store.dispatch(
      updateHouse({
        houseData: houseData,
      })
    );

    store.dispatch(
      updateHeatingById({
        id: "includeWarmWater",
        value: flatDataContext._includeWarmWater,
      })
    );

    let warmWaterData = {};

    warmWaterData.warmWaterDistribution =
      flatDataContext._houseData._warmWaterData._warmWaterDistribution.id;
    warmWaterData.warmWaterStorage =
      flatDataContext._houseData._warmWaterData._warmWaterStorage.id;
    warmWaterData.distributorEfficiency =
      flatDataContext._houseData._warmWaterData._distributorEfficiency;
    warmWaterData.storeEfficiency =
      flatDataContext._houseData._warmWaterData._storeEfficiency;
    store.dispatch(
      updateDistribution({
        heatingData: warmWaterData,
      })
    );
    store.dispatch(
      updateHeatingById({
        id: "consumptionIntensityId",
        value:
          flatDataContext._houseData._warmWaterData.consumptionIntensityType.id,
      })
    );

    store.dispatch(
      updateWindows(
        flatDataContext._houseData._windowElement._windowsType.id,
        flatDataContext._houseData._windowElement._thermalBridgesType.id,
        flatDataContext._houseData._windowElement._shutters
      )
    );
    const ventilationData = {
      ventilation:
        flatDataContext._houseData._ventilationData._ventilationMethodObject.id,
      airTightness:
        flatDataContext._houseData._ventilationData._airTightness.id,
      co2Sensor: flatDataContext._houseData._ventilationData._co2Sensor,
      gwcSet: flatDataContext._houseData._ventilationData._gwcSet,
      isMechanical:
        VentilationMethod.getVentilationMethodById(
          flatDataContext._houseData._ventilationData._ventilationMethodObject
            .id
        ).type === VentilationMethod.MECHANICAL,
      humidification:
        flatDataContext._houseData._ventilationData._humidification,
      patency: flatDataContext._houseData._ventilationData._patency,
      ventilationFreq:
        flatDataContext._houseData._ventilationData._ventilationFreq,
      recuperator: flatDataContext._houseData._ventilationData._recuperator,
      recuperatorLocation:
        flatDataContext._houseData._ventilationData._recuperatorLocation,
      n50: flatDataContext._houseData._ventilationData._n50,
    };

    store.dispatch(updateVentilation(ventilationData));

    let insulationData = {};
    insulationData.floor = {
      ...flatDataContext._houseData._floorElement,
      foundations: flatDataContext._houseData._foundationType.id,
    };
    insulationData.wall = {
      ...flatDataContext._houseData._wallElement,
      doorType: flatDataContext._houseData._doorType.id,
    };
    insulationData.roof = flatDataContext._houseData._roofElement;
    insulationData.basement = {};
    insulationData.basement.wall = {
      material: flatDataContext._houseData._basementWallElement.material,
      width: flatDataContext._houseData._basementWallElement.width,
      uValue: flatDataContext._houseData._basementWallElement.uValue,
      heatTransfer:
        flatDataContext._houseData._basementWallElement.heatTransfer,
    };

    insulationData.basement.floor = {
      material: flatDataContext._houseData._basementFloorElement.material,
      width: flatDataContext._houseData._basementFloorElement.width,
      uValue: flatDataContext._houseData._basementFloorElement.uValue,
      heatTransfer:
        flatDataContext._houseData._basementFloorElement.heatTransfer,
    };

    store.dispatch(
      updateInsulation({
        insulationData: insulationData,
      })
    );

    store.dispatch(
      updatePersons({
        persons: flatDataContext._houseData._personNumber,
        livingNeeds: flatDataContext._houseData._livingNeeds,
        livingNeedsChanged: flatDataContext._houseData._livingNeedsChanged,
      })
    );

    let conversionData = {};

    conversionData.currencyLocaleCode =
      flatDataContext._conversionData._currencyLocaleCode;
    conversionData.currencyName = flatDataContext._conversionData._currencyName;
    conversionData.electricityPricePerKWh =
      flatDataContext._conversionData._electricityPricePerKWh;
    conversionData.electricityPricePerKWhLocal =
      flatDataContext._conversionData._electricityPricePerKWhLocal;
    conversionData.electricityEmissionCoefficient =
      flatDataContext._conversionData._electricityEmissionCoefficient;
    conversionData.isElectric = flatDataContext._conversionData._isElectric;
    conversionData.isWaterElectric =
      flatDataContext._conversionData._isWaterElectric;
    conversionData.pvPricePerKWh =
      flatDataContext._conversionData._pvPricePerKWh;
    conversionData.emissionCoefficient =
      flatDataContext._heatingData._emissionCoefficient;
    conversionData.finalToPrimaryCoefficient =
      flatDataContext._heatingData._finalToPrimaryCoefficient;
    conversionData.houseHeatingEfficiency =
      flatDataContext._heatingData._heatingEfficiency *
      flatDataContext._heatingData.heatingSystemEfficiency;

    const costInEuro = ConversionUnits.isEuroCostUnit(
      flatDataContext._conversionData._selectedUnit
    );
    const costUnit = ConversionUnits.isCostUnit(
      flatDataContext._conversionData._selectedUnit
    );
    if (costUnit && costInEuro) {
      conversionData.pricePerKWh = flatDataContext._conversionData._pricePerKWh;
      conversionData.pricePerKWh = HeatingSourceType.toPricePerKWh(
        flatDataContext._conversionData._naturalUnitPrice,
        flatDataContext._heatingData._calorificValue,
        flatDataContext._heatingData._naturalUnit,
        flatDataContext._heatingData._calorificValueUnit
      );
    } else {
      conversionData.pricePerKWh = HeatingSourceType.toPricePerKWh(
        flatDataContext._conversionData._naturalUnitPriceLocal,
        flatDataContext._heatingData._calorificValue,
        flatDataContext._heatingData._naturalUnit,
        flatDataContext._heatingData._calorificValueUnit
      );
    }

    conversionData.waterPricePerKWh =
      flatDataContext._conversionData._waterPricePerKWh;

    conversionData.selectedUnit = flatDataContext._conversionData._selectedUnit;
    conversionData.shortUnitName =
      flatDataContext._conversionData._shortUnitName;
    conversionData.toPLNCurrencyExchangeRate =
      flatDataContext._conversionData._toPLNCurrencyExchangeRate;
    conversionData.naturalUnitPrice =
      flatDataContext._conversionData._naturalUnitPrice;
    conversionData.naturalUnitPriceLocal =
      flatDataContext._conversionData._naturalUnitPriceLocal;
    conversionData.naturalUnitPriceChanged =
      flatDataContext._conversionData._naturalUnitPriceChanged;
    conversionData.unitName = flatDataContext._conversionData._unitName;
    conversionData.warmWaterFinalToPrimaryCoefficient =
      flatDataContext._heatingData._warmWaterFinalToPrimaryCoefficient;
    conversionData.warmWaterHeatingEfficiency =
      flatDataContext._heatingData._waterHeatingEfficiency;
    conversionData.waterEmissionCoefficient =
      flatDataContext._heatingData._waterEmissionCoefficient;

    conversionData.heatingSource = flatDataContext._heatingData._heatingSource;
    conversionData.waterHeatingSource =
      flatDataContext._heatingData._waterHeatingSource;
    conversionData.waterHeatingPlacement =
      flatDataContext._heatingData._waterHeatingPlacement;
    conversionData.includeWarmWater = flatDataContext._includeWarmWater;
    conversionData.warmWaterStorageId =
      flatDataContext._houseData._warmWaterData._warmWaterStorage.id;

    let ac = flatDataContext._houseData.acData;
    conversionData.airConditioning = ac.airconditioning;
    store.dispatch(
      updateConversion({
        conversionData: conversionData,
      })
    );

    let pv = flatDataContext._houseData._pvData;

    store.dispatch(
      updatePhotovoltaic({
        data: pv,
      })
    );

    store.dispatch(updateCosts(flatDataContext.costs));

    let irr = flatDataContext._houseData._irrData;

    store.dispatch(
      updateReports({
        data: irr,
      })
    );

    store.dispatch(
      updateAirconditioning({
        data: ac,
      })
    );

    let insolation = flatDataContext._houseData._insolationData;

    store.dispatch(
      updateInsolation({
        data: insolation,
      })
    );

    return dataContext;
  };
}

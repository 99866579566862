import { windowBreakPoints } from "../../constants/windowBreakpoints";
import { UPDATE_BREAKPOINTS } from "./actions";

const initialState = {
  breakpoints: windowBreakPoints.filter(width => window.innerWidth >= width),
};

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BREAKPOINTS: {
      return {
        ...state,
        breakpoints: action.payload,
      };
    }

    default:
      return state;
  }
};

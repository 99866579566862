export class ShadingHelper {
  static getMonthShading = (shadingArray, month) => {
    let result = {};
    for (let k in shadingArray) {
      if (shadingArray.hasOwnProperty(k)) {
        if (shadingArray[k] === true) {
          if (shadingArray[k + "_val"] != null) {
            result[k] = shadingArray[k + "_val"][month];
          }
        }
      }
    }
    return result;
  };
}

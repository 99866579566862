import { AbstractDictionaryReader } from "./AbstractDictionaryReader";
import { VentilationTypeConfig } from "../VentilationTypeConfig";
import { VentilationMethod } from "../model/VentilationMethod";

export class VentilationTypeConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    let ventilationTypes = this.readVentilationTypes(
      dictionaryXML.ventilation_types
    );
    let ventilationTypeConfig = new VentilationTypeConfig(ventilationTypes);
    ventilationTypeConfig.selectedIndex = this.getSelectedIndex(
      dictionaryXML.ventilation_types
    );

    dictionaryConfig.ventilationTypeConfiguration = ventilationTypeConfig;
    return dictionaryConfig;
  };

  readVentilationTypes = ventilationTypesXML => {
    let ventilationTypes = [];
    ventilationTypesXML.ventilation_type.forEach(ventilationTypeXML => {
      ventilationTypes.push(this.readVentilationType(ventilationTypeXML));
    });

    return ventilationTypes;
  };

  readVentilationType = ventilationTypeXML => {
    let id = ventilationTypeXML.id;
    let ventType =
      ventilationTypeXML.type === "1"
        ? VentilationMethod.GRAVITATIONAL
        : VentilationMethod.MECHANICAL;
    let efficiency = Number(ventilationTypeXML.efficiency);
    let naturalRegulated = this.readBoolean(
      ventilationTypeXML,
      "natural_regulated"
    );
    let humidification = ventilationTypeXML.humidification;
    let defrosting = ventilationTypeXML.defrosting;
    let recuperator = ventilationTypeXML.recuperator;
    let multiHouse = ventilationTypeXML.multi_house;
    let multiHouseAggregated = ventilationTypeXML.multi_house_aggregated;
    let versions = ventilationTypeXML.versions;

    let ventilationMethod = new VentilationMethod(
      id,
      ventType,
      efficiency,
      naturalRegulated,
      recuperator,
      humidification,
      defrosting,
      multiHouse,
      multiHouseAggregated,
      versions
    );
    this.fillBaseProperties(ventilationTypeXML, ventilationMethod);
    return ventilationMethod;
  };
}

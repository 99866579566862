export const UPDATE_WINDOWS = "UPDATE_WINDOWS";
export const UPDATE_VENTILATION = "UPDATE_VENTILATION";
export const UPDATE_VENTILATION_BY_ID = "UPDATE_VENTILATION_BY_ID";
export const UPDATE_PERSONS = "UPDATE_PERSONS";
export const UPDATE_INSULATION = "UPDATE_INSULATION";
export const UPDATE_INSULATION_BY_TYPE = "UPDATE_INSULATION_BY_TYPE";
export const UPDATE_INSULATION_BY_TYPE_AND_ID =
  "UPDATE_INSULATION_BY_TYPE_AND_ID";
export const UPDATE_INSULATION_BASEMENT_BY_TYPE =
  "UPDATE_INSULATION_BASEMENT_BY_TYPE";
export const UPDATE_HOUSE = "UPDATE_HOUSE";
export const UPDATE_HOUSE_BY_ID = "UPDATE_HOUSE_BY_ID";
export const UPDATE_HOUSE_ROOF_BY_ID = "UPDATE_HOUSE_ROOF_BY_ID";
export const UPDATE_HOUSE_ROOF = "UPDATE_HOUSE_ROOF";
export const UPDATE_HOUSE_BASEMENT_BY_ID = "UPDATE_HOUSE_BASEMENT_BY_ID";
export const UPDATE_HOUSE_BASEMENT = "UPDATE_HOUSE_BASEMENT";
export const UPDATE_SURFACE = "UPDATE_SURFACE";
export const UPDATE_HEATING = "UPDATE_HEATING";
export const UPDATE_HEATING_BY_ID = "UPDATE_HEATING_BY_ID";
export const UPDATE_HEATING_AND_WATER = "UPDATE_HEATING_AND_WATER";
export const UPDATE_WATER = "UPDATE_WATER";
export const UPDATE_COLLECTORS = "UPDATE_COLLECTORS";
export const UPDATE_CONVERSION = "UPDATE_CONVERSION";
export const UPDATE_DISTRIBUTION = "UPDATE_DISTRIBUTION";
export const UPDATE_HEATING_ALL = "UPDATE_HEATING_ALL";
export const ADD_HOUSE_WALL = "ADD_HOUSE_WALL";
export const UPDATE_HOUSE_WALL = "UPDATE_HOUSE_WALL";
export const DELETE_HOUSE_WALL = "DELETE_HOUSE_WALL";
export const REPLACE_HOUSE_WALLS = "REPLACE_HOUSE_WALLS";
export const UPDATE_ALL = "UPDATE_ALL";
export const UPDATE_PHOTOVOLTAIC = "UPDATE_PHOTOVOLTAIC";
export const UPDATE_PHOTOVOLTAIC_BY_ID = "UPDATE_PHOTOVOLTAIC_BY_ID";
export const UPDATE_BY_ID = "UPDATE_BY_ID";
export const UPDATE_AIRCONDITIONING_BY_ID = "UPDATE_AIRCONDITIONING_BY_ID";
export const UPDATE_AIRCONDITIONING = "UPDATE_AIRCONDITIONING";
export const UPDATE_INSOLATION = "UPDATE_INSOLATION";
export const UPDATE_REPORTS = "UPDATE_REPORTS";

export const UPDATE_COSTS = "UPDATE_COSTS";

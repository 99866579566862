import React from "react";
import { connect } from "react-redux";
import { updatePersons } from "../../redux/actions";
import { getPersons } from "../../redux/selectors";

import { FormattedMessage, injectIntl } from "react-intl";

import NumericInput from "react-numeric-input";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";

function PersonalGainInput(props) {
  const personChanged = e => {
    props.dispatch(updatePersons({ persons: e })).then(() => {
      props.writeCache();
    });
  };

  const renderIcons = () => {
    let persons = [];
    for (let i = 0; i < props.persons.persons; i++) {
      persons.push(
        <IcomoonReact
          key={"person-icon" + i}
          className={"person-icon"}
          iconSet={iconSet}
          size={16}
          icon="vital_heat_icon"
        />
      );
    }
    return persons;
  };

  return (
    <>
      <div className={"d-flex align-items-center"}>
        <NumericInput
          min={1}
          max={10}
          value={props.persons.persons}
          onChange={e => personChanged(e)}
          className={"short-input"}
        />
        <div className={"persons"}>{renderIcons()}</div>
      </div>
      <FormattedMessage
        id="hm.label.person_number"
        defaultMessage=""
        tagName={"p"}
      >
        {message => <p className={"mb-0"}>{message}</p>}
      </FormattedMessage>
    </>
  );
}
const mapStateToProps = state => {
  const persons = getPersons(state);
  return { persons };
};
export default injectIntl(connect(mapStateToProps)(PersonalGainInput));

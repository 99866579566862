import { AbstractSurfaceCalculator } from "./AbstractSurfaceCalculator";
import { HouseTypeHelper } from "../../../../util/HouseTypeHelper";
import { RoofType } from "../../../../constants/RoofType";
import { Directions } from "../../../../constants/Directions";

export class DetachedHouseSurfaceCalculator extends AbstractSurfaceCalculator {
  doCalculation = (surfaceData, type, roofData) => {
    const strop = 0.3;
    const floorCount = roofData.attic
      ? surfaceData.floorCount - 1
      : surfaceData.floorCount;
    let roofWallWE = 0;
    let roofWallN = 0;
    let roofWallS = 0;
    const totalSurface = surfaceData.totalSurface * 1.12;

    surfaceData.floorArea = totalSurface / surfaceData.floorCount;
    surfaceData.floorSurface = surfaceData.floorArea;
    surfaceData.atticSurface = surfaceData.floorArea;

    let floorSurfaceData = this.calcFloorSurfaceData(
      surfaceData.floorArea,
      type
    );
    const roofHeight = this.calcRoofHeight(floorSurfaceData.b, roofData.angle);
    const wallLength = 2 * (floorSurfaceData.a + floorSurfaceData.b);
    surfaceData.wallSurface =
      wallLength * surfaceData.floorHeight * floorCount +
      wallLength * strop * (surfaceData.floorCount - 1);
    surfaceData.roofSurface = 1.2 * surfaceData.floorArea;
    surfaceData.windowsSurface = 0.2 * totalSurface;
    surfaceData.windowsSurfaceS = surfaceData.windowsSurface * 0.45;
    surfaceData.windowsSurfaceN = surfaceData.windowsSurface * 0.15;
    surfaceData.windowsSurfaceWE = surfaceData.windowsSurface * 0.4;

    surfaceData.floorSurfaceC =
      (Math.sqrt(surfaceData.floorSurface) - 2) *
      (Math.sqrt(surfaceData.floorSurface) - 2);
    surfaceData.floorSurfaceR =
      surfaceData.floorSurface - surfaceData.floorSurfaceC;

    surfaceData.wallSurface = Math.max(
      surfaceData.wallSurface - surfaceData.windowsSurface,
      0
    );
    surfaceData.insulatedWallSurface = surfaceData.wallSurface;
    surfaceData.nonInsulatedWallSurface = 0;

    if (roofData.type === RoofType.FLAT) {
      surfaceData.atticWallSurface = 0;
      surfaceData.roofWallSurface = 0;
      surfaceData.roofSurface = surfaceData.floorArea;
      surfaceData.insulatedAtticWall = 0;
      surfaceData.nonInsulatedAtticWall = 0;
    } else if (roofData.type === RoofType.COMPLICATED) {
      surfaceData.roofSurface = Math.abs(
        (surfaceData.floorArea /
          Math.cos(this.degreesToRadians(roofData.angle))) *
          1.05 *
          1.2
      );
      surfaceData.atticWallSurface = roofData.attic
        ? wallLength * roofData.kneewall
        : 0;
      surfaceData.insulatedAtticWall = surfaceData.atticWallSurface;
      surfaceData.nonInsulatedAtticWall = 0;
      surfaceData.roofWallSurface = 0;
    } else {
      surfaceData.roofSurface = Math.abs(
        (surfaceData.floorArea /
          Math.cos(this.degreesToRadians(roofData.angle))) *
          1.05
      );
      surfaceData.atticWallSurface = roofData.attic
        ? wallLength * roofData.kneewall
        : 0;
      surfaceData.insulatedAtticWall = surfaceData.atticWallSurface;
      surfaceData.nonInsulatedAtticWall = 0;
      if (roofData.type === RoofType.PITCHED) {
        roofWallWE = floorSurfaceData.b * roofHeight;
        roofWallN = floorSurfaceData.a * roofHeight;
        surfaceData.roofWallSurface = roofWallWE + roofWallN;
      } else if (roofData.type === RoofType.GABLE) {
        roofWallWE = (floorSurfaceData.b * roofHeight) / 2;
        surfaceData.roofWallSurface = roofWallWE / 2;
      }
    }
    surfaceData.insulatedWallSurface +=
      surfaceData.insulatedAtticWall + surfaceData.roofWallSurface;

    surfaceData.wallsCount = 4;
    surfaceData.wallsData = [
      {
        width:
          floorSurfaceData.a * surfaceData.floorHeight * floorCount +
          floorSurfaceData.a * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.a * roofData.kneewall : 0) +
          roofWallN,
        direction: Directions.NORTH,
        window: surfaceData.windowsSurfaceN,
        insulated: true,
      },
      {
        width:
          floorSurfaceData.b * surfaceData.floorHeight * floorCount +
          floorSurfaceData.b * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.b * roofData.kneewall : 0) +
          roofWallWE / 2,
        direction: Directions.EAST,
        window: surfaceData.windowsSurfaceWE / 2,
        insulated: true,
      },
      {
        width:
          floorSurfaceData.a * surfaceData.floorHeight * floorCount +
          floorSurfaceData.a * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.a * roofData.kneewall : 0) +
          roofWallS,
        direction: Directions.SOUTH,
        window: surfaceData.windowsSurfaceS,
        insulated: true,
      },
      {
        width:
          floorSurfaceData.b * surfaceData.floorHeight * floorCount +
          floorSurfaceData.b * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.b * roofData.kneewall : 0) +
          roofWallWE / 2,
        direction: Directions.WEST,
        window: surfaceData.windowsSurfaceWE / 2,
        insulated: true,
      },
    ];

    return surfaceData;
  };

  supportsHouseType = type => HouseTypeHelper.isDetachedType(type);
}

import { HouseStandardTypeConfig } from "../HouseStandardTypeConfig";
import { SolarCollectorType } from "../model/SolarCollectorType";
import { HouseStandardTypeItem } from "../model/HouseStandardTypeItem";
import { HouseStandardTypeModel } from "../../../core/model/HouseStandardTypeModel";
import { AbstractDictionaryReader } from "./AbstractDictionaryReader";
import { InsulationMaterialType } from "../model/InsulationMaterialType";
import { VentilationMethod } from "../model/VentilationMethod";
import { FoundationsType } from "../model/FoundationsType";
import { DoorType } from "../model/DoorType";
import { ThermalBridgesType } from "../model/ThermalBridgesType";
import { AirTightness } from "../model/AirTightness";
import { WindowsType } from "../model/WindowsType";
import { WarmWaterStorage } from "../model/WarmWaterStorage";
import { WarmWaterDistribution } from "../model/WarmWaterDistribution";
import { SolarCollectorData } from "../../../core/model/SolarCollectorData";

export class HouseStandardTypeConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    dictionaryConfig.houseStandardTypeConfiguration = new HouseStandardTypeConfig(
      this.readHouseStandardTypes(dictionaryXML.standard_type_configuration)
    );
    return dictionaryConfig;
  };

  readHouseStandardTypes = houseStandardTypesXML => {
    let standardTypeItems = [];
    houseStandardTypesXML.forEach(houseStandardTypeXML => {
      standardTypeItems.push(this.readHouseStandardType(houseStandardTypeXML));
    });
    return standardTypeItems;
  };

  readHouseStandardType = houseStandardTypeXML => {
    const id = houseStandardTypeXML.id;
    const roofInsulationMaterialTypeId =
      houseStandardTypeXML.roof_insulation_material_type;
    const wallInsulationMaterialTypeId =
      houseStandardTypeXML.wall_insulation_material_type;
    const floorInsulationMaterialTypeId =
      houseStandardTypeXML.floor_insulation_material_type;
    const basementWallInsulationMaterialTypeId =
      houseStandardTypeXML.basement_wall_insulation_material_type;
    const basementFloorInsulationMaterialTypeId =
      houseStandardTypeXML.basement_floor_insulation_material_type;
    const ventilationTypeId = houseStandardTypeXML.ventilation_type;
    const foundationTypeId = houseStandardTypeXML.foundation_type;
    const thermalBridgesTypeId = houseStandardTypeXML.thermal_bridges_type;
    const doorTypeId = houseStandardTypeXML.door_type;
    const windowTypeId = houseStandardTypeXML.window_type;
    const tightnessTypeId = houseStandardTypeXML.tightness_item;
    const heatingSource = houseStandardTypeXML.heating_source;
    const heatingSourceType = houseStandardTypeXML.heating_source_type;
    const heatingSystem = houseStandardTypeXML.heating_system;
    const heatingFuel = houseStandardTypeXML.heating_fuel;
    const heatingEfficiency = houseStandardTypeXML.heating_efficiency;
    const coolingEfficiency = houseStandardTypeXML.cooling_efficiency;
    const waterHeatingEfficiency =
      houseStandardTypeXML.water_heating_efficiency;
    const waterHeatingSource = houseStandardTypeXML.water_heating_source;
    const waterHeatingSourceLocation =
      houseStandardTypeXML.water_heating_source_location;
    const waterHeatingFuel = houseStandardTypeXML.water_heating_fuel;
    const waterStorageId = houseStandardTypeXML.water_storage;
    const waterDistributionId = houseStandardTypeXML.water_distribution;
    const latest = houseStandardTypeXML.latest;
    const actual = houseStandardTypeXML.actual;

    const roofInsulationMaterialType = InsulationMaterialType.getMaterialInsulationTypeById(
      roofInsulationMaterialTypeId
    );
    if (roofInsulationMaterialType == null) {
      throw new Error(
        "Unrecognized roof insulation material type id: " +
          roofInsulationMaterialTypeId
      );
    }

    const wallInsulationMaterialType = InsulationMaterialType.getMaterialInsulationTypeById(
      wallInsulationMaterialTypeId
    );
    if (wallInsulationMaterialType == null) {
      throw new Error(
        "Unrecognized wall insulation material type id: " +
          wallInsulationMaterialTypeId
      );
    }

    const floorInsulationMaterialType = InsulationMaterialType.getMaterialInsulationTypeById(
      floorInsulationMaterialTypeId
    );
    if (floorInsulationMaterialType == null) {
      throw new Error(
        "Unrecognized floor insulation material type id: " +
          floorInsulationMaterialTypeId
      );
    }

    const basementFloorInsulationMaterialType = InsulationMaterialType.getMaterialInsulationTypeById(
      basementFloorInsulationMaterialTypeId
    );
    if (basementFloorInsulationMaterialType == null) {
      throw new Error(
        "Unrecognized floor insulation material type id: " +
          basementFloorInsulationMaterialType
      );
    }
    const basementWallInsulationMaterialType = InsulationMaterialType.getMaterialInsulationTypeById(
      basementWallInsulationMaterialTypeId
    );
    if (basementWallInsulationMaterialType == null) {
      throw new Error(
        "Unrecognized floor insulation material type id: " +
          basementWallInsulationMaterialType
      );
    }

    const ventilationMethod = VentilationMethod.getVentilationMethodById(
      ventilationTypeId
    );
    if (ventilationMethod == null) {
      throw new Error("Unrecognized ventilation type id: " + ventilationTypeId);
    }

    const foundationsType = FoundationsType.getFoundationById(foundationTypeId);
    if (foundationsType == null) {
      throw new Error("Unrecognized foundations type id: " + foundationTypeId);
    }

    const thermalBridgesType = ThermalBridgesType.getThermalBridgesTypeById(
      thermalBridgesTypeId
    );
    if (thermalBridgesType == null) {
      throw new Error(
        "Unrecognized thermal bridges type id: " + thermalBridgesTypeId
      );
    }

    const doorType = DoorType.getDoorTypeById(doorTypeId);
    if (doorType == null) {
      throw new Error("Unrecognized door type id: " + doorTypeId);
    }

    const windowType = WindowsType.getWindowsTypeById(windowTypeId);
    if (windowType == null) {
      throw new Error("Unrecognized window type id: " + windowTypeId);
    }

    const tightnessType = AirTightness.getAirTightnessById(tightnessTypeId);
    if (tightnessType == null) {
      throw new Error("Unrecognized tightness type id: " + tightnessTypeId);
    }

    const waterStorage = WarmWaterStorage.getWarmWaterStorageById(
      waterStorageId
    );
    if (waterStorage == null) {
      throw new Error("Unrecognized water storage type id: " + waterStorageId);
    }

    const waterDistribution = WarmWaterDistribution.getWarmWaterDistributionById(
      waterDistributionId
    );
    if (waterDistribution == null) {
      throw new Error(
        "Unrecognized water distribution id: " + waterDistributionId
      );
    }

    const solarCollectorsData = this.readSolarCollectorsData(
      houseStandardTypeXML.solar_collector_data
    );

    const roofUValue = Number(houseStandardTypeXML.roof_u_value);
    const wallUValue = Number(houseStandardTypeXML.wall_u_value);
    const floorUValue = Number(houseStandardTypeXML.floor_u_value);
    const basementFloorUValue = Number(
      houseStandardTypeXML.basement_floor_u_value
    );
    const basementWallUValue = Number(
      houseStandardTypeXML.basement_wall_u_value
    );
    const airConditioning = houseStandardTypeXML.air_conditioning;
    const pvType = parseFloat(houseStandardTypeXML.pv_type);
    const pvPower = parseFloat(houseStandardTypeXML.pv_power);

    const southWindowSurfaceFactor = this.readNumber(
      houseStandardTypeXML,
      "south_windows_surface_factor"
    );
    const northWindowsSurfaceFactor = this.readNumber(
      houseStandardTypeXML,
      "north_windows_surface_factor"
    );

    const shuttersEnabled = this.readBoolean(
      houseStandardTypeXML,
      "shutters_enabled"
    );
    const co2SensorEnabled = this.readBoolean(
      houseStandardTypeXML,
      "carbon_dioxide_sensor"
    );

    const houseStandardTypeModel = new HouseStandardTypeModel(
      roofInsulationMaterialType,
      wallInsulationMaterialType,
      floorInsulationMaterialType,
      basementWallInsulationMaterialType,
      basementFloorInsulationMaterialType,
      ventilationMethod,
      foundationsType,
      thermalBridgesType,
      doorType,
      windowType,
      heatingSource,
      heatingSourceType,
      heatingSystem,
      heatingFuel,

      heatingEfficiency,
      coolingEfficiency,
      waterHeatingEfficiency,
      waterHeatingSource,
      waterHeatingSourceLocation,
      waterHeatingFuel,
      solarCollectorsData,
      waterDistribution,
      waterStorage,
      roofUValue,
      wallUValue,
      floorUValue,
      basementFloorUValue,
      basementWallUValue,
      pvType,
      pvPower,
      tightnessType,
      southWindowSurfaceFactor,
      northWindowsSurfaceFactor,
      shuttersEnabled,
      co2SensorEnabled,
      latest,
      actual,
      airConditioning
    );

    const houseStandardTypeItem = new HouseStandardTypeItem(
      id,
      houseStandardTypeModel
    );
    this.fillBaseProperties(houseStandardTypeXML, houseStandardTypeItem);

    return houseStandardTypeItem;
  };

  readSolarCollectorsData = collectorsDataXML => {
    const collectorsTypeId = collectorsDataXML.solar_collector_type;
    const solarCollectorType = SolarCollectorType.getSolarCollectorTypeById(
      collectorsTypeId
    );
    if (solarCollectorType == null) {
      throw new Error(
        "Unrecognized solar collector type id: " + collectorsTypeId
      );
    }
    if (solarCollectorType.type === SolarCollectorType.NONE_TYPE) {
      return SolarCollectorData.createSolarCollectorData(solarCollectorType, 0);
    }

    const surface = Number(collectorsDataXML.surface);
    return SolarCollectorData.createSolarCollectorDataWithCountCalculated(
      solarCollectorType,
      surface
    );
  };
}

export class MonthInputData {
  _tOut;
  _tGround;

  _groundInsolation45;
  _groundInsolationSum;
  _southInsolation;
  _westEastInsolation;
  _northInsolation;
  _shadingData;

  constructor(
    tOut,
    tGround,
    groundInsolation45,
    groundInsolationSum,
    southInsolation,
    westEastInsolation,
    northInsolation,
    shadingData
  ) {
    this._tOut = tOut;
    this._tGround = tGround;
    this._groundInsolation45 = groundInsolation45;
    this._groundInsolationSum = groundInsolationSum;
    this._southInsolation = southInsolation;
    this._westEastInsolation = westEastInsolation;
    this._northInsolation = northInsolation;
    this._shadingData = shadingData;
  }

  get tOut() {
    return this._tOut;
  }

  set tOut(value) {
    this._tOut = value;
  }

  get tGround() {
    return this._tGround;
  }

  set tGround(value) {
    this._tGround = value;
  }

  get groundInsolationSum() {
    return this._groundInsolationSum;
  }

  set groundInsolationSum(value) {
    this._groundInsolationSum = value;
  }

  get groundInsolation45() {
    return this._groundInsolation45;
  }

  set groundInsolation45(value) {
    this._groundInsolation45 = value;
  }

  get southInsolation() {
    return this._southInsolation;
  }

  set southInsolation(value) {
    this._southInsolation = value;
  }

  get westEastInsolation() {
    return this._westEastInsolation;
  }

  set westEastInsolation(value) {
    this._westEastInsolation = value;
  }

  get northInsolation() {
    return this._northInsolation;
  }

  set northInsolation(value) {
    this._northInsolation = value;
  }

  get shadingData() {
    return this._shadingData;
  }

  set shadingData(value) {
    this._shadingData = value;
  }
}

import { OptimizerResultsState } from "../OptimizerResultsState";

export class AirConditioningOptimizer {
  constructor(
    isInReferenceState,
    buildingType,
    refPricePerKWh,
    newPricePerKWh
  ) {
    this.buildingType = buildingType;
    this.refPricePerKWh = refPricePerKWh;
    this.newPricePerKWh = newPricePerKWh;
    this.isInReferenceState = isInReferenceState;
  }
  elementList;

  validate = (
    type,
    result,
    heatingData,
    newHeatingData,
    element,
    newElement,
    surface,
    refCosts,
    newCosts
  ) => {
    result.state = OptimizerResultsState.THE_SAME;
    result.refItem = element
      ? {
          id: "hm.optimize.air_conditioning.yes",
          labelKey: "hm.optimize.air_conditioning.yes",
          value: 0,
          price:
            heatingData.heatingSource !== "hm.dropdown.heating.source.heat_pump"
              ? 3000
              : 0,
          priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
          work:
            heatingData.heatingSource !== "hm.dropdown.heating.source.heat_pump"
              ? 1500
              : 0,
          workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
          obsolescence: 25,
        }
      : {
          id: "hm.optimize.air_conditioning.no",
          labelKey: "hm.optimize.air_conditioning.no",
          value: 0,
          price: 0,
          priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
          work: 0,
          workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
          obsolescence: 25,
        };
    result.newItem = newElement
      ? {
          id: "hm.optimize.air_conditioning.yes",
          labelKey: "hm.optimize.air_conditioning.yes",
          value: 0,
          price:
            newHeatingData.heatingSource !==
            "hm.dropdown.heating.source.heat_pump"
              ? 3000
              : 0,
          priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
          work:
            newHeatingData.heatingSource !==
            "hm.dropdown.heating.source.heat_pump"
              ? 1500
              : 0,
          workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
          obsolescence: 25,
        }
      : {
          id: "hm.optimize.air_conditioning.no",
          labelKey: "hm.optimize.air_conditioning.no",
          value: 0,
          price: 0,
          priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
          work: 0,
          workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
          obsolescence: 25,
        };
    result.obsolescence = 25;

    if (element === true && newElement === false) {
      result.state = OptimizerResultsState.WORSE;
    } else if (element === false && newElement === true) {
      result.state = OptimizerResultsState.BETTER;
      result.refPricePerKWh = this.refPricePerKWh;
      result.newPricePerKWh = this.newPricePerKWh;

      let materialCost = result.refItem.price;
      let newMaterialCost = result.newItem.price;
      let workCost = result.refItem.work;
      let newWorkCost = result.newItem.work;

      if (refCosts[type + "_material_changed"]) {
        materialCost = refCosts[type + "_material"];
      }
      if (newCosts[type + "_material_changed"]) {
        newMaterialCost = newCosts[type + "_material"];
      }
      if (refCosts[type + "_work_changed"]) {
        workCost = refCosts[type + "_work"];
      }
      if (newCosts[type + "_work_changed"]) {
        newWorkCost = newCosts[type + "_work"];
      }

      result.workPrice = this.isInReferenceState ? workCost : newWorkCost;
      result.materialPrice = this.isInReferenceState
        ? materialCost
        : newMaterialCost;
      result.work =
        this.buildingType === "current" ? newWorkCost : newWorkCost - workCost;
      result.material =
        this.buildingType === "current"
          ? newMaterialCost
          : newMaterialCost - materialCost;
      result.obsolescence = result.newItem.obsolescence;
      result.service = result.material * 0.05;
      result.service = 0;
      result.cost =
        this.buildingType === "current"
          ? result.material + result.work + result.service
          : result.material + result.work;
      result.costView = result.workPrice + result.materialPrice;
    }
    return result;
  };
}

import { HeatingSourceTypeConfig } from "../HeatingSourceTypeConfig";
import { HeatingSourceType } from "../model/HeatingSourceType";
import { AbstractDictionaryReader } from "./AbstractDictionaryReader";
import { NaturalUnit } from "../../../constants/NaturalUnit";
import { HeatingType } from "../../../constants/HeatingType";
import { HeatingFuelsTypeConfig } from "../HeatingFuelsTypeConfig";

export class HeatingDeviceTypeConfigReader extends AbstractDictionaryReader {
  electricityHeatingSourceType = null;

  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    let heatingSourceTypesMap = dictionaryXML.heating_devices2;
    let heatingFuelTypesMap = this.readHeatingDeviceTypes(dictionaryXML.fuels);
    let devicePricesTypesMap = dictionaryXML.prices;

    let heatingSourceTypeConfiguration = new HeatingSourceTypeConfig();
    let heatingFuelsTypeConfiguration = new HeatingFuelsTypeConfig();
    heatingSourceTypeConfiguration.heatingDeviceTypes = heatingSourceTypesMap;
    heatingFuelsTypeConfiguration.heatingFuelTypes = heatingFuelTypesMap;
    heatingSourceTypeConfiguration.devicePricesTypesMap = devicePricesTypesMap;

    heatingSourceTypeConfiguration.electricityHeatingSourceType = this.electricityHeatingSourceType;
    heatingFuelsTypeConfiguration.electricityHeatingSourceType = this.electricityHeatingSourceType;

    dictionaryConfig.heatingDeviceTypeConfiguration = heatingSourceTypeConfiguration;
    dictionaryConfig.heatingFuelsTypeConfiguration = heatingFuelsTypeConfiguration;

    return dictionaryConfig;
  };

  readHeatingDeviceTypes = heatingSourceTypesXML => {
    let heatingSourceTypesMap = [];

    heatingSourceTypesXML.map(heatingSourceTypeXML => {
      let heatingSourceType = this.readHeatingDeviceType(heatingSourceTypeXML);
      heatingSourceTypesMap.push(heatingSourceType);

      if (this.readBoolean(heatingSourceTypeXML, "electricity")) {
        if (this.electricityHeatingSourceType == null) {
          heatingSourceType.efficiency = 90;
          this.electricityHeatingSourceType = heatingSourceType;
        } else {
        }
      }
      return false;
    });

    if (this.electricityHeatingSourceType == null) {
      throw new Error("Found no electricity heating source type");
    }
    return heatingSourceTypesMap;
  };

  readHeatingDeviceType = heatingSourceTypeXML => {
    let id = heatingSourceTypeXML.ID;
    let calorificValue = Number(heatingSourceTypeXML.calorific_value);
    let calorificValueUnit = heatingSourceTypeXML.calorific_value_unit;
    let calorificValueEditable =
      heatingSourceTypeXML.calorific_value_editable === "true";
    let emissionPerKWh = Number(heatingSourceTypeXML.emission_per_kwh);
    let finalToPrimaryCoefficient = Number(
      heatingSourceTypeXML.final_to_primary_coefficient
    );
    let naturalUnit = NaturalUnit.getNaturalUnitByCode(
      heatingSourceTypeXML.natural_unit
    );
    let naturalUnitPrice = Number(heatingSourceTypeXML.natural_unit_price);
    let showNaturalUnit = heatingSourceTypeXML.show_natural_unit === "true";

    let heatingSourceType = new HeatingSourceType(
      id,
      naturalUnit,
      naturalUnitPrice,
      calorificValue,
      calorificValueUnit,
      emissionPerKWh,
      finalToPrimaryCoefficient,
      showNaturalUnit,
      calorificValueEditable
    );
    this.fillBaseProperties(heatingSourceTypeXML, heatingSourceType);

    let heatingType = HeatingType.of(heatingSourceTypeXML.type);

    if (heatingType == null) {
      throw new Error(
        "Unrecognized heating source type: " +
          heatingSourceTypeXML.type +
          ". Allowable values are: pellets|wood|coal|gas|oil|combined_heat|electric"
      );
    }
    heatingSourceType.heatingType = heatingType;

    return heatingSourceType;
  };
}

import { DictionaryReader } from "./DictionaryReader";

export class AbstractDictionaryReader extends DictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    throw new Error();
  };

  fillBaseProperties = (dictionaryTypeXML, baseComboObject) => {
    baseComboObject.id = dictionaryTypeXML.id;
    if (this.attributeNotEmpty(dictionaryTypeXML, "label_key")) {
      baseComboObject.labelKey = dictionaryTypeXML.label_key;
      baseComboObject.name = baseComboObject.labelKey;
    } else if (
      this.attributeNotEmpty(dictionaryTypeXML, "en_name") &&
      this.attributeNotEmpty(dictionaryTypeXML, "pl_name")
    ) {
      baseComboObject.enName = dictionaryTypeXML.en_name;
      baseComboObject.plName = dictionaryTypeXML.pl_name;
    }
    if (this.attributeNotEmpty(dictionaryTypeXML, "price")) {
      baseComboObject.price = dictionaryTypeXML.price;
      baseComboObject.priceUnit = dictionaryTypeXML.price_unit;
      baseComboObject.work = dictionaryTypeXML.work;
      baseComboObject.workUnit = dictionaryTypeXML.work_unit;
      baseComboObject.obsolescence = dictionaryTypeXML.obsolescence;
    }
    return baseComboObject;
  };

  readNumber = (xml, attrName) => {
    let attrValue = xml[attrName];
    return attrValue != null && attrValue !== ""
      ? Number(attrValue)
      : Number.NaN;
  };

  readBoolean = (xml, attrName) => {
    let attrValue = xml[attrName];
    return attrValue != null && attrValue === "true";
  };

  toDictionary = dictionaryItems => {
    let dictionary = [];
    for (let i = 0; i < dictionaryItems.length; i++) {
      let dictionaryItem = dictionaryItems[i];
      dictionary[dictionaryItem.id] = dictionaryItem;
    }
    return dictionary;
  };

  getSelectedIndex = xmlItem => {
    return Number(xmlItem.selected_index);
  };

  attributeNotEmpty = (xml, attrName) => {
    let attrValue = xml[attrName];
    return attrValue != null && attrValue !== "";
  };
}

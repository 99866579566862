import { RatioCluster } from "./RatioCluster";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

export class RatioClusterFactory {
  static fromWattsToKwhRatio = () => {
    return (24 * 30) / 1000;
  };

  static getMonthlyWattsRatioCluster = conversionData => {
    let ratioCluster = new RatioCluster();
    ratioCluster.toWattsRatio = 1;
    ratioCluster.tokWhRatio = this.fromWattsToKwhRatio();
    ratioCluster.toCostRatio =
      ratioCluster.tokWhRatio * conversionData.pricePerKWh;
    ratioCluster.toGJRatio = (24 * 3600 * 30) / 1000000000;

    ratioCluster.toEmissionRatio =
      ratioCluster.tokWhRatio * conversionData.emissionCoefficient;
    ratioCluster.toWarmWaterEmissionRatio =
      ratioCluster.tokWhRatio * conversionData.waterEmissionCoefficient;

    return ratioCluster;
  };

  static convertToUnit = (value, unit, pricePerKWh, emissionCoefficient) => {
    if (ConversionUnits.isKiloWattsUnit(unit)) {
      return value * this.fromWattsToKwhRatio();
    } else if (ConversionUnits.isCostUnit(unit)) {
      return value * this.fromWattsToKwhRatio() * pricePerKWh;
    } else if (ConversionUnits.isGJUnit(unit)) {
      return (value * (24 * 3600 * 30)) / 1000000000;
    } else if (ConversionUnits.isEmisionUnit(unit)) {
      return value * this.fromWattsToKwhRatio() * emissionCoefficient;
    }
  };

  static getMonthlyKiloWattsHourRatioCluster = (
    conversionData,
    water = false
  ) => {
    let ratioCluster = new RatioCluster();
    let price = conversionData.pricePerKWh;
    if (water) {
      conversionData.pricePerKWh = conversionData.waterPricePerKWh;
    }
    ratioCluster.toWattsRatio = 1000 / (24 * 30);
    ratioCluster.tokWhRatio = 1;
    ratioCluster.toCostRatio = conversionData.pricePerKWh;
    ratioCluster.toGJRatio = 0.036;

    ratioCluster.toEmissionRatio =
      ratioCluster.tokWhRatio * conversionData.emissionCoefficient;
    ratioCluster.toWarmWaterEmissionRatio =
      ratioCluster.tokWhRatio * conversionData.waterEmissionCoefficient;
    conversionData.pricePerKWh = price;
    return ratioCluster;
  };

  static getMonthlyGJRatioCluster = (conversionData, water = false) => {
    let ratioCluster = new RatioCluster();
    let price = conversionData.pricePerKWh;
    if (water) {
      conversionData.pricePerKWh = conversionData.waterPricePerKWh;
    }
    ratioCluster.toWattsRatio = ((1 / 0.0036) * 1000) / (24 * 30);
    ratioCluster.tokWhRatio = 1 / 0.0036;
    ratioCluster.toCostRatio =
      ratioCluster.tokWhRatio * conversionData.pricePerKWh;
    ratioCluster.toGJRatio = 1;

    ratioCluster.toEmissionRatio =
      ratioCluster.tokWhRatio * conversionData.emissionCoefficient;
    ratioCluster.toWarmWaterEmissionRatio =
      ratioCluster.tokWhRatio * conversionData.waterEmissionCoefficient;
    conversionData.pricePerKWh = price;
    return ratioCluster;
  };

  static getMonthlyCostRatioCluster = (conversionData, water = false) => {
    let ratioCluster = new RatioCluster();
    let price = conversionData.pricePerKWh;
    if (water) {
      conversionData.pricePerKWh = conversionData.waterPricePerKWh;
    }
    ratioCluster.toWattsRatio =
      (1000 / (24 * 30)) * (1 / conversionData.pricePerKWh);
    ratioCluster.tokWhRatio = 1 / conversionData.pricePerKWh;
    ratioCluster.toCostRatio = 1;
    ratioCluster.toGJRatio = (1 / conversionData.pricePerKWh) * 0.036;

    ratioCluster.toEmissionRatio =
      ratioCluster.tokWhRatio * conversionData.emissionCoefficient;
    ratioCluster.toWarmWaterEmissionRatio =
      ratioCluster.tokWhRatio * conversionData.waterEmissionCoefficient;
    conversionData.pricePerKWh = price;
    return ratioCluster;
  };

  static getEmissionRatioCluster = (conversionData, water = false) => {
    let ratioCluster = new RatioCluster();
    ratioCluster.tokWhRatio =
      1 /
      (water
        ? conversionData.waterEmissionCoefficient
        : conversionData.emissionCoefficient);
    ratioCluster.toWattsRatio = (1000 / (24 * 30)) * ratioCluster.tokWhRatio;
    ratioCluster.toCostRatio =
      ratioCluster.tokWhRatio * conversionData.pricePerKWh;
    ratioCluster.toGJRatio = ratioCluster.tokWhRatio * 0.036;

    ratioCluster.toEmissionRatio = 1;
    ratioCluster.toWarmWaterEmissionRatio = 1;

    return ratioCluster;
  };

  static getRatioCluster = (conversionData, water = false) => {
    let ratioCluster = new RatioCluster();

    let unit = conversionData.selectedUnit;

    if (unit === ConversionUnits.ENERGY_WATTS) {
      ratioCluster = this.getMonthlyWattsRatioCluster(conversionData);
    } else if (unit === ConversionUnits.ENERGY_GJ_PER_YEAR) {
      ratioCluster = this.getMonthlyGJRatioCluster(conversionData, water);
    } else if (unit === ConversionUnits.EMISSIONS_PER_YEAR) {
      ratioCluster = this.getEmissionRatioCluster(conversionData, water);
    } else if (
      unit === ConversionUnits.ENERGY_KWH_PER_MONTH ||
      unit === ConversionUnits.ENERGY_KWH_PER_YEAR
    ) {
      ratioCluster = this.getMonthlyKiloWattsHourRatioCluster(
        conversionData,
        water
      );
    } else if (ConversionUnits.isCostUnit(unit)) {
      ratioCluster = this.getMonthlyCostRatioCluster(conversionData, water);
    }

    return ratioCluster;
  };
}

import { ConvertedResult } from "./ConvertedResult";
import { ConverterHelper } from "./ConverterHelper";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";
import { BalanceValueHelper } from "../calc/utils/BalanceValueHelper";

export class ConverterService {
  electricityHeatingSourceType;

  configurationRead = businessConfiguration => {
    this.electricityHeatingSourceType =
      businessConfiguration.dictionaryConfig.heatingDeviceTypeConfiguration.electricityHeatingSourceType;
  };

  convert = (processResult, conversionData, ratioCluster) => {
    let monthlyConvertedDataArray = [];
    let aggregatedResult = new ConvertedResult();
    const dataContext = processResult.dataContext;
    let monthlyEnergyConsumption =
      processResult.wattsEnergyResult.monthlyEnergyConsumption;
    let ratio = 1;
    for (let month = 0; month < monthlyEnergyConsumption.length; month++) {
      try {
        let monthEnergyData = monthlyEnergyConsumption[month];

        let monthlyConvertedResult = this.convertMonthEnergyDataForRatio(
          monthEnergyData,
          dataContext,
          ratio,
          conversionData,
          ratioCluster,
          month
        );
        let absoluteConvertedResult = ConverterHelper.buildWithAbsoluteValues(
          monthlyConvertedResult
        );

        aggregatedResult.enBasement += absoluteConvertedResult.enBasement;
        aggregatedResult.enWalls += absoluteConvertedResult.enWalls;
        aggregatedResult.enDoor += absoluteConvertedResult.enDoor;
        aggregatedResult.enFloor += absoluteConvertedResult.enFloor;
        aggregatedResult.enRoof += absoluteConvertedResult.enRoof;
        aggregatedResult.enFoundations += absoluteConvertedResult.enFoundations;
        aggregatedResult.enWindows += absoluteConvertedResult.enWindows;
        aggregatedResult.enVent += absoluteConvertedResult.enVent;
        aggregatedResult.enRecuperator += absoluteConvertedResult.enRecuperator;
        aggregatedResult.enHumidification +=
          absoluteConvertedResult.enHumidification;
        aggregatedResult.enAir += absoluteConvertedResult.enAir;
        aggregatedResult.enTightness += absoluteConvertedResult.enTightness;
        aggregatedResult.enWarmWater += absoluteConvertedResult.enWarmWater;

        aggregatedResult.enSolGain += absoluteConvertedResult.enSolGain;
        aggregatedResult.enPersonGain += absoluteConvertedResult.enPersonGain;
        aggregatedResult.enLivingNeeds += absoluteConvertedResult.enLivingNeeds;
        aggregatedResult.enElectricityGain +=
          absoluteConvertedResult.enElectricityGain;
        aggregatedResult.enWarmWaterGain +=
          absoluteConvertedResult.enWarmWaterGain;
        aggregatedResult.enCollectorSolarGain +=
          absoluteConvertedResult.enCollectorSolarGain;
        aggregatedResult.enPhotovoltaicGain +=
          absoluteConvertedResult.enPhotovoltaicGain;
        aggregatedResult.enPhotovoltaicGainNormalized +=
          monthlyConvertedResult.enPhotovoltaicGainNormalized;
        aggregatedResult.heatingSourceLoss +=
          monthlyConvertedResult.heatingSourceLoss;
        aggregatedResult.airConditioningLoss +=
          monthlyConvertedResult.airConditioningLoss;
        aggregatedResult.warmWaterHeatingSourceLoss +=
          monthlyConvertedResult.warmWaterHeatingSourceLoss;
        aggregatedResult.heatingDeviceEnergy +=
          monthlyConvertedResult.heatingDeviceEnergy;
        aggregatedResult.heatingWaterDeviceEnergy +=
          monthlyConvertedResult.heatingWaterDeviceEnergy;
        aggregatedResult.airConditioningDeviceEnergy +=
          absoluteConvertedResult.airConditioningDeviceEnergy;
        aggregatedResult.enElectricDevicesEnergyLoss +=
          monthlyConvertedResult.enElectricDevicesEnergyLoss;
        aggregatedResult.enElectricDevicesEnergyLossNormalized +=
          monthlyConvertedResult.enElectricDevicesEnergyLossNormalized;
        aggregatedResult.enElectricVentilationEnergyLoss +=
          monthlyConvertedResult.enElectricVentilationEnergyLoss;
        aggregatedResult.enWaterElectricDevicesEnergyLoss +=
          monthlyConvertedResult.enWaterElectricDevicesEnergyLoss;
        aggregatedResult.electricDevicesEnergySum +=
          monthlyConvertedResult.electricDevicesEnergySum;

        aggregatedResult.enWarmWaterNormalized +=
          monthlyConvertedResult.enWarmWaterNormalized;
        aggregatedResult.enWarmWaterNormalizedHeatingSourceLoss +=
          monthlyConvertedResult.enWarmWaterNormalizedHeatingSourceLoss;

        monthlyConvertedDataArray.push(monthlyConvertedResult);
      } catch (e) {
        console.log(e);
      }
    }

    processResult.monthlyConvertedData = monthlyConvertedDataArray;

    aggregatedResult.enSolGainAggregated = BalanceValueHelper.calcBalanceEnSolGain(
      processResult
    );
    aggregatedResult.enHeatingProductAggregated = BalanceValueHelper.calcBalanceEnByProductHeat(
      processResult
    );

    processResult.yearlyAggregatedConvertedData = aggregatedResult;

    return processResult;
  };

  convertMonthEnergyDataForRatio = (
    energyData,
    dataContext,
    ratio,
    conversionData
  ) => {
    let converterResult = new ConvertedResult();
    converterResult.enWalls = energyData.enWalls * ratio;
    converterResult.enDoor = energyData.enDoor * ratio;
    converterResult.enBasement = energyData.enBasement * ratio;

    converterResult.enFloor = energyData.enFloor * ratio;
    converterResult.enRoof = energyData.enRoof * ratio;
    converterResult.enFoundations = energyData.enFoundations * ratio;
    converterResult.enWindows = energyData.enWindows * ratio;
    converterResult.enVent = energyData.enVent * ratio;
    converterResult.enAir = energyData.enAir * ratio;
    converterResult.enTightness = energyData.enTightness * ratio;

    converterResult.enRecuperator = energyData.enRecuperator * ratio;

    converterResult.enHumidification = energyData.enHumidification * ratio;

    converterResult.enWarmWater = energyData.enWarmWater * ratio;

    converterResult.enSolGain = energyData.enSolGain * ratio;
    converterResult.enPersonGain = energyData.enPersonGain * ratio;
    converterResult.enElectricityGain = energyData.enElectricityGain * ratio;

    converterResult.enWarmWaterGain = energyData.enWarmWaterGain * ratio;

    converterResult.enCollectorSolarGain =
      energyData.enCollectorSolarGain * ratio;

    converterResult.enWarmWaterNormalized =
      energyData.enWarmWaterNormalized * ratio;
    const heatingBalance = Math.max(0, converterResult.getHeatingBalance());
    const acBalance = converterResult.getACBalance();

    const warmWaterBalance = Math.max(0, converterResult.getWarmWaterBalance());
    const warmWaterNormalizedBalance = Math.max(
      0,
      converterResult.getWarmWaterNormalizedBalance()
    );
    let nominalEff = conversionData.houseHeatingEfficiency;
    let nominalWaterEff = conversionData.warmWaterHeatingEfficiency;
    if (nominalEff > 10) {
      nominalEff = nominalEff / 100;
    }
    if (nominalWaterEff > 10) {
      nominalWaterEff = nominalWaterEff / 100;
    }
    if (typeof conversionData.heatingSource !== "undefined") {
      if (
        conversionData.heatingSource ===
        "hm.dropdown.heating.source.solid_fuel_boiler"
      ) {
        nominalEff = conversionData.houseHeatingEfficiency;
        if (conversionData.includeWarmWater) {
          if (
            conversionData.waterHeatingSource ===
            "hm.dropdown.heating.source.the_same"
          ) {
            if (
              conversionData.warmWaterStorageId ===
              "hm.water-storage.heat_node_without_storage"
            ) {
              nominalWaterEff = conversionData.warmWaterHeatingEfficiency;
            }
          }
        }
      }
    }
    converterResult.heatingDeviceEnergy = this.calcDeviceEnergy(
      heatingBalance,
      nominalEff
    );
    converterResult.heatingWaterDeviceEnergy = this.calcDeviceEnergy(
      warmWaterBalance,
      nominalWaterEff
    );
    converterResult.enPhotovoltaicGain = energyData.enPhotovoltaicGain * ratio;
    converterResult.enPhotovoltaicGainNormalized =
      energyData.enPhotovoltaicGainNormalized * ratio;

    converterResult.heatingSourceLoss = this.calcHeatingSourceLoss(
      heatingBalance,
      nominalEff
    );
    converterResult.enWaterElectricDevicesEnergyLoss =
      energyData.enWaterElectricDevicesEnergyLoss * ratio;
    converterResult.enElectricDevicesEnergyLossNormalized =
      energyData.enElectricDevicesEnergyLossNormalized * ratio;

    converterResult.enElectricVentilationEnergyLoss =
      energyData.enElectricVentilationEnergyLoss * ratio;

    converterResult.enLivingNeeds = energyData.enLivingNeeds * ratio;
    converterResult.airConditioningLoss = 0;

    if (
      typeof conversionData.airConditioning !== "undefined" &&
      conversionData.airConditioning
    ) {
      const heatingSourceLoss = this.calcHeatingSourceLoss(acBalance, 3.5);
      const heatingDevice = this.calcDeviceEnergy(acBalance, 3.5);

      converterResult.airConditioningLoss = heatingSourceLoss;
      converterResult.airConditioningDeviceEnergy =
        heatingDevice + heatingSourceLoss;
    }

    let electricDevicesEnergy = 0;

    electricDevicesEnergy += converterResult.enWaterElectricDevicesEnergyLoss;
    electricDevicesEnergy +=
      converterResult.enElectricDevicesEnergyLossNormalized;
    electricDevicesEnergy += converterResult.enElectricVentilationEnergyLoss;
    electricDevicesEnergy += converterResult.enRecuperator;
    electricDevicesEnergy += converterResult.enHumidification;
    if (conversionData.airConditioning) {
      electricDevicesEnergy += converterResult.airConditioningDeviceEnergy;
    }
    if (conversionData.isElectric) {
      electricDevicesEnergy += converterResult.heatingDeviceEnergy;
      electricDevicesEnergy += converterResult.heatingWaterDeviceEnergy;
    }

    converterResult.electricDevicesEnergySum = electricDevicesEnergy;

    converterResult.warmWaterHeatingSourceLoss = this.calcHeatingSourceLoss(
      warmWaterBalance,
      nominalWaterEff
    );
    converterResult.enWarmWaterNormalizedHeatingSourceLoss = this.calcHeatingSourceLoss(
      warmWaterNormalizedBalance,
      nominalWaterEff
    );
    return converterResult;
  };

  calcHeatingSourceLoss = (value, efficiency) => {
    return value / efficiency - value;
  };

  calcDeviceEnergy = (value, efficiency) => {
    return value / efficiency;
  };

  convertElectricDeviceLoss = (energyData, conversionData, ratioCluster) => {
    let result = energyData;
    if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      result =
        result *
        ratioCluster.tokWhRatio *
        (conversionData.isElectric
          ? conversionData.pricePerKWh
          : conversionData.electricityPricePerKWh);
    } else if (ConversionUnits.isEmisionUnit(conversionData.selectedUnit)) {
      result = result * ratioCluster.toEmissionRatio;
    } else {
      result =
        result *
        ConversionUnits.getRatio(conversionData.selectedUnit, ratioCluster);
    }
    return result;
  };

  convertEnRecuperator = (energyData, conversionData, ratioCluster) => {
    let result = energyData.enRecuperator;
    if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      result =
        result *
        ratioCluster.tokWhRatio *
        conversionData.electricityPricePerKWh;
    } else if (ConversionUnits.isEmisionUnit(conversionData.selectedUnit)) {
      result =
        result *
        ratioCluster.tokWhRatio *
        this.electricityHeatingSourceType.emissionPerKWh;
    } else {
      let ratio = ConversionUnits.getRatio(
        conversionData.selectedUnit,
        ratioCluster
      );
      result = result * ratio;
    }
    return result;
  };

  convertEnHumidification = (energyData, conversionData, ratioCluster) => {
    let result = energyData.enHumidification;
    if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      result =
        result *
        ratioCluster.tokWhRatio *
        conversionData.electricityPricePerKWh;
    } else if (ConversionUnits.isEmisionUnit(conversionData.selectedUnit)) {
      result =
        result *
        ratioCluster.tokWhRatio *
        this.electricityHeatingSourceType.emissionPerKWh;
    } else {
      let ratio = ConversionUnits.getRatio(
        conversionData.selectedUnit,
        ratioCluster
      );
      result = result * ratio;
    }
    return result;
  };

  convertWarmWaterLoss = (enWarmWater, conversionData, ratioCluster) => {
    let result = enWarmWater;

    if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      result =
        enWarmWater * ratioCluster.tokWhRatio * conversionData.waterPricePerKWh;
    } else if (ConversionUnits.isEmisionUnit(conversionData.selectedUnit)) {
      result = enWarmWater * ratioCluster.toWarmWaterEmissionRatio;
    } else {
      let ratio = ConversionUnits.getRatio(
        conversionData.selectedUnit,
        ratioCluster
      );
      result = result * ratio;
    }
    return result;
  };

  convertPhotovoltaicGain = (energyData, conversionData, ratioCluster) => {
    let result = energyData;
    if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      result =
        result *
        ratioCluster.tokWhRatio *
        (conversionData.isElectric
          ? conversionData.pricePerKWh
          : conversionData.electricityPricePerKWh);
    } else if (ConversionUnits.isEmisionUnit(conversionData.selectedUnit)) {
      result = result * ratioCluster.toEmissionRatio;
    } else {
      result =
        result *
        ConversionUnits.getRatio(conversionData.selectedUnit, ratioCluster);
    }
    return result;
  };

  convertEnergykWh = (energyData, conversionData, ratioCluster) => {
    let result = energyData;
    if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      result = result * ratioCluster.tokWhRatio * conversionData.pricePerKWh;
    } else if (ConversionUnits.isEmisionUnit(conversionData.selectedUnit)) {
      result = result * ratioCluster.toEmissionRatio;
    } else {
      result =
        result *
        ConversionUnits.getRatio(conversionData.selectedUnit, ratioCluster);
    }
    return result;
  };

  convertWarmWaterGain = (energyData, conversionData, ratioCluster) => {
    let result = energyData.enWarmWaterGain;

    if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      result = result * ratioCluster.toCostRatio;
    } else if (ConversionUnits.isEmisionUnit(conversionData.selectedUnit)) {
      result = result * ratioCluster.toEmissionRatio;
    } else {
      result =
        result *
        ConversionUnits.getRatio(conversionData.selectedUnit, ratioCluster);
    }
    return result;
  };

  convertSolarCollectorGain = (energyData, conversionData, ratioCluster) => {
    let result = Math.min(
      energyData.enWarmWater,
      energyData.enCollectorSolarGain
    );
    if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      result =
        result * ratioCluster.tokWhRatio * conversionData.waterPricePerKWh;
    } else if (ConversionUnits.isEmisionUnit(conversionData.selectedUnit)) {
      result = result * ratioCluster.toWarmWaterEmissionRatio;
    } else {
      let ratio = ConversionUnits.getRatio(
        conversionData.selectedUnit,
        ratioCluster
      );
      result = ratio * result;
    }
    return result;
  };

  chooseRatio = (conversionData, ratioCluster) => {
    let ratio = 1;

    if (conversionData.selectedUnit === ConversionUnits.ENERGY_WATTS) {
      ratio = ratioCluster.toWattsRatio;
    } else if (ConversionUnits.isKiloWattsUnit(conversionData.selectedUnit)) {
      ratio = ratioCluster.tokWhRatio;
    } else if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      ratio = ratioCluster.toCostRatio;
    } else if (ConversionUnits.isGJUnit(conversionData.selectedUnit)) {
      ratio = ratioCluster.toGJRatio;
    } else if (ConversionUnits.isEmisionUnit(conversionData.selectedUnit)) {
      ratio = ratioCluster.toEmissionRatio;
    }

    return ratio;
  };
}

import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";

export class PersonEnergyGainCalculator extends IMonthEnergyCalculator {
  calcEnergy(energyData, contextData, monthInputData) {
    let enWarmWater = 0;
    let warmWaterData = contextData.houseData.warmWaterData;

    let enPersonGain = this.calcPersonEnGain(
      contextData.houseData.personNumber
    );
    if (contextData.includeWarmWater) {
      enWarmWater = EnergyCalcHelper.calcWarmWaterEnergyLoss(
        contextData.houseData.personNumber
      );
    }
    let enWarmWaterGain =
      0.4 *
      enWarmWater *
      (1 /
        (warmWaterData.distributorEfficiency * warmWaterData.storeEfficiency) -
        1);

    energyData.enPersonGain = enPersonGain;
    energyData.enWarmWaterGain = enWarmWaterGain;

    return energyData;
  }

  calcPersonEnGain = personNumber => {
    let enGain = 5.3 * (80 * personNumber + 275);
    enGain = EnergyCalcHelper.convertkWhPerYearToWatts(enGain);
    return enGain;
  };
}

import { RatioClusterFactory } from "./converter/RatioClusterFactory";
import { ReportDataCalculator } from "./calc/ReportDataCalculator";
import { HeatMasterWattsCalculator } from "./calc/HeatMasterWattsCalculator";
import { ConverterService } from "./converter/ConverterService";
import { GlobalValues } from "../constants/GlobalValues";
import { ProcessingResult } from "./ProcessingResult";
import { ComparingProcessingResult } from "./state/ComparingProcessingResult";
import { OptimizerEnergyCalculator } from "./optimizer/OptimizerEnergyCalculator";

export class HeatMasterController {
  wattsEnergyCalculator = new HeatMasterWattsCalculator();
  reportDataCalculator = new ReportDataCalculator();
  converterService = new ConverterService();
  optimizerEnergyCalculator = new OptimizerEnergyCalculator();

  businessConfigurationListeners = [];
  dataContextListeners = [];
  resultDataListeners = [];

  dataContext;
  processResult;

  selectedMonth = GlobalValues.START_MONTH;

  started = false;

  addBusinessConfigurationListener = listener =>
    this.dataContextListeners.push(listener);
  addDataContextListener = dataContextListener =>
    this.businessConfigurationListeners.push(dataContextListener);

  addResultDataListener = resultDataListener =>
    this.resultDataListeners.push(resultDataListener);

  propagateBusinessConfiguration = businessConfig => {
    for (let i = 0; i < this.businessConfigurationListeners.length; i++) {
      let businessConfigListener = this.businessConfigurationListeners[i];
      businessConfigListener.configurationRead(businessConfig);
    }
    this.reportDataCalculator.configurationRead(businessConfig);
    this.converterService.configurationRead(businessConfig);
  };

  getDataContext = () => this.dataContext;

  getProcessResult = () => this.processResult;

  resetDataContext = dataContext => {
    this.selectedMonth = dataContext.selectedMonth;
    this.dataContext = dataContext;
  };

  startCalculationWithConfig = dataContext => {
    this.dataContext = dataContext;
    this.doStartCalculation();
  };

  initData = dataContext => {
    for (let i = 0; i < this.dataContextListeners.length; i++) {
      let dataContextListener = this.dataContextListeners[i];
      dataContext = dataContextListener.initData(dataContext);
    }
    return dataContext;
  };

  calculateAndSetViewState = dataContext => {
    this.dataContext = dataContext;
    this.selectedMonth = 0;

    this.started = true;

    this.doCalculationAndPropagateResult();
  };

  calculateAndSetViewReferenceState = stateDataContext => {
    this.dataContext = stateDataContext.getCurrentDataContext();
    this.selectedMonth = 0;

    this.started = true;

    this.doCalculationAndPropagateResult();
  };

  calculate = () => {
    if (this.started) {
      this.doCalculationAndPropagateResult();
    }
  };

  calculateStateless = dataContext =>
    this.performActualCalculation(dataContext);

  calculateAndPropagateComparingResult = (refDataContext, newDataContext) => {
    let refProcessingResult = this.calculateStateless(refDataContext);
    let newProcessingResult = this.calculateStateless(newDataContext);
    return new ComparingProcessingResult(
      refProcessingResult,
      newProcessingResult
    );
  };

  propagateComparingResult = comparingResult => {
    try {
      for (let i = 0; i < this.resultDataListeners.length; i++) {
        let resultDataListener = this.resultDataListeners[i];
        resultDataListener.comparingResultCalculated(
          comparingResult,
          this.selectedMonth
        );
      }
    } catch (e) {}
  };

  monthChanged = month => {
    this.selectedMonth = month;
    if (this.started && this.processResult != null) {
    }
  };

  conversionDataChanged(conversionRequired) {
    if (this.started && this.processResult != null) {
      if (conversionRequired) {
        this.processResult = this.convertResult(
          this.processResult,
          this.dataContext.conversionData
        );
        this.processResult = this.reportDataCalculator.calcReportValues(
          this.dataContext,
          this.processResult,
          this.dataContext.conversionData
        );
      }
      try {
      } catch (e) {}
    }
  }

  convertResult = (processResult, conversionData) => {
    try {
      let ratioCluster = RatioClusterFactory.getMonthlyWattsRatioCluster(
        conversionData
      );

      processResult.conversionData = conversionData;
      return this.converterService.convert(
        processResult,
        conversionData,
        ratioCluster
      );
    } catch (e) {}
    return processResult;
  };

  doStartCalculation() {
    this.started = true;
    try {
      this.doCalculation();
    } catch (e) {}
  }

  setUpView = dataContext => {
    try {
      for (let i = 0; i < this.dataContextListeners.length; i++) {
        let dataContextListener = this.dataContextListeners[i];
        dataContextListener.setupView(dataContext);
      }
    } catch (e) {}
  };

  doCalculationAndPropagateResult = () => {
    this.doCalculation();
  };

  doCalculation() {
    try {
      this.processResult = this.performActualCalculation(this.dataContext);
    } catch (e) {}
  }

  performActualCalculation = dataContext => {
    let processingResult = new ProcessingResult();
    processingResult.dataContext = dataContext;
    processingResult.wattsEnergyResult = this.wattsEnergyCalculator.calcEnergyDataWithGaussianBlurring(
      dataContext
    );

    processingResult = this.convertResult(
      processingResult,
      dataContext.conversionData
    );
    const tOriginal = dataContext.houseData.tIn;
    dataContext.houseData.tIn = 20;
    processingResult.classAwareWattsEnergyResult = this.wattsEnergyCalculator.calcEnergyDataWithGaussianBlurring(
      dataContext
    );

    if (dataContext.houseData.acData.airconditioning) {
      let processingResultAC = new ProcessingResult();
      dataContext.houseData.tIn = dataContext.houseData.acData.temp;
      processingResultAC.dataContext = dataContext;
      processingResultAC.wattsEnergyResult = this.wattsEnergyCalculator.calcEnergyDataWithGaussianBlurring(
        dataContext
      );

      processingResultAC = this.convertResult(
        processingResultAC,
        dataContext.conversionData
      );

      processingResult.yearlyAggregatedConvertedData.airConditioningDeviceEnergy = Math.abs(
        processingResultAC.yearlyAggregatedConvertedData
          .airConditioningDeviceEnergy
      );
      processingResult.yearlyAggregatedConvertedData.airConditioningLoss = Math.abs(
        processingResultAC.yearlyAggregatedConvertedData.airConditioningLoss
      );

      for (let i = 0; i < 12; i++) {
        processingResult.monthlyConvertedData[
          i
        ].airConditioningDeviceEnergy = Math.abs(
          processingResultAC.monthlyConvertedData[i].airConditioningDeviceEnergy
        );
        processingResult.monthlyConvertedData[i].airConditioningLoss = Math.abs(
          processingResultAC.monthlyConvertedData[i].airConditioningLoss
        );
      }
    }

    dataContext.houseData.tIn = tOriginal;

    processingResult = this.reportDataCalculator.calcReportValues(
      dataContext,
      processingResult,
      dataContext.conversionData
    );
    return processingResult;
  };

  performCalculationForOptimizer = dataContext => {
    let processingResult = new ProcessingResult();
    processingResult.dataContext = dataContext;
    processingResult.wattsEnergyResult = this.optimizerEnergyCalculator.calcEnergyDataWithGaussianBlurring(
      dataContext
    );
    processingResult = this.convertResult(
      processingResult,
      dataContext.conversionData
    );
    let tOriginal = dataContext.houseData.tIn;
    dataContext.houseData.tIn = 20;
    processingResult.classAwareWattsEnergyResult = this.wattsEnergyCalculator.calcEnergyDataWithGaussianBlurring(
      dataContext
    );

    if (dataContext.houseData.acData.airconditioning) {
      let processingResultAC = new ProcessingResult();
      dataContext.houseData.tIn = dataContext.houseData.acData.temp;
      processingResultAC.dataContext = dataContext;
      processingResultAC.wattsEnergyResult = this.wattsEnergyCalculator.calcEnergyDataWithGaussianBlurring(
        dataContext
      );

      processingResultAC = this.convertResult(
        processingResultAC,
        dataContext.conversionData
      );
      processingResult.yearlyAggregatedConvertedData.airConditioningLoss =
        processingResultAC.yearlyAggregatedConvertedData.airConditioningLoss;
      processingResult.yearlyAggregatedConvertedData.airConditioningDeviceEnergy =
        processingResultAC.yearlyAggregatedConvertedData.airConditioningDeviceEnergy;
      for (let i = 0; i < 12; i++) {
        processingResult.monthlyConvertedData[i].airConditioningLoss =
          processingResultAC.monthlyConvertedData[i].airConditioningLoss;
        processingResult.monthlyConvertedData[i].airConditioningDeviceEnergy =
          processingResultAC.monthlyConvertedData[
            i
          ].airConditioningDeviceEnergy;
      }
    }
    dataContext.houseData.tIn = tOriginal;

    processingResult = this.reportDataCalculator.calcReportValues(
      dataContext,
      processingResult,
      dataContext.conversionData
    );

    return processingResult;
  };
}

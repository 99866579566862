export class ConversionData {
  _selectedUnit;
  _unitName;
  _shortUnitName;
  _pricePerKWh;
  _electricityPricePerKWh;
  _electricityPricePerKWhLocal;
  _electricityEmissionCoefficient;
  _isElectric;
  _isWaterElectric;
  _photovoltaicEnergy;
  _waterPricePerKWh;
  _pvPricePerKWh;
  _airConditioning;
  _houseHeatingEfficiency;
  _warmWaterHeatingEfficiency;
  _finalToPrimaryCoefficient;
  _warmWaterFinalToPrimaryCoefficient;
  _emissionCoefficient;
  _waterEmissionCoefficient;
  _toPLNCurrencyExchangeRate;
  _currencyLocaleCode;
  _currencyName;
  _naturalUnitPrice;
  _naturalUnitPriceLocal;

  _heatingSource;
  _waterHeatingSource;
  _includeWarmWater;
  _warmWaterStorageId;

  static buildConversionDataFromConversionData = (conversionData, unit) => {
    let result = this.buildConversionData(unit, conversionData.pricePerKWh);
    result.waterPricePerKWh = conversionData.waterPricePerKWh;
    result.houseHeatingEfficiency = conversionData.houseHeatingEfficiency;
    result.warmWaterHeatingEfficiency =
      conversionData.warmWaterHeatingEfficiency;
    result.electricityPricePerKWh = conversionData.electricityPricePerKWh;
    result.electricityPricePerKWhLocal =
      conversionData.electricityPricePerKWhLocal;
    result.isElectric = conversionData.isElectric;
    result.isWaterElectric = conversionData._isWaterElectric;
    result.pvPricePerKWh = conversionData.pvPricePerKWh;
    result.naturalUnitPrice = conversionData.naturalUnitPrice;
    result.naturalUnitPriceLocal = conversionData.naturalUnitPriceLocal;
    result.naturalUnitPriceChanged = conversionData.naturalUnitPriceChanged;
    return result;
  };

  static buildConversionData = (selectedUnit, pricePerKWh) => {
    let conversionData = new ConversionData();
    conversionData._selectedUnit = selectedUnit;
    conversionData._pricePerKWh = pricePerKWh;
    return conversionData;
  };

  static buildConversionDataWithEmissionCoefficient = (
    selectedUnit,
    pricePerKWh,
    emissionCoefficient
  ) => {
    let conversionData = new ConversionData();
    conversionData._selectedUnit = selectedUnit;
    conversionData._pricePerKWh = pricePerKWh;
    conversionData._emissionCoefficient = emissionCoefficient;
    conversionData._waterEmissionCoefficient = emissionCoefficient;
    return conversionData;
  };

  _toString = () =>
    "SelectedUnit: " +
    this._selectedUnit +
    " pricekWh " +
    this._pricePerKWh +
    " _unitName " +
    this._unitName +
    " " +
    " waterPricePerKWh: " +
    this._waterPricePerKWh +
    " houseHeatingEfficiency: " +
    this._houseHeatingEfficiency +
    " warmWaterEfficiency: " +
    this._warmWaterHeatingEfficiency +
    " ";

  get selectedUnit() {
    return this._selectedUnit;
  }

  set selectedUnit(value) {
    this._selectedUnit = value;
  }

  get unitName() {
    return this._unitName;
  }

  set unitName(value) {
    this._unitName = value;
  }

  get shortUnitName() {
    return this._shortUnitName;
  }

  set shortUnitName(value) {
    this._shortUnitName = value;
  }

  get pricePerKWh() {
    return this._pricePerKWh;
  }

  set pricePerKWh(value) {
    this._pricePerKWh = value;
  }

  get electricityPricePerKWh() {
    return this._electricityPricePerKWh;
  }

  set electricityPricePerKWh(value) {
    this._electricityPricePerKWh = value;
  }

  get electricityPricePerKWhLocal() {
    return this._electricityPricePerKWhLocal;
  }

  set electricityPricePerKWhLocal(value) {
    this._electricityPricePerKWhLocal = value;
  }

  get electricityEmissionCoefficient() {
    return this._electricityEmissionCoefficient;
  }

  set electricityEmissionCoefficient(value) {
    this._electricityEmissionCoefficient = value;
  }

  get isElectric() {
    return this._isElectric;
  }

  set isElectric(value) {
    this._isElectric = value;
  }

  get isWaterElectric() {
    return this._isWaterElectric;
  }

  set isWaterElectric(value) {
    this._isWaterElectric = value;
  }

  get waterPricePerKWh() {
    return this._waterPricePerKWh;
  }

  set waterPricePerKWh(value) {
    this._waterPricePerKWh = value;
  }

  get houseHeatingEfficiency() {
    return this._houseHeatingEfficiency;
  }

  set houseHeatingEfficiency(value) {
    this._houseHeatingEfficiency = value;
  }

  get warmWaterHeatingEfficiency() {
    return this._warmWaterHeatingEfficiency;
  }

  get pvPricePerKWh() {
    return this._pvPricePerKWh;
  }

  set pvPricePerKWh(value) {
    this._pvPricePerKWh = value;
  }

  set warmWaterHeatingEfficiency(value) {
    this._warmWaterHeatingEfficiency = value;
  }

  get finalToPrimaryCoefficient() {
    return this._finalToPrimaryCoefficient;
  }

  set finalToPrimaryCoefficient(value) {
    this._finalToPrimaryCoefficient = value;
  }

  get warmWaterFinalToPrimaryCoefficient() {
    return this._warmWaterFinalToPrimaryCoefficient;
  }

  set warmWaterFinalToPrimaryCoefficient(value) {
    this._warmWaterFinalToPrimaryCoefficient = value;
  }

  get emissionCoefficient() {
    return this._emissionCoefficient;
  }

  set emissionCoefficient(value) {
    this._emissionCoefficient = value;
  }

  get waterEmissionCoefficient() {
    return this._waterEmissionCoefficient;
  }

  set waterEmissionCoefficient(value) {
    this._waterEmissionCoefficient = value;
  }

  get toPLNCurrencyExchangeRate() {
    return this._toPLNCurrencyExchangeRate;
  }

  set toPLNCurrencyExchangeRate(value) {
    this._toPLNCurrencyExchangeRate = value;
  }

  get buildConversionData() {
    return this._buildConversionData;
  }

  set buildConversionData(value) {
    this._buildConversionData = value;
  }

  get buildConversionDataWithEmissionCoefficient() {
    return this._buildConversionDataWithEmissionCoefficient;
  }

  set buildConversionDataWithEmissionCoefficient(value) {
    this._buildConversionDataWithEmissionCoefficient = value;
  }

  get toString() {
    return this._toString;
  }

  set toString(value) {
    this._toString = value;
  }

  get currencyLocaleCode() {
    return this._currencyLocaleCode;
  }

  set currencyLocaleCode(value) {
    this._currencyLocaleCode = value;
  }

  get currencyName() {
    return this._currencyName;
  }

  set currencyName(value) {
    this._currencyName = value;
  }

  get photovoltaicEnergy() {
    return this._photovoltaicEnergy;
  }

  set photovoltaicEnergy(value) {
    this._photovoltaicEnergy = value;
  }

  get heatingSource() {
    return this._heatingSource;
  }

  set heatingSource(value) {
    this._heatingSource = value;
  }

  get waterHeatingSource() {
    return this._waterHeatingSource;
  }

  set waterHeatingSource(value) {
    this._waterHeatingSource = value;
  }

  get includeWarmWater() {
    return this._includeWarmWater;
  }

  set includeWarmWater(value) {
    this._includeWarmWater = value;
  }

  get warmWaterStorageId() {
    return this._warmWaterStorageId;
  }

  set warmWaterStorageId(value) {
    this._warmWaterStorageId = value;
  }

  get airConditioning() {
    return this._airConditioning;
  }

  set airConditioning(value) {
    this._airConditioning = value;
  }

  get naturalUnitPrice() {
    return this._naturalUnitPrice;
  }

  set naturalUnitPrice(value) {
    this._naturalUnitPrice = value;
  }

  get naturalUnitPriceLocal() {
    return this._naturalUnitPriceLocal;
  }

  set naturalUnitPriceLocal(value) {
    this._naturalUnitPriceLocal = value;
  }

  get naturalUnitPriceChanged() {
    return this._naturalUnitPriceChanged;
  }

  set naturalUnitPriceChanged(value) {
    this._naturalUnitPriceChanged = value;
  }
}

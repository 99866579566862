export class StationsWrapper {
  countryStations = [];

  constructor(stationsConfig) {
    this.fill(stationsConfig);
  }

  getStationsForCountry = countryCode => {
    return this.countryStations[countryCode];
  };

  fill = stationsConfigArray => {
    for (let i = 0; i < stationsConfigArray.length; i++) {
      let stationConfig = stationsConfigArray[i];
      this.countryStations[stationConfig.countryCode] = stationConfig;
    }
  };
}

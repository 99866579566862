import { StationItem } from "./StationItem";
import { StationsConfig } from "./StationsConfig";
import { TemperatureCalculator } from "../core/calc/utils/TemperatureCalculator";

export class StationConfigurationReader {
  stationsXML;
  resourceManager;

  stationsConfig;

  constructor(stationsXML, resourceManager) {
    this.stationsXML = stationsXML;
    this.resourceManager = resourceManager;
  }

  readConfig = () => {
    if (this.stationsConfig == null) {
      this.stationsConfig = this.doReadConfig();
    }
    return this.stationsConfig;
  };

  doReadConfig = () => {
    let result = [];
    for (let i = 0; i < this.stationsXML.stations_config.length; i++) {
      let stationConfigXML = this.stationsXML.stations_config[i].station_config;
      result.push(this.readStationConfig(stationConfigXML));
    }
    return result;
  };

  readStationConfig = stationConfigXML => {
    let stationConfig = new StationsConfig();
    stationConfig.countryCode = stationConfigXML.country;
    stationConfig.mapImage = stationConfigXML.map_image;
    let stations = [];
    for (let i = 0; i < stationConfigXML.stations.length; i++) {
      let stationXML = stationConfigXML.stations[i];
      let station = this.readStationItem(stationXML);
      stations.push(station);
      if (station.isDefaultStation()) {
        stationConfig.defaultStation = station;
      }
    }

    stationConfig.stations = stations;
    return stationConfig;
  };

  readStationItem = stationXML => {
    let stationItem = new StationItem();
    stationItem.localeCode = stationXML.name;
    stationItem.code = stationXML.name;
    stationItem.name = this.resourceManager[stationItem.localeCode];

    stationItem.mapX = stationXML.position.img_x;
    stationItem.mapY = stationXML.position.img_y;

    if (
      stationXML.default_station != null &&
      stationXML.default_station === "true"
    ) {
      stationItem.setDefaultStation(true);
    }

    let temperatures = new Array(12);
    let insolationS = new Array(12);
    let insolationWE = new Array(12);
    let insolationN = new Array(12);
    let optimalInsolation = new Array(12);

    for (let k = 0; k < 12; k++) {
      temperatures[k] = stationXML.temperatures[k] + 1.5;
      optimalInsolation[k] = stationXML.insolation.optimal[k];
      insolationS[k] = stationXML.insolation.south[k];
      insolationWE[k] = stationXML.insolation.west_east[k];
      insolationN[k] = stationXML.insolation.north[k];
    }

    stationItem.minCalcTemp = stationXML.calc_temp;
    stationItem.temperatures = temperatures;
    stationItem.groundTemperatures = TemperatureCalculator.calcGroundTemperatures(
      temperatures
    );
    stationItem.insolationN = insolationN;
    stationItem.insolationS = insolationS;
    stationItem.insolationWE = insolationWE;
    stationItem.optimalInsolation = optimalInsolation;

    return stationItem;
  };
}

import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";
import { Directions } from "../../../constants/Directions";

export class PhotovoltaicEnergyGainCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInputData) => {
    const SOLAR_TRACKER = 999;

    let ratio = 1 / 12;
    let angles = [];

    energyData.enPhotovoltaicGain = 0;
    let basicEnergy = 0;

    if (contextData.houseData.pvData.pv_type === 2) {
      if (
        contextData.houseData.roofData.direction === Directions.EAST ||
        contextData.houseData.roofData.direction === Directions.WEST
      ) {
        angles[10] = 852;
        angles[15] = 844;
        angles[20] = 835;
        angles[25] = 827;
        angles[30] = 809;
        angles[35] = 801;
        angles[40] = 789;
      } else if (
        contextData.houseData.roofData.direction === Directions.SOUTHWEST ||
        contextData.houseData.roofData.direction === Directions.SOUTHEAST
      ) {
        angles[10] = 905;
        angles[15] = 912;
        angles[20] = 920;
        angles[25] = 924;
        angles[30] = 925;
        angles[35] = 929;
        angles[40] = 921;
      } else if (
        contextData.houseData.roofData.direction === Directions.SOUTH
      ) {
        angles[10] = 921;
        angles[15] = 947;
        angles[20] = 955;
        angles[25] = 973;
        angles[30] = 981;
        angles[35] = 990;
        angles[40] = 980;
      }
      if (contextData.houseData.roofData.angle > 40) {
        contextData.houseData.roofData.angle = 40;
      }
      if (contextData.houseData.roofData.angle < 10) {
        contextData.houseData.roofData.angle = 10;
      }
      basicEnergy = angles[contextData.houseData.roofData.angle] * ratio;
      basicEnergy *= contextData.houseData.pvData.pv_power;
      basicEnergy = EnergyCalcHelper.convertkWhPerMonthToWatts(basicEnergy);
    } else if (contextData.houseData.pvData.pv_type === 3) {
      basicEnergy = SOLAR_TRACKER;
      basicEnergy *= ratio;
      basicEnergy *= contextData.houseData.pvData.pv_power;
      basicEnergy = EnergyCalcHelper.convertkWhPerMonthToWatts(basicEnergy);
    }
    basicEnergy = basicEnergy * 1.1;
    energyData.enPhotovoltaicGain = basicEnergy;
    energyData.enPhotovoltaicGainNormalized =
      (basicEnergy * contextData.houseData.pvData.autoconsumption) / 100 +
      ((basicEnergy * contextData.houseData.pvData.energy_network) / 100) *
        (1 - contextData.houseData.pvData.autoconsumption / 100);

    return energyData;
  };

  calcShadingRatio = shadingData => {
    let ratio = 1;
    for (let key in shadingData) {
      if (shadingData.hasOwnProperty(key)) {
        if (key === "buildings") {
          ratio = ratio * (1 - shadingData[key] / 100);
        } else if (key === "trees_coniferous") {
          ratio = ratio * (1 - shadingData[key] / 100);
        } else if (key === "trees_leafy") {
          ratio = ratio * (1 - shadingData[key] / 100);
        } else if (key === "trees_mixed") {
          ratio = ratio * (1 - shadingData[key] / 100);
        }
      }
    }
    return ratio;
  };
}

import {
  ConvertToUnit,
  fromKwhToWattsRatio,
} from "../components/Helpers/ConvertToUnit";
import { ConversionUnits } from "../constants/combo/ConversionUnits";

export function getConvertedCosts(dataResults, selectedUnit, pvCalc) {
  const { conversionData } = dataResults;
  const costInEuro = ConversionUnits.isEuroCostUnit(selectedUnit);
  const costUnit = ConversionUnits.isCostUnit(selectedUnit);

  const yearlyHeatingCost = ConvertToUnit(
    dataResults.yearlyHeatingCost,
    selectedUnit,
    conversionData.pricePerKWh,
    conversionData.emissionCoefficient
  );
  const yearlyWaterHeatingCost = ConvertToUnit(
    dataResults.yearlyWaterHeatingCost,
    selectedUnit,
    conversionData.waterPricePerKWh,
    conversionData.waterEmissionCoefficient
  );
  const yearlyElectricCost = ConvertToUnit(
    dataResults.yearlyElectricCost,
    selectedUnit,
    costUnit && costInEuro
      ? conversionData.electricityPricePerKWh
      : conversionData.electricityPricePerKWhLocal,
    conversionData.electricityEmissionCoefficient
  );
  const photovoltaicGain = ConvertToUnit(
    pvCalc.basicEnergy * fromKwhToWattsRatio(),
    selectedUnit,
    costUnit && costInEuro
      ? conversionData.electricityPricePerKWh
      : conversionData.electricityPricePerKWhLocal,
    conversionData.electricityEmissionCoefficient
  );
  const pvCost = Math.min(
    photovoltaicGain,
    (conversionData.isElectric ? yearlyHeatingCost : 0) +
      (conversionData.isWaterElectric ? yearlyWaterHeatingCost : 0) +
      yearlyElectricCost
  );
  const yearlyCost =
    yearlyHeatingCost + yearlyWaterHeatingCost + yearlyElectricCost - pvCost;

  return {
    yearlyHeatingCost: yearlyHeatingCost,
    yearlyWaterHeatingCost: yearlyWaterHeatingCost,
    yearlyElectricCost: yearlyElectricCost,
    photovoltaicGain: photovoltaicGain,
    pvCost: pvCost,
    yearlyCost: yearlyCost,
  };
}

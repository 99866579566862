import React from "react";
import "./PlotPanel.scss";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";
import { BasePlotPanel } from "./BasePlotPanel";
import { Popup } from "../Helpers/Popup";
import { EnergyClassHelper } from "../../util/EnergyClassHelper";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";
import connect from "react-redux/es/connect/connect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AggregatedEnergyChart from "./AggregatedEnergyChart";
import { HouseSpecs } from "../HouseSpecs/HouseSpecs";
import { ConverterHelper } from "../../core/converter/ConverterHelper";
import { getConversion, getHouse, getPersons } from "../../redux/selectors";
import {
  ConvertToUnit,
  createFormattedValue,
  fromWattsToKwhRatio,
} from "../Helpers/ConvertToUnit";
import { getPvEnergyCalculated } from "../../util/getPvEnergyCalculated";
import { getConvertedCosts } from "../../util/getConvertedCosts";

class PlotPanel extends BasePlotPanel {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  costMainLabel = "Koszty ogrzewania w poszczególnych miesiacach. [zl/mies]";
  mainLabelKWh =
    "Bilans energetyczny budynku w kolejnych miesiącach: energia na ogrzewanie [kWh/mies.]";
  mainLabelWatts =
    "Bilans energetyczny budynku w kolejnych miesiącach: energia na ogrzewanie [W]";
  mainLabelGJ =
    "Bilans energetyczny budynku w kolejnych miesiącach: energia na ogrzewanie [GJ/mies]";
  costEmissionLegendItemText = "hm.label.costs";
  costEmissionLegendText = "hm.label.monthly_cost";

  static COST = "Koszty";
  static EMISSION = "Emisje";
  static MONTH_COST = "Koszty miesięczne";
  static MONTH_EMISSION = "Emisje miesięczne";

  plotDataProvider = [];
  lineSeriesDataProvider = [];
  unitName = "";
  panelLabelWidth;
  bluePlotLineVisible = false;
  warmWaterGroup;
  legendEnergyLossGroupY;
  warmWaterHeatingEfficiency;
  warmWaterIncluded = false;
  isCostUnit = false;
  isEmissionUnit = false;
  energyClass;
  yearlyCost = 0;
  _originalChartWidth;

  getTotalConsumptionEnergyString = (
    energyConsumption,
    conversionData,
    limitPrecisionThreshold = Number.NaN
  ) => {
    let energyUnitName = this.getAdjustedEnergyUnitName(
      energyConsumption,
      conversionData.selectedUnit
    );
    let energyConsumptionVal = this.adjustNumber(energyConsumption);
    let decimalNumber = this.getDecimalNumbersCount(
      energyConsumption,
      ConversionUnits.isGJUnit(conversionData.selectedUnit),
      energyUnitName === "MWh"
    );

    if (isNaN(limitPrecisionThreshold)) {
      return this.formatHMValue(energyConsumptionVal, decimalNumber);
    }

    return this.formatHMValue(
      energyConsumptionVal,
      energyConsumptionVal < limitPrecisionThreshold
        ? Math.max(1, decimalNumber)
        : 0
    );
  };

  getDecimalNumbersCount = (value, isGJUnit, isMGWhUnit) => {
    let decimalNumber = 0;
    if (isGJUnit) {
      let formattedGJValue = this.formatHMValue(value);
      if (formattedGJValue.length <= 2) {
        decimalNumber = 3;
      } else if (formattedGJValue.length <= 4) {
        decimalNumber = 2;
      } else if (formattedGJValue.length <= 5) {
        decimalNumber = 1;
      }
    } else {
      if (isMGWhUnit) {
        decimalNumber = 2;
        let formattedMWhValue = this.formatHMValue(value);
        if (formattedMWhValue.length >= 5) {
          decimalNumber = 2 - (7 - Math.min(6, formattedMWhValue.length));
        }
      }
    }
    return decimalNumber;
  };

  formatEnergyText = (value, unitName, precision, textLength) => {
    let formatted = this.formatHMValue(value, precision) + " " + unitName;
    if (formatted.length < textLength) {
    }
    return formatted;
  };

  adjustNumber = value => {
    let r = value / 1000000;
    if (r === 0) {
      return value;
    }
    return value;
  };

  getAdjustedEnergyUnitName = (value, selectedUnit) => {
    let prefix = "k";
    let r = value / 1000000;
    if (r >= 1) {
      prefix = "M";
    }
    if (r >= 1000) {
      prefix = "G";
    }
    if (r > 1000000) {
      prefix = "T";
    }
    return prefix + "Wh";
  };

  chooseMainLabelText = conversionData => {
    let result = this.costMainLabel;
    if (ConversionUnits.isCostUnit(conversionData.selectedUnit)) {
      result = this.costMainLabel;
    } else if (ConversionUnits.isKiloWattsUnit(conversionData.selectedUnit)) {
      result = this.mainLabelKWh;
    } else if (conversionData.selectedUnit === ConversionUnits.ENERGY_WATTS) {
      result = this.mainLabelWatts;
    } else if (
      conversionData.selectedUnit === ConversionUnits.ENERGY_GJ_PER_YEAR
    ) {
      result = this.mainLabelGJ;
    }
    return result;
  };

  defineLabel = (labelValue, previousLabelValue, ax, labelItem) => {
    let monthName = labelItem.monthName;
    let enBalance = labelItem.enBalance.toString();
    return monthName + "\n" + enBalance;
  };

  getFontRatio = () => {
    if (this.width <= 1154) {
      return 11 / 12;
    }
    return 1;
  };

  getWidthRatio = () => {
    if (this.width <= 1154) {
      return 15 / 16;
    }
    return 1;
  };

  getOffsetRatio = () => {
    if (this.width <= 1154) {
      return 0.6;
    }
    return 1;
  };

  getFirstOffsetRatio = () => {
    if (this.width <= 1154) {
      return 0.8;
    }
    return 1;
  };

  getProperLegendLabel = () => {
    this.isCostUnit = ConversionUnits.isCostUnit(
      this.props.dataResults.conversionData.selectedUnit
    );
    this.isEmissionUnit = ConversionUnits.isEmisionUnit(
      this.props.dataResults.conversionData.selectedUnit
    );
    if (this.isCostUnit) {
      this.costEmissionLegendText = "hm.label.monthly_cost";
    } else if (this.isEmissionUnit) {
      this.costEmissionLegendText = "hm.label.monthly_emission";
    }
    return this.costEmissionLegendText;
  };

  getProperLegendItem = () => {
    this.isCostUnit = ConversionUnits.isCostUnit(
      this.props.dataResults.conversionData.selectedUnit
    );
    this.isEmissionUnit = ConversionUnits.isEmisionUnit(
      this.props.dataResults.conversionData.selectedUnit
    );
    if (this.isCostUnit) {
      this.costEmissionLegendItemText = "hm.label.costs";
    } else if (this.isEmissionUnit) {
      this.costEmissionLegendItemText = "hm.label.emissions";
    }
    return this.costEmissionLegendItemText;
  };

  render() {
    const energy = this.props.dataResults.yearlyAggregatedConvertedData;
    const pvCalc = getPvEnergyCalculated(
      this.props.dataResults,
      this.props.persons.livingNeedsChanged,
      this.props.persons.livingNeeds
    );

    let conversionData = { ...this.props.conversion };
    let electricConversionData = { ...this.props.conversion };
    let waterConversionData = { ...this.props.conversion };
    const costs = getConvertedCosts(
      this.props.dataResults,
      this.props.selectedUnit,
      pvCalc
    );
    const yearlyHeatingCost = createFormattedValue(
      ConverterHelper.toConvertedValue(costs.yearlyHeatingCost, conversionData)
        .zlotyValue,
      this.props.selectedUnit
    );

    waterConversionData.pricePerKWh = this.props.waterPricePerKWh;
    waterConversionData.emissionCoefficient = this.props.waterEmissionCoefficient;
    const yearlyWaterHeatingCost = createFormattedValue(
      ConverterHelper.toConvertedValue(
        costs.yearlyWaterHeatingCost,
        waterConversionData
      ).zlotyValue,
      this.props.selectedUnit
    );

    electricConversionData.pricePerKWh = this.props.electricityPricePerKWh;
    electricConversionData.emissionCoefficient = this.props.electricityEmissionCoefficient;
    const yearlyElectricCost = createFormattedValue(
      ConverterHelper.toConvertedValue(
        costs.yearlyElectricCost,
        electricConversionData
      ).zlotyValue,
      this.props.selectedUnit
    );
    const pvCost = createFormattedValue(
      ConverterHelper.toConvertedValue(costs.pvCost, electricConversionData)
        .zlotyValue,
      this.props.selectedUnit
    );
    const pvKWh = createFormattedValue(
      ConverterHelper.toConvertedValue(costs.pvCost, electricConversionData)
        .kWhValue,
      this.props.selectedUnit
    );

    conversionData.pricePerKWh = this.props.pricePerKWh;
    conversionData.emissionCoefficient = this.props.emissionCoefficient;
    const yearlyCost = createFormattedValue(
      yearlyHeatingCost + yearlyWaterHeatingCost + yearlyElectricCost - pvCost,
      this.props.selectedUnit
    );

    const finalYearlyBuyKWh = Math.abs(
      this.props.dataResults.finalYearlyCost * fromWattsToKwhRatio() - pvKWh
    );
    const finalUnitSurfaceYearlyBuyKWh = Math.abs(
      finalYearlyBuyKWh / this.props.house.surfaceData.totalSurface
    );

    const userYearlyKWh =
      this.props.dataResults.userYearlyCost * fromWattsToKwhRatio() - pvKWh;
    const unitSurfaceUserYearlyKWh =
      userYearlyKWh / this.props.house.surfaceData.totalSurface;
    const primaryHeatingYearKWh =
      this.props.dataResults.yearlyHeatingCost *
      fromWattsToKwhRatio() *
      this.props.conversion.finalToPrimaryCoefficient;
    const primaryWaterYearKWh =
      this.props.dataResults.yearlyWaterHeatingCost *
      fromWattsToKwhRatio() *
      this.props.conversion.warmWaterFinalToPrimaryCoefficient;
    const primaryYearlyElectricKWh =
      (this.props.dataResults.yearlyElectricCost * fromWattsToKwhRatio() -
        pvKWh) *
      this.props.conversion.electricityFinalToPrimaryCoefficient;
    const primaryYearKWh = Math.abs(
      primaryHeatingYearKWh + primaryWaterYearKWh + primaryYearlyElectricKWh
    );
    const unitSurfacePrimaryYearKWh =
      primaryYearKWh / this.props.house.surfaceData.totalSurface;
    const airConditioningDeviceEnergy = energy.airConditioningDeviceEnergy;
    const electricDevicesEnergy =
      energy.enElectricDevicesEnergyLossNormalized +
      energy.enRecuperator +
      energy.enHumidification +
      energy.enWaterElectricDevicesEnergyLoss +
      energy.enElectricVentilationEnergyLoss;
    const heatingDeviceEnergy = energy.heatingDeviceEnergy;
    const heatingWaterDeviceEnergy = energy.heatingWaterDeviceEnergy;
    const finalYearlyKWh = ConvertToUnit(
      Math.round(
        heatingDeviceEnergy +
          heatingWaterDeviceEnergy +
          airConditioningDeviceEnergy +
          electricDevicesEnergy
      ),
      ConversionUnits.ENERGY_KWH_PER_YEAR
    );
    const finalUnitSurfaceYearlyKWh = Math.abs(
      finalYearlyKWh / this.props.house.surfaceData.totalSurface
    );

    return (
      <div id={"plotContainer"}>
        <FontAwesomeIcon
          icon="times"
          onClick={e => this.props.togglePlotPanel(false)}
        />
        <div className={"d-block d-md-flex"}>
          <div className={"col-12 col-md-7 px-0 px-md-3"}>
            <div className={"row"}>
              <div className={"col-1 p-0"} />
              <div className={"col-3 col-sm-4  p-0"}>
                <FormattedMessage
                  id={"hm.label.effective_energy.consumption"}
                />
              </div>
              <div className={"col-3 px-3 text-right"}>
                {this.getTotalConsumptionEnergyString(
                  userYearlyKWh,

                  this.props.conversion
                )}
                {" " +
                  this.getAdjustedEnergyUnitName(
                    userYearlyKWh,
                    this.props.conversion.selectedUnit
                  )}
              </div>
              <div
                className={"col-4 col-sm-3 p-0 d-flex justify-content-around"}
              >
                <div className={" p-0 text-left"}>
                  <FormattedMessage id={"hm.label.per"} /> 1m<sup>2</sup>:
                </div>
                <div className={" p-0 text-right"}>
                  {this.getTotalConsumptionEnergyString(
                    unitSurfaceUserYearlyKWh,

                    this.props.conversion,
                    100000
                  )}
                  {" " +
                    this.getAdjustedEnergyUnitName(
                      unitSurfaceUserYearlyKWh,
                      this.props.conversion.selectedUnit
                    )}
                </div>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-1 text-center"}>
                <Popup
                  show={this.state.show}
                  message={"hm.tooltip.energy.final"}
                  className={"highest"}
                  placement={"right"}
                />
              </div>
              <div className={"col-3 col-sm-4 p-0"}>
                <FormattedHTMLMessage
                  id={"hm.label.final_energy.consumption"}
                />
              </div>
              <div className={"col-3 px-3 text-right"}>
                {this.getTotalConsumptionEnergyString(
                  finalYearlyKWh,

                  this.props.conversion
                )}
                {" " +
                  this.getAdjustedEnergyUnitName(
                    finalYearlyKWh,
                    this.props.conversion.selectedUnit
                  )}
              </div>
              <div
                className={"col-4 col-sm-3 p-0 d-flex justify-content-around"}
              >
                <div className={"p-0 text-left"}>
                  <FormattedMessage id={"hm.label.per"} /> 1m<sup>2</sup>:
                </div>
                <div className={"p-0 text-right"}>
                  {this.getTotalConsumptionEnergyString(
                    finalUnitSurfaceYearlyKWh,

                    this.props.conversion,
                    100000
                  )}
                  {" " +
                    this.getAdjustedEnergyUnitName(
                      finalUnitSurfaceYearlyKWh,
                      this.props.conversion.selectedUnit
                    )}
                </div>
              </div>
              <div className="col-1">
                {((this.props.house.standardTypeId ===
                  "hm.standard_type.wt17" &&
                  unitSurfacePrimaryYearKWh > 95) ||
                  (this.props.house.standardTypeId ===
                    "hm.standard_type.wt21" &&
                    unitSurfacePrimaryYearKWh > 70)) && (
                  <FormattedMessage
                    id="hm.label.primary_energy.consumption.warning"
                    defaultMessage=""
                  >
                    {message => (
                      <span
                        title={message}
                        className={"text-danger consumption-icon ml-1"}
                      >
                        <FontAwesomeIcon icon="exclamation-triangle" />
                      </span>
                    )}
                  </FormattedMessage>
                )}
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-1 text-center"}></div>
              <div className={"col-3 col-sm-4 p-0"}>
                <FormattedHTMLMessage id={"hm.label.final_energy.buy"} />
              </div>
              <div className={"col-3 px-3 text-right"}>
                {this.getTotalConsumptionEnergyString(
                  finalYearlyBuyKWh,

                  this.props.conversion
                )}
                {" " +
                  this.getAdjustedEnergyUnitName(
                    finalYearlyBuyKWh,
                    this.props.conversion.selectedUnit
                  )}
              </div>
              <div
                className={"col-4 col-sm-3 p-0 d-flex justify-content-around"}
              >
                <div className={"p-0 text-left"}>
                  <FormattedMessage id={"hm.label.per"} /> 1m<sup>2</sup>:
                </div>
                <div className={"p-0 text-right"}>
                  {this.getTotalConsumptionEnergyString(
                    finalUnitSurfaceYearlyBuyKWh,

                    this.props.conversion,
                    100000
                  )}
                  {" " +
                    this.getAdjustedEnergyUnitName(
                      finalUnitSurfaceYearlyBuyKWh,
                      this.props.conversion.selectedUnit
                    )}
                </div>
              </div>
              <div className="col-1" />
            </div>
            <div className={"row"}>
              <div className={"col-1"} />
              <div className={"col-3 col-sm-4 p-0"}>
                <FormattedHTMLMessage
                  id={"hm.label.primary_energy.consumption"}
                />
              </div>
              <div className={"col-3 px-3 text-right"}>
                {this.getTotalConsumptionEnergyString(
                  primaryYearKWh,

                  this.props.conversion
                )}
                {" " +
                  this.getAdjustedEnergyUnitName(
                    primaryYearKWh,
                    this.props.conversion.selectedUnit
                  )}
              </div>

              <div
                className={"col-4 col-sm-3 p-0 d-flex justify-content-around"}
              >
                <div className={"p-0 text-left"}>
                  <FormattedMessage id={"hm.label.per"} /> 1m<sup>2</sup>:
                </div>
                <div className={" p-0 text-right"}>
                  {this.getTotalConsumptionEnergyString(
                    unitSurfacePrimaryYearKWh,

                    this.props.conversion,
                    100000
                  )}
                  {" " +
                    this.getAdjustedEnergyUnitName(
                      unitSurfacePrimaryYearKWh,
                      this.props.conversion.selectedUnit
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className={"col-12 mt-3 mt-md-0 col-md-5"}>
            <div className={"row"}>
              <div
                className={"col-12 col-sm-6 text-alert text-center energy-cost"}
              >
                <h4>
                  <FormattedHTMLMessage id={"hm.label.energy_cost"} />
                </h4>
                {this.formatHMValue(yearlyCost)}
                &nbsp;
                {this.props.conversion.currencyName}
              </div>
              <div className={"col-12 col-sm-6 energy-class"}>
                <Popup
                  show={this.state.show}
                  message={"hm.tooltip.energy.class"}
                  placement={"left"}
                  className={"highest"}
                />
                <FormattedHTMLMessage id={"hm.label.energy_class"}>
                  {message => (
                    <span className={"energy-class-header ml-2"}>
                      {message}
                    </span>
                  )}
                </FormattedHTMLMessage>
                <span
                  id={"energyClassCodeLabel"}
                  style={{
                    color: EnergyClassHelper.getEnergyClass(
                      finalUnitSurfaceYearlyKWh
                    ).color,
                  }}
                >
                  {
                    EnergyClassHelper.getEnergyClass(finalUnitSurfaceYearlyKWh)
                      .code
                  }
                </span>
                <p
                  className={"energy-class-value"}
                  style={{
                    color: EnergyClassHelper.getEnergyClass(
                      finalUnitSurfaceYearlyKWh
                    ).color,
                  }}
                >
                  <FormattedMessage
                    id={
                      EnergyClassHelper.getEnergyClass(
                        finalUnitSurfaceYearlyKWh
                      ).description
                    }
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <LoadChart
          ifLoad={
            ConversionUnits.isEmisionUnit(
              this.props.dataResults.conversionData.selectedUnit
            ) ||
            ConversionUnits.isCostUnit(
              this.props.dataResults.conversionData.selectedUnit
            )
          }
          dataResults={this.props.dataResults}
          month={this.props.month}
          getMonthlyPlotData={(dataResults, month) =>
            this.getMonthlyPlotData(dataResults, month)
          }
          getRawUnitName={(unit, currency) =>
            this.getRawUnitName(unit, currency)
          }
          getProperLegendItem={unit => this.getProperLegendItem(unit)}
        />
        {this.props.dataResults && (
          <HouseSpecs
            dataResults={this.props.dataResults}
            selectedUnit={this.props.selectedUnit}
            pricePerKWh={this.props.pricePerKWh}
            waterPricePerKWh={this.props.waterPricePerKWh}
            electricityPricePerKWh={this.props.electricityPricePerKWh}
            emissionCoefficient={this.props.emissionCoefficient}
            waterEmissionCoefficient={this.props.waterEmissionCoefficient}
            electricityEmissionCoefficient={
              this.props.electricityEmissionCoefficient
            }
            finalUnitSurfaceYearlyKWh={finalUnitSurfaceYearlyKWh}
            unitSurfaceUserYearlyKWh={unitSurfaceUserYearlyKWh}
            unitSurfacePrimaryYearKWh={unitSurfacePrimaryYearKWh}
            airConditioningDeviceEnergy={airConditioningDeviceEnergy}
            electricDevicesEnergy={electricDevicesEnergy}
            heatingDeviceEnergy={heatingDeviceEnergy}
            heatingWaterDeviceEnergy={heatingWaterDeviceEnergy}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const conversion = getConversion(state);
  const persons = getPersons(state);
  const house = getHouse(state);
  return { conversion, persons, house };
};

function LoadChart(props) {
  return (
    <AggregatedEnergyChart
      dataResults={props.dataResults}
      month={props.month}
      getMonthlyPlotData={(dataResults, month) =>
        props.getMonthlyPlotData(dataResults, month)
      }
      getRawUnitName={(unit, currency) => props.getRawUnitName(unit, currency)}
      getProperLegendItem={unit => props.getProperLegendItem(unit)}
    />
  );
}

export default injectIntl(connect(mapStateToProps)(PlotPanel));

import { TightnessConfig } from "../TightnessConfig";
import { AbstractDictionaryReader } from "./AbstractDictionaryReader";
import { AirTightness } from "../model/AirTightness";

export class TightnessConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    let tightnessConfiguration = new TightnessConfig(
      this.readTightnessItems(dictionaryXML.tightness_configuration)
    );
    tightnessConfiguration.selectedIndex = this.getSelectedIndex(
      dictionaryXML.tightness_configuration
    );
    dictionaryConfig.tightnessConfiguration = tightnessConfiguration;
    return dictionaryConfig;
  };

  readTightnessItems = tightnessItemsXML => {
    let tightnessItems = [];
    tightnessItemsXML.tightness_item.forEach(tightnessItemXML => {
      tightnessItems.push(this.readTightnessItem(tightnessItemXML));
    });

    return tightnessItems;
  };

  readTightnessItem = tightnessItemXML => {
    const id = tightnessItemXML.id;
    const value = Number(tightnessItemXML.value);
    const ventNatural = tightnessItemXML.vent_natural;

    const airTightness = new AirTightness(id, value, ventNatural);
    this.fillBaseProperties(tightnessItemXML, airTightness);
    return airTightness;
  };
}

import { ConversionUnits } from "../../constants/combo/ConversionUnits";

export const ConvertToUnit = (
  value,
  unit,
  pricePerKWh = 1,
  emissionCoefficient = 1
) => {
  let ratio = 1;
  if (ConversionUnits.isKiloWattsUnit(unit)) {
    ratio = fromWattsToKwhRatio();
  } else if (ConversionUnits.isCostUnit(unit)) {
    ratio = fromWattsToKwhRatio() * pricePerKWh;
  } else if (ConversionUnits.isGJUnit(unit)) {
    ratio = (24 * 3600 * 30) / 1000000000;
  } else if (ConversionUnits.isEmisionUnit(unit)) {
    ratio = fromWattsToKwhRatio() * emissionCoefficient;
  }

  return value * ratio;
};

export const fromWattsToKwhRatio = () => {
  return (24 * 30) / 1000;
};

export const fromKwhToWattsRatio = () => {
  return 1000 / (24 * 30);
};

export const createFormattedValue = (value, unit) => {
  if (value >= 10000000 && ConversionUnits.isEmisionUnit(unit)) {
    value = value / 1000;
  }
  return Math.round(value);
};

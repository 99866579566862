import { DoorTypeConfigReader } from "./reader/DoorTypeConfigReader";
import { InsulationMaterialTypeConfigReader } from "./reader/InsulationMaterialTypeConfigReader";
import { SolarCollectorTypeConfigReader } from "./reader/SolarCollectorTypeConfigReader";
import { ThermalBridgesTypeConfigReader } from "./reader/ThermalBridgesTypeConfigReader";
import { VentilationTypeConfigReader } from "./reader/VentilationTypeConfigReader";
import { WindowTypeConfigReader } from "./reader/WindowTypeConfigReader";
import { TightnessConfigReader } from "./reader/TightnessConfigReader";
import { FoundationsTypeConfigReader } from "./reader/FoundationsTypeConfigReader";
import { WaterStorageDistributionConfigReader } from "./reader/WaterStorageDistributionConfigReader";
import { WarmWaterConsumptionConfigReader } from "./reader/WarmWaterConsumptionConfigReader";
import { HouseStandardTypeConfigReader } from "./reader/HouseStandardTypeConfigReader";
import { DictionaryConfig } from "./DictionaryConfig";
import { ShadingConfigReader } from "./reader/ShadingConfigReader";
import { HeatingDeviceTypeConfigReader } from "./reader/HeatingDeviceTypeConfigReader";

export class DictionaryConfigReader {
  dictionaryXML;
  resourceManager;
  dictionaryConfigurationReaders = [];
  houseStandardConfigurationReader;

  constructor(dictionaryXML, resourceManager) {
    this.dictionaryXML = dictionaryXML;
    this.resourceManager = resourceManager;

    this.dictionaryConfigurationReaders.push(
      new DoorTypeConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new InsulationMaterialTypeConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new SolarCollectorTypeConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new ThermalBridgesTypeConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new VentilationTypeConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new WindowTypeConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new TightnessConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new FoundationsTypeConfigReader(resourceManager)
    );

    this.dictionaryConfigurationReaders.push(
      new HeatingDeviceTypeConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new WaterStorageDistributionConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new WarmWaterConsumptionConfigReader(resourceManager)
    );
    this.dictionaryConfigurationReaders.push(
      new ShadingConfigReader(resourceManager)
    );
    this.houseStandardConfigurationReader = new HouseStandardTypeConfigReader(
      resourceManager
    );

    this.readConfig();
  }

  readConfig = () => {
    let dictionaryConfig = new DictionaryConfig();

    for (let i = 0; i < this.dictionaryConfigurationReaders.length; i++) {
      let dictionaryReader = this.dictionaryConfigurationReaders[i];
      dictionaryConfig = dictionaryReader.readXMLConfig(
        dictionaryConfig,
        this.dictionaryXML
      );
    }

    dictionaryConfig = this.houseStandardConfigurationReader.readXMLConfig(
      dictionaryConfig,
      this.dictionaryXML
    );

    return dictionaryConfig;
  };
}

import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";

export class WindowsEnergyCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInputData) => {
    const houseData = contextData.houseData;
    const surfaceData = houseData.surfaceData;
    const windowElement = houseData.windowElement;

    let enWindows = EnergyCalcHelper.calcHeatTransfer(
      windowElement.uValue,
      surfaceData.windowsSurface,
      houseData.tIn,
      monthInputData.tOut
    );

    if (houseData.windowElement.shutters) {
      enWindows = enWindows * 0.8;
    }

    const thermalBridgesUValue = windowElement.thermalBridgesType.uValue;
    enWindows +=
      thermalBridgesUValue *
      (houseData.tIn - monthInputData.tOut) *
      surfaceData.windowsSurface *
      3;
    energyData.enWindows = Math.max(enWindows, 0);
    return energyData;
  };
}

import { FoundationsTypeConfig } from "../FoundationsTypeConfig";
import { FoundationsType } from "../model/FoundationsType";
import { AbstractDictionaryReader } from "./AbstractDictionaryReader";

export class FoundationsTypeConfigReader extends AbstractDictionaryReader {
  readXMLConfig = (dictionaryConfig, dictionaryXML) => {
    dictionaryConfig.foundationConfiguration = this.readFoundationTypes(
      dictionaryXML.foundation_types
    );
    return dictionaryConfig;
  };

  readFoundationTypes = foundationItemsXML => {
    let foundationItems = [];
    let foundationTypeConfig = new FoundationsTypeConfig();

    foundationTypeConfig.selectedIndex = Number(
      foundationItemsXML.selected_index
    );
    foundationItemsXML.foundation_type.forEach(foundationItemXML => {
      foundationItems.push(this.readFoundationType(foundationItemXML));
    });
    foundationTypeConfig.foundationsType = foundationItems;
    return foundationTypeConfig;
  };

  readFoundationType = foundationTypeXML => {
    let id = foundationTypeXML.id;
    let fType = Number(foundationTypeXML.type);
    let uValue = Number(foundationTypeXML.u_value);

    let foundationsType = new FoundationsType(id, fType, uValue);
    this.fillBaseProperties(foundationTypeXML, foundationsType);
    return foundationsType;
  };
}

export class RatioCluster {
  _toCostRatio;
  _toWattsRatio;
  _Number;
  _tokWhRatio;
  _toGJRatio;
  _toEmissionRatio;
  _toWarmWaterEmissionRatio;

  multiply = value => {
    this._toCostRatio *= value;
    this._toWattsRatio *= value;
    this._tokWhRatio *= value;
    return this;
  };

  get toCostRatio() {
    return this._toCostRatio;
  }

  set toCostRatio(value) {
    this._toCostRatio = value;
  }

  get toWattsRatio() {
    return this._toWattsRatio;
  }

  set toWattsRatio(value) {
    this._toWattsRatio = value;
  }

  get Number() {
    return this._Number;
  }

  set Number(value) {
    this._Number = value;
  }

  get tokWhRatio() {
    return this._tokWhRatio;
  }

  set tokWhRatio(value) {
    this._tokWhRatio = value;
  }

  get toGJRatio() {
    return this._toGJRatio;
  }

  set toGJRatio(value) {
    this._toGJRatio = value;
  }

  get toEmissionRatio() {
    return this._toEmissionRatio;
  }

  set toEmissionRatio(value) {
    this._toEmissionRatio = value;
  }

  get toWarmWaterEmissionRatio() {
    return this._toWarmWaterEmissionRatio;
  }

  set toWarmWaterEmissionRatio(value) {
    this._toWarmWaterEmissionRatio = value;
  }
}

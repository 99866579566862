export class VentilationTypeConfig {
  _ventilationTypes;
  _selectedIndex;

  constructor(ventilationTypes) {
    this._ventilationTypes = ventilationTypes;
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  set selectedIndex(value) {
    this._selectedIndex = value;
  }

  get ventilationTypes() {
    return this._ventilationTypes;
  }

  set ventilationTypes(value) {
    this._ventilationTypes = value;
  }
}

import { HeatMaster } from "../HeatMaster";
import React from "react";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { Button } from "react-bootstrap";
import connect from "react-redux/es/connect/connect";
import "./EnergyMeter.scss";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";
import { EnvironmentalDataPreparer } from "../../ui/builder/prepare/EnvironmentalDataPreparer";
import {
  getConversion,
  getHouse,
  getPersons,
  getPV,
} from "../../redux/selectors";
import { EnvironmentalData } from "../../core/model/EnvironmentalData";
import cx from "classnames";
import { updatePhotovoltaicById } from "../../redux/actions";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";
import PieEnergyChartGroup from "../Charts/PieEnergyChartGroup";
import getMonthlyPlotData from "../../util/getMonthlyPlotData";
import { createFormattedValue } from "../Helpers/ConvertToUnit";
import { getPvEnergyCalculated } from "../../util/getPvEnergyCalculated";
import { getConvertedCosts } from "../../util/getConvertedCosts";

class EnergyMeter extends HeatMaster {
  constructor(props) {
    super(props);
    this.state = {};
  }

  environmentalData;
  selectedMonth = 0;
  formattedReferenceValue = 0;
  formattedNewValue = 0;
  selectedCountry;
  businessConfiguration;
  refProcessingResult;
  newProcessingResult;

  CURRENCY_EURO_LOCALE_CODE = "hm.currency.euro";

  monthList = [
    "hm.dropdown.month_1",
    "hm.dropdown.month_2",
    "hm.dropdown.month_3",
    "hm.dropdown.month_4",
    "hm.dropdown.month_5",
    "hm.dropdown.month_6",
    "hm.dropdown.month_7",
    "hm.dropdown.month_8",
    "hm.dropdown.month_9",
    "hm.dropdown.month_10",
    "hm.dropdown.month_11",
    "hm.dropdown.month_12",
  ];

  componentWillMount() {
    this.configurationRead(this.props.businessConfig);
  }

  configurationRead = businessConfiguration => {
    this.businessConfiguration = businessConfiguration;
    this.countryDropDownList = businessConfiguration.countryItems;
  };

  freezeReferenceState = () => {
    this.initNewStateView();

    let currentDataContext = this.props.cachedData.getCurrentDataContext();
    currentDataContext.environmentalData = EnvironmentalDataPreparer.prepareFromCache(
      new EnvironmentalData(),
      this.props.cachedData.environmentalData
    );
    this.formattedNewValue = this.formattedReferenceValue;
    this.newProcessingResult = this.refProcessingResult;

    this.props.calculationStateController.freezeReferenceState(
      currentDataContext
    );
  };

  switchToNewState = () => {
    this.props.calculationStateController.switchToNewState();
    this.props.toggleReferenceState(false);
    this.props.configChanged(
      ConversionUnits.isLocalCurrencyCostUnit(
        this.props.conversion.selectedUnit
      ),
      true,
      false
    );
  };

  switchToReferenceState = () => {
    this.props.calculationStateController.switchToReferenceState();
    this.props.toggleReferenceState(true);
    this.props.configChanged(
      ConversionUnits.isLocalCurrencyCostUnit(
        this.props.conversion.selectedUnit
      ),
      true,
      false
    );
  };

  initReferenceStateView = () => {
    this.props.toggleCompareView();
  };

  initNewStateView = () => {
    this.props.toggleCompareView();
  };

  getShortUnitNameCode = (unit, shortUnitNameCode) => {
    let result = shortUnitNameCode;
    if (ConversionUnits.isLocalCurrencyCostUnit(unit)) {
      result = this.selectedCountry.currencyLocaleCode + ".";
      result += ConversionUnits.isYearUnit(unit)
        ? HeatMaster.YEAR_COST_SUFFIX_CODE
        : HeatMaster.MONTH_COST_SUFFIX_CODE;
    }
    return result;
  };

  tempChanged = () => {
    this.environmentalData = EnvironmentalDataPreparer.applyTemperatureStats(
      this.environmentalData
    );
  };

  showMonthlyReport = () => {
    this.props.togglePlotPanel(true);
  };

  showBalanceReport = () => {
    this.props.toggleComparingPlotPanel(true);
  };

  toggleOptimizerPanel = () => {
    this.unitUIChanged(ConversionUnits.ENERGY_KWH_PER_YEAR);
    this.props.toggleOptimizerPanel();
  };

  applyPvPrice = (configItem, localCurrency) => {
    let rate = 1;
    if (configItem.ignoreConfigPrices) {
      rate = localCurrency
        ? configItem.currencyExchangeRate
        : 1 / configItem.currencyExchangeRate;
    }
    this.props
      .dispatch(
        updatePhotovoltaicById({
          id: "price",
          value: this.props.pv.price * rate,
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  unitUIChanged = unit => {
    this.props.unitUIChanged(unit);
  };

  render() {
    const monthlyPlotData = this.props.dataResults
      ? getMonthlyPlotData(this.props.dataResults)
      : [];
    let refValue = 0;
    let newValue = 0;
    if (this.props.refProcessingResult) {
      const pvCalc = getPvEnergyCalculated(
        this.props.refProcessingResult,
        this.props.refProcessingResult.conversionData.livingNeedsChanged,
        this.props.refProcessingResult.conversionData.livingNeeds
      );
      const costs = getConvertedCosts(
        this.props.refProcessingResult,
        this.props.conversion.selectedUnit,
        pvCalc
      );
      refValue = createFormattedValue(
        costs.yearlyHeatingCost +
          costs.yearlyWaterHeatingCost +
          costs.yearlyElectricCost -
          costs.pvCost,
        this.props.conversion.selectedUnit
      );
      if (this.props.newProcessingResult) {
        const pvCalc = getPvEnergyCalculated(
          this.props.newProcessingResult,
          this.props.newProcessingResult.conversionData.livingNeedsChanged,
          this.props.newProcessingResult.conversionData.livingNeeds
        );
        const costs = getConvertedCosts(
          this.props.newProcessingResult,
          this.props.conversion.selectedUnit,
          pvCalc
        );
        newValue = createFormattedValue(
          costs.yearlyHeatingCost +
            costs.yearlyWaterHeatingCost +
            costs.yearlyElectricCost -
            costs.pvCost,
          this.props.conversion.selectedUnit
        );
      }
    }
    return (
      <div className={"energy-meter"}>
        <div className={"content row align-items-center"}>
          <div className={"mr-3"}>
            <select
              className={"form-control"}
              onChange={e => {
                this.unitUIChanged(e.target.value);
              }}
              value={this.props.conversion.selectedUnit}
              disabled={this.props.showOptimizerPanel}
              id={"unitDropDownList"}
            >
              {this.props.unitList.map(value => (
                <FormattedMessage id={value.localeCode} key={value.localeCode}>
                  {message => (
                    <option value={value.data}>
                      {ConversionUnits.isLocalCurrencyCostUnit(value.data)
                        ? message + " [" + value.labelUnit + "]"
                        : message}
                    </option>
                  )}
                </FormattedMessage>
              ))}
            </select>
          </div>
          <div className={"spacer"} />
          <div
            className={cx(
              "d-flex btn rounded-0 mr-0 p-0 align-self-start",
              { "active btn-whites": this.props.referenceState },
              {
                "pointer ": !this.props.referenceState,
              }
            )}
            onClick={() => {
              if (!this.props.referenceState) {
                this.switchToReferenceState();
              }
            }}
          >
            <div className={`px-1 col-8`}>
              <FormattedMessage
                id={"hm.config.reference"}
                key={"hm.config.reference"}
              >
                {message => {
                  return <p className={"mb-0"}>{message}</p>;
                }}
              </FormattedMessage>
              <div className={"d-flex justify-content-center"}>
                <p
                  id={"refValueLabel"}
                  className={"value mr-1 mb-0 font-weight-bold"}
                >
                  {refValue > 0 ? refValue : 0}
                </p>
                <FormattedMessage id={this.props.conversion.unitName}>
                  {message => (
                    <p className={"value mb-0 font-weight-bold"}>{message}</p>
                  )}
                </FormattedMessage>
              </div>
            </div>
            {this.props.dataResults &&
              this.props.referenceState &&
              window.innerWidth >= 900 && (
                <FormattedMessage
                  id={"hm.tooltip.button.report"}
                  key={"hm.tooltip.button.report"}
                >
                  {message => {
                    return (
                      <span
                        title={message}
                        onClick={() => this.showMonthlyReport()}
                      >
                        <PieEnergyChartGroup
                          dataResults={this.props.dataResults}
                          data={monthlyPlotData
                            .filter(
                              item => item.type === "loss" && item.value > 0
                            )
                            .map(item => {
                              item.name = this.props.intl.formatMessage({
                                id: item.name,
                              });
                              return item;
                            })}
                          type={"loss"}
                          style={{ marginLeft: "-10px" }}
                          legend={false}
                        />
                      </span>
                    );
                  }}
                </FormattedMessage>
              )}
            {this.props.dataResults &&
              this.props.referenceState &&
              window.innerWidth >= 900 && (
                <PieEnergyChartGroup
                  dataResults={this.props.dataResults}
                  data={monthlyPlotData
                    .filter(item => item.type === "gain" && item.value > 0)
                    .map(item => {
                      item.name = this.props.intl.formatMessage({
                        id: item.name,
                      });
                      return item;
                    })}
                  type={"gain"}
                  legend={false}
                />
              )}
          </div>

          {this.props.inCompareView && (
            <div
              onClick={() => {
                if (this.props.referenceState) {
                  this.switchToNewState();
                }
              }}
              className={cx(
                "ml-0 btn rounded-0 mr-0 p-0 align-self-start",
                { "d-flex": this.props.inCompareView },
                { "active  btn-whites": !this.props.referenceState },
                { "pointer ": this.props.referenceState }
              )}
            >
              <div className={`px-1 col-8`}>
                <FormattedMessage id={"hm.config.new"} key={"hm.config.new"}>
                  {message => {
                    return <p className={"mb-0"}>{message}</p>;
                  }}
                </FormattedMessage>
                <div className={"d-flex justify-content-center"}>
                  <p
                    id={"newValueLabel"}
                    className={"value mr-1 mb-0 font-weight-bold"}
                  >
                    {newValue > 0 ? newValue : 0}
                  </p>
                  <FormattedMessage id={this.props.conversion.unitName}>
                    {message => (
                      <p className={"value mb-0 font-weight-bold"}>{message}</p>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              {this.props.dataResults &&
                !this.props.referenceState &&
                window.innerWidth >= 900 && (
                  <FormattedMessage
                    id={"hm.tooltip.button.report"}
                    key={"hm.tooltip.button.report"}
                  >
                    {message => {
                      return (
                        <span
                          title={message}
                          onClick={() => this.showMonthlyReport()}
                        >
                          <PieEnergyChartGroup
                            dataResults={this.props.dataResults}
                            data={monthlyPlotData
                              .filter(
                                item => item.type === "loss" && item.value > 0
                              )
                              .map(item => {
                                item.name = this.props.intl.formatMessage({
                                  id: item.name,
                                });
                                return item;
                              })}
                            style={{ marginLeft: "-10px" }}
                            type={"loss"}
                            legend={false}
                          />
                        </span>
                      );
                    }}
                  </FormattedMessage>
                )}
              {this.props.dataResults && !this.props.referenceState && (
                <PieEnergyChartGroup
                  dataResults={this.props.dataResults}
                  data={monthlyPlotData
                    .filter(item => item.type === "gain" && item.value > 0)
                    .map(item => {
                      item.name = this.props.intl.formatMessage({
                        id: item.name,
                      });

                      return item;
                    })}
                  type={"gain"}
                  legend={false}
                />
              )}
            </div>
          )}

          <div className={" pl-0 d-flex ml-3 balance-report"}>
            {this.props.inCompareView && (
              <div className={"align-self-center"}>
                <FormattedMessage
                  id={"hm.tooltip.button.report-compare"}
                  key={"hm.tooltip.button.report-compare"}
                >
                  {message => {
                    return (
                      <span
                        className={
                          "pointer display-inline ml-3 font-weight-bold"
                        }
                        onClick={() => {
                          this.showBalanceReport();
                        }}
                        title={message}
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={20}
                          icon={"raport_icon-color"}
                        />
                      </span>
                    );
                  }}
                </FormattedMessage>
                {this.props.house.country === "pl" && (
                  <FormattedHTMLMessage
                    id={"hm.tooltip.optimizer"}
                    key={"hm.tooltip.optimizer"}
                  >
                    {message => {
                      return (
                        <span
                          className={
                            "pointer display-inline ml-3 font-weight-bold"
                          }
                          onClick={() => {
                            this.toggleOptimizerPanel();
                          }}
                          title={message}
                        >
                          <IcomoonReact
                            className={"pointer"}
                            iconSet={iconSet}
                            size={20}
                            icon={"optimizer_icon2"}
                          />
                        </span>
                      );
                    }}
                  </FormattedHTMLMessage>
                )}
              </div>
            )}
          </div>
          {!this.props.inCompareView && (
            <div className={"text-center"}>
              <Button
                id="freezeStateButton"
                size="sm"
                variant="primary"
                onClick={() => {
                  this.freezeReferenceState();
                  this.switchToNewState();
                }}
              >
                <FormattedMessage id="hm.button.compare.houses" />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  let conversion = getConversion(state);
  let house = getHouse(state);
  let persons = getPersons(state);
  let pv = getPV(state);
  return { conversion, house, pv, persons };
};

export default injectIntl(connect(mapStateToProps)(EnergyMeter));

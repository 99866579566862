export class HouseType {
  static DETACHED = 1;
  static TERRACED_LEFT = 2;
  static TERRACED_CENTER = 3;
  static TERRACED_RIGHT = 4;
  static MULTI_FAMILY_UPPER = 5;
  static MULTI_FAMILY_CENTER = 6;
  static MULTI_FAMILY_FIRST_FLOOR = 7;

  static BLOCK_CENTER = 8;
  static BLOCK_CENTER_UPPER = 9;
  static BLOCK_CENTER_LOWER = 10;
  static BLOCK_EDGE_RIGHT = 11;
  static BLOCK_EDGE_LEFT = 12;
  static BLOCK_LOWER_RIGHT = 13;
  static BLOCK_LOWER_LEFT = 14;
  static BLOCK_UPPER_RIGHT = 15;
  static BLOCK_UPPER_LEFT = 16;

  static BLOCK_DOUBLE_CENTER = 17;
  static BLOCK_DOUBLE_CENTER_UPPER = 18;
  static BLOCK_DOUBLE_CENTER_LOWER = 19;
  static BLOCK_DOUBLE_EDGE_RIGHT = 20;
  static BLOCK_DOUBLE_EDGE_LEFT = 21;
  static BLOCK_DOUBLE_LOWER_RIGHT = 22;
  static BLOCK_DOUBLE_LOWER_LEFT = 23;
  static BLOCK_DOUBLE_UPPER_RIGHT = 24;
  static BLOCK_DOUBLE_UPPER_LEFT = 25;
}

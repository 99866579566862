import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { windowBreakPoints } from "../constants/windowBreakpoints";
import { updateBreakpoints } from "../redux/ui/actions";

export const WindowResizer = () => {
  const { breakpoints } = useSelector(state => state.ui);
  const dispatch = useDispatch();
  const state = useRef({ breakpoints });

  useEffect(() => {
    state.current.breakpoints = breakpoints;
  }, [breakpoints]);

  const onResize = () => {
    const values = windowBreakPoints.filter(
      width => window.innerWidth >= width
    );
    if (values.length !== state.current.breakpoints.length) {
      dispatch(updateBreakpoints(values));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return <div></div>;
};

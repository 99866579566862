import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";
import { VentilationMethod } from "../../../config/dictionary/model/VentilationMethod";

export class BaseVentilationEnergyCalculator extends IMonthEnergyCalculator {
  superCalcEnergy = (energyData, contextData, monthInputData) => {
    try {
      const houseData = contextData.houseData;
      const ventilationData = houseData.ventilationData;
      let heatCoefficient = 1;
      if (
        ventilationData.ventilationMethodObject.type ===
        VentilationMethod.MECHANICAL
      ) {
        heatCoefficient = this.calcHeatCoefficient(
          ventilationData.ventilationMethodObject.efficiency
        );
      }
      const tInEffective = this.calcEffectiveTIn(
        contextData,
        monthInputData.tOut,
        monthInputData.tGround
      );
      const airVolume = this.calcAirVolume(
        contextData,
        monthInputData.tOut,
        tInEffective
      );

      const energy = EnergyCalcHelper.calcAirHeatEnergyTransfer(
        airVolume,
        houseData.tIn,
        tInEffective,
        heatCoefficient
      );
      energyData = this.applyCalculatedEnergy(energy, energyData);
    } catch (e) {}

    return energyData;
  };

  applyCalculatedEnergy = (energy, energyData) => energyData;

  calcHeatCoefficient = efficiency => 1;

  calcAirVolume = (contextData, tOut, tInEffective) => 0;

  calcEffectiveTIn = (contextData, tOut, month) => {
    return tOut;
  };

  getType = () => {
    return 0;
  };
}

import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { ElectricityConsumption } from "../../../constants/combo/ElectricityConsumption";
import { EnergyCalcHelper } from "./EnergyCalcHelper";

export class ElectricityDevicesEnergyGainCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInputData) => {
    energyData.enElectricityGain = 0;
    return energyData;
  };

  calElectricityDevicesGain = (personNumber, electricityUsage) => {
    let enGain = 0;
    if (personNumber === 1) {
      enGain = 1300;
    } else if (personNumber === 2) {
      enGain = 1930;
    } else if (personNumber === 3) {
      enGain = 2480;
    } else if (personNumber === 4) {
      enGain = 2830;
    } else if (personNumber >= 5) {
      enGain = 2830 + 420 * (personNumber - 4);
    }

    if (electricityUsage === ElectricityConsumption.VERY_LOW) {
      enGain *= 0.5;
    } else if (electricityUsage === ElectricityConsumption.LOW) {
      enGain *= 0.8;
    } else if (electricityUsage === ElectricityConsumption.BIG) {
      enGain *= 1.4;
    } else if (electricityUsage === ElectricityConsumption.VERY_BIG) {
      enGain *= 2.0;
    }

    enGain = EnergyCalcHelper.convertkWhPerYearToWatts(enGain);

    return enGain;
  };
}

import { UPDATE_CONVERSION } from "../actionTypes";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

const initialState = {
  selectedUnit: ConversionUnits.LOCAL_CURRENCY_COTS_PER_YEAR,
  unitName: "hm.currency.pln.year_cost",
  pricePerKWh: 0.12461538461538461,
  electricityPricePerKWh: 0.1576,
  electricityPricePerKWhLocal: 0.65,
  electricityEmissionCoefficient: 0.89,
  isElectric: false,
  waterPricePerKWh: 0.12461538461538461,
  pvPricePerKWh: 0.62,
  airConditioning: false,
  houseHeatingEfficiency: 70 * 70,
  warmWaterHeatingEfficiency: 95,
  finalToPrimaryCoefficient: 1.1,
  warmWaterFinalToPrimaryCoefficient: 1.1,
  electricityFinalToPrimaryCoefficient: 3,
  emissionCoefficient: 0.338,
  waterEmissionCoefficient: 0.338,
  isWaterElectric: false,
  toPLNCurrencyExchangeRate: 1,
  currencyLocaleCode: "hm.currency.pln",
  currencyName: "PLN",
  naturalUnitPrice: 218.208267669,
  naturalUnitPriceLocal: 900,
  naturalUnitPriceChanged: false,

  heatingSource: "hm.dropdown.heating.source.solid_fuel_boiler",
  waterHeatingSource: "hm.dropdown.heating.source.the_same",
  includeWarmWater: true,
  warmWaterStorageId: "hm.water-storage.heat_node_without_storage",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CONVERSION: {
      const { conversionData } = action.payload;
      return {
        ...state,
        ...conversionData,
      };
    }
    default:
      return state;
  }
}

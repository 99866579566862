import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { getHouse } from "../../redux/selectors";
import connect from "react-redux/es/connect/connect";
import { HouseSpecsTableTd } from "./HouseSpecsTableTd";
import Table from "react-bootstrap/Table";
import PieEnergyChartGroup from "../Charts/PieEnergyChartGroup";
import { ConvertToUnit } from "../Helpers/ConvertToUnit";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

function HouseEnergyLossSpecs(props) {
  const totalSurface = props.house.surfaceData.totalSurface;
  const { yearlyAggregatedConvertedData } = props.dataResults;
  const enFloor = ConvertToUnit(
    yearlyAggregatedConvertedData.enFloor,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const enFoundations = ConvertToUnit(
    yearlyAggregatedConvertedData.enFoundations,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const enWalls = ConvertToUnit(
    yearlyAggregatedConvertedData.enWalls,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const enWindows = ConvertToUnit(
    yearlyAggregatedConvertedData.enWindows,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const enRoof = ConvertToUnit(
    yearlyAggregatedConvertedData.enRoof,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const enDoor = ConvertToUnit(
    yearlyAggregatedConvertedData.enDoor,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );
  const enVent = ConvertToUnit(
    yearlyAggregatedConvertedData.enVent +
      yearlyAggregatedConvertedData.enTightness +
      yearlyAggregatedConvertedData.enHumidification +
      yearlyAggregatedConvertedData.enRecuperator,
    ConversionUnits.ENERGY_KWH_PER_YEAR
  );

  const energySum = Math.round(
    enFloor + enFoundations + enWalls + enDoor + enWindows + enRoof + enVent
  );
  return (
    <>
      <FormattedMessage id="hm.house_spec.heating" defaultMessage="">
        {message => (
          <h2 className={"mb-0 text-center"}>
            {message} [kWh/(m<sup>2</sup>·
            {<FormattedMessage id="common.year" />})]
          </h2>
        )}
      </FormattedMessage>
      <Table striped bordered hover responsive className={"text-center w-100"}>
        <tbody>
          <tr>
            <HouseSpecsTableTd />
            <HouseSpecsTableTd message={"hm.house_spec.energy.floor"} />
            <HouseSpecsTableTd message={"hm.house_spec.energy.walls"} />
            <HouseSpecsTableTd message={"hm.house_spec.energy.windows"} />
            <HouseSpecsTableTd message={"hm.house_spec.energy.roof"} />
            <HouseSpecsTableTd message={"hm.house_spec.energy.ventilation"} />
            <HouseSpecsTableTd message={"hm.house_spec.sum"} />
          </tr>
          <tr>
            <HouseSpecsTableTd
              value={
                <>
                  [kWh/(m<sup>2</sup>·{<FormattedMessage id="common.year" />})]
                </>
              }
            />
            <HouseSpecsTableTd
              value={((enFloor + enFoundations) / totalSurface).toFixed(1)}
            />
            <HouseSpecsTableTd
              value={((enWalls + enDoor) / totalSurface).toFixed(1)}
            />
            <HouseSpecsTableTd value={(enWindows / totalSurface).toFixed(1)} />
            <HouseSpecsTableTd value={(enRoof / totalSurface).toFixed(1)} />
            <HouseSpecsTableTd value={(enVent / totalSurface).toFixed(1)} />
            <HouseSpecsTableTd value={(energySum / totalSurface).toFixed(1)} />
          </tr>
          <tr>
            <HouseSpecsTableTd message={"hm.house_spec.participation"} />
            <HouseSpecsTableTd
              value={Math.round(((enFloor + enFoundations) / energySum) * 100)}
            />
            <HouseSpecsTableTd
              value={Math.round(((enWalls + enDoor) / energySum) * 100)}
            />
            <HouseSpecsTableTd
              value={Math.round((enWindows / energySum) * 100)}
            />
            <HouseSpecsTableTd value={Math.round((enRoof / energySum) * 100)} />
            <HouseSpecsTableTd value={Math.round((enVent / energySum) * 100)} />
            <HouseSpecsTableTd value={100} />
          </tr>
        </tbody>
      </Table>
      <PieEnergyChartGroup
        dataResults={props.dataResults}
        data={[
          {
            name: props.intl.formatMessage({
              id: "hm.house_spec.energy.floor",
            }),
            value: parseFloat(
              ((enFloor + enFoundations) / totalSurface).toFixed(1)
            ),
            fill: "#315529",
          },
          {
            name: props.intl.formatMessage({
              id: "hm.house_spec.energy.walls",
            }),
            value: parseFloat(((enWalls + enDoor) / totalSurface).toFixed(1)),
            fill: "#494949",
          },
          {
            name: props.intl.formatMessage({
              id: "hm.house_spec.energy.windows",
            }),
            value: parseFloat((enWindows / totalSurface).toFixed(1)),
            fill: "#5babe2",
          },
          {
            name: props.intl.formatMessage({ id: "hm.house_spec.energy.roof" }),
            value: parseFloat((enRoof / totalSurface).toFixed(1)),
            fill: "#830000",
          },
          {
            name: props.intl.formatMessage({
              id: "hm.house_spec.energy.ventilation",
            }),
            value: parseFloat((enVent / totalSurface).toFixed(1)),
            fill: "#59318a",
          },
        ]}
        legend={true}
      />
    </>
  );
}

const mapStateToProps = state => {
  const house = getHouse(state);
  return { house };
};

export default injectIntl(connect(mapStateToProps)(HouseEnergyLossSpecs));

import { UValueBasedElementPreparer } from "../../../ui/builder/prepare/element/UValueBasedElementPreparer";
import { IrrCounter } from "../../../util/IrrCounter";
import { RoofElement } from "../../model/RoofElement";
import { fromWattsToKwhRatio } from "../../../components/Helpers/ConvertToUnit";

export function RoofInsulationOptimizer(
  type,
  cancel,
  refDataContext,
  newDataContext,
  heatMasterController,
  reports,
  dictionaryConfig
) {
  const roofInsulationList =
    dictionaryConfig.insulationMaterialTypeConfig.roofInsulationMaterialTypes;
  const newHouseData = newDataContext.houseData;
  const oldElement = refDataContext.houseData.roofElement;
  const refHeatingEff = refDataContext.heatingData.heatingEfficiency;
  const refPricePerKWh =
    refDataContext.conversionData.pricePerKWh / (refHeatingEff / 100);
  const refCosts = refDataContext.costs;
  const newCosts = newDataContext.costs;
  let preferredMaterial = "hm.insulation.material.styrofoam_graphite";
  let preferredMaterialHeat = 0.031;
  let element = newDataContext.houseData.roofElement;
  if (oldElement.material !== element.material) {
    preferredMaterial = element.material;
    preferredMaterialHeat = element.heatTransfer;
  }

  let materialChange = false;
  if (oldElement.material !== preferredMaterial) {
    materialChange = true;
  }
  const newItem = roofInsulationList.filter(
    item => item.id === preferredMaterial
  )[0];
  const oldItem = roofInsulationList.filter(
    item => item.id === oldElement.material
  )[0];
  let energy = heatMasterController.performCalculationForOptimizer(
    refDataContext
  );
  energy = energy.yearlyAggregatedConvertedData.enRoof * fromWattsToKwhRatio();

  const maxWidth = 50;
  let width = oldElement.width;

  let newEnergy = 0;
  let cost = 0;
  let energyDiff = 0;
  let newIrr = 0;
  let newElement = new RoofElement();

  let materialCost = oldItem.price;
  let newMaterialCost = newItem.price;
  let newWorkCost = materialChange ? newItem.work + 30 : newItem.work;

  if (refCosts[type + "_material_changed"]) {
    materialCost = refCosts[type + "_material"];
  }
  if (newCosts[type + "_material_changed"]) {
    newMaterialCost = newCosts[type + "_material"];
  }
  if (newCosts[type + "_work_changed"]) {
    newWorkCost = newCosts[type + "_work"];
  }

  newElement.material = oldElement.material;
  newElement.heatTransfer = oldElement.heatTransfer;
  newElement.width = oldElement.width;
  newElement.uValue = oldElement.uValue;
  newElement.u0 = element.u0;

  const surface = newHouseData.surfaceData.roofSurface;

  for (width; width <= maxWidth; width += 1) {
    const work =
      newHouseData.buildingType === "current"
        ? calculateWork(newWorkCost, surface, width)
        : 0;
    const material =
      newHouseData.buildingType === "current"
        ? calculateMaterial(newMaterialCost, surface, width)
        : calculateMaterialDifference(
            materialCost,
            newMaterialCost,
            surface,
            oldElement.width,
            width
          );
    cost = work + material;

    let el = new RoofElement();
    el.material = preferredMaterial;
    el.heatTransfer = preferredMaterialHeat;
    el.width = width;
    el.uValue = -1;
    el.u0 = newElement.u0;
    el = UValueBasedElementPreparer.prepare(el);

    newDataContext.houseData.roofElement = el;
    newEnergy = heatMasterController.performCalculationForOptimizer(
      newDataContext
    );
    newEnergy =
      newEnergy.yearlyAggregatedConvertedData.enRoof * fromWattsToKwhRatio();
    newIrr = IrrCounter(
      cost,
      energy,
      newEnergy,
      50,
      refPricePerKWh,
      refPricePerKWh,
      reports.irrPriceChange
    );

    if (energy - newEnergy > energyDiff && newIrr >= reports.irr) {
      energyDiff = energy - newEnergy;
      newElement.material = el.material;
      newElement.heatTransfer = el.heatTransfer;
      newElement.width = el.width;
      newElement.uValue = el.uValue;
      newElement.u0 = el.u0;
    }
  }

  if (cancel) {
    return {
      material: oldElement.material,
      heatTransfer: oldElement.heatTransfer,
      width: oldElement.width,
      uValue: oldElement.uValue,
      u0: oldElement.u0,
    };
  } else {
    return {
      material: newElement.material,
      heatTransfer: newElement.heatTransfer,
      width: newElement.width,
      uValue: newElement.uValue,
      u0: newElement.u0,
    };
  }
}

const calculateMaterial = (price, surface, width) => {
  return (price * surface * width) / 100;
};

const calculateWork = (work, surface) => {
  return work * surface;
};

const calculateMaterialDifference = (
  price,
  newPrice,
  surface,
  width,
  newWidth
) => {
  return (
    (newPrice * surface * newWidth) / 100 - (price * surface * width) / 100
  );
};

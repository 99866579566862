import { BaseVentilationEnergyCalculator } from "./BaseVentilationEnergyCalculator";
import { VentilationMethod } from "../../../config/dictionary/model/VentilationMethod";
import { EnergyCalcHelper } from "./EnergyCalcHelper";

export class VentilationEnergyCalculator extends BaseVentilationEnergyCalculator {
  calcEnergy = (energyData, contextData, monthInput) => {
    energyData = this.superCalcEnergy(energyData, contextData, monthInput);
    let enRecuperator = 0;
    let enHumidification = 0;
    let enDefrosting = 0;
    const ventData = contextData.houseData.ventilationData;
    if (
      ventData.ventilationMethodObject.type === VentilationMethod.MECHANICAL &&
      ventData.ventilationMethodObject.recuperator
    ) {
      const heatCoefficient = this.calcHeatCoefficient(
        ventData.ventilationMethodObject.efficiency
      );
      const tInEffective = this.calcEffectiveTIn(
        contextData,
        monthInput.tOut,
        monthInput.tGround
      );
      const airVolumePerHour =
        this.calcAirVolume(contextData, monthInput.tOut, tInEffective) * 3600;
      if (ventData.airTightness.value < 3) {
        enRecuperator = heatCoefficient * airVolumePerHour;
      }
      if (
        ventData.humidification &&
        !ventData.ventilationMethodObject.humidification
      ) {
        enHumidification = this.calcHumidificationEnergy(
          airVolumePerHour,
          contextData.houseData.tIn,
          monthInput.tOut
        );
      }
      if (!ventData.ventilationMethodObject.defrosting) {
        enDefrosting = this.calcDefrostingEnergy(
          airVolumePerHour,
          ventData.ventilationMethodObject.id,
          monthInput.tOut
        );
      }
    }
    energyData.enHumidification = Math.max(0, enHumidification);
    energyData.enRecuperator = enRecuperator + enDefrosting;

    return energyData;
  };

  applyCalculatedEnergy = (energy, energyData) => {
    energyData.enVent = energy;
    return energyData;
  };

  calcHeatCoefficient = efficiency => {
    return 1 - efficiency;
  };

  calcHumidificationEnergy = (airVolumePerHour, tIn, tOut) => {
    const _EVAPORATION = 2257;
    const humidityOut =
      ((this.calcHumidity(tOut) * 0.8) / (1 + 0.00366 * tOut)) * 0.8;
    const humidityIn =
      ((this.calcHumidity(tIn) * 0.8) / (1 + 0.00366 * tIn)) * 0.4;
    const diff = humidityIn - humidityOut;
    return ((diff * airVolumePerHour * _EVAPORATION) / 3600 / 1000) * 30 * 24;
  };

  calcDefrostingEnergy = (airVolumePerHour, ventDataId, tOut) => {
    let temp = 0;
    switch (ventDataId) {
      case "hm.recuperator-type.cross": {
        temp = 12;
        break;
      }
      case "hm.recuperator-type.countercurrent": {
        temp = 1;
        break;
      }
      case "hm.recuperator-type.countercurrent_entalpic": {
        temp = 5;
        break;
      }
      default:
    }
    if (tOut < 0) {
      return (airVolumePerHour * (tOut + temp) * 1005 * 1200) / 3600 / 1000;
    }
    return 0;
  };

  calcHumidity = temp => {
    return 6.1121 * Math.exp((17.502 * temp) / (240.97 + temp));
  };

  calcAirVolume = (contextData, tOut, tInEffective) => {
    const houseData = contextData.houseData;
    const surfaceData = houseData.surfaceData;
    const ventData = houseData.ventilationData;
    let airVolume = EnergyCalcHelper.calcASHRAEStandardVentilationVolume(
      surfaceData.totalSurface,
      contextData.houseData.personNumber
    );

    if (
      ventData.ventilationMethodObject.type === VentilationMethod.MECHANICAL
    ) {
      if (ventData.co2Sensor) {
        airVolume = (15.75 / 3600) * contextData.houseData.personNumber;
      }
    } else {
      if (
        (ventData.ventilationMethodObject.naturalRegulated && tOut >= 12) ||
        (!ventData.ventilationMethodObject.naturalRegulated &&
          ventData.ventilationMethodObject.type ===
            VentilationMethod.GRAVITATIONAL)
      ) {
        let tDiff = Math.max(0, houseData.tIn - tInEffective);
        airVolume = airVolume * Math.pow(tDiff / 8, 0.6);
      }
    }
    return (airVolume * ventData.patency) / 100;
  };

  calcEffectiveTIn = (contextData, tOut, tGround) => {
    if (
      contextData.houseData.ventilationData.ventilationMethodObject.type ===
        VentilationMethod.MECHANICAL &&
      contextData.houseData.ventilationData.gwcSet &&
      tOut < contextData.environmentalData.tAvg
    ) {
      return (tOut + tGround) / 2;
    }
    return tOut;
  };

  getType = () => 1;
}

import React from "react";
import "./charts.scss";
import cx from "classnames";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";
import getRawUnitName from "../../util/getRawUnitName";

const PieEnergyChartGroup = React.memo(props => {
  const { style, legend } = props;
  const unit = getRawUnitName(
    props.dataResults.conversionData.selectedUnit,
    props.dataResults.conversionData.currencyName
  );
  return (
    <div
      className={cx("chart pie-chart p-0 position-relative", {
        circle: props.data.length === 1,
      })}
      style={{ ...style }}
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            dataKey="value"
            data={props.data}
            fill="#8884d8"
            labelLine={false}
            label={CustomizedLabel}
          >
            {props.data.map((entry, index) => (
              <Cell
                className={entry.class}
                key={`cell-${index}`}
                fill={entry.fill}
              />
            ))}
          </Pie>
          <Tooltip
            content={<CustomTooltip unit={unit} />}
            position={{ x: 40, y: 3 }}
          />
          {legend && (
            <Legend
              layout={"vertical"}
              align={"right"}
              verticalAlign="middle"
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
});

const CustomTooltip = ({ active, payload, label, unit }) => {
  if (active) {
    return (
      <div className="recharts-default-tooltip">
        <p className="label mb-0">{`${payload[0].name} : ${payload[0].value} ${unit}`}</p>
      </div>
    );
  }

  return null;
};

const CustomizedLabel = ({ x, y, fill, stroke, value }) => {
  return value > 0 ? (
    <text x={x} y={y} dy={-4} fill={fill} fontSize={15} textAnchor="middle">
      {value}
    </text>
  ) : (
    ""
  );
};

PieEnergyChartGroup.displayName = "PieEnergyChartGroup";
export default PieEnergyChartGroup;

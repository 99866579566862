import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";

export class CompositeEnergyCalculator extends IMonthEnergyCalculator {
  energyCalculators = [];

  calcEnergy = (energyData, dataContext, monthInputData) => {
    for (let i = 0; i < this.energyCalculators.length; i++) {
      let energyCalculator = this.energyCalculators[i];

      energyData = energyCalculator.calcEnergy(
        energyData,
        dataContext,
        monthInputData
      );
    }
    return energyData;
  };

  addEnergyCalculator = energyCalculator => {
    this.energyCalculators.push(energyCalculator);
  };
}

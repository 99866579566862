import { IMonthEnergyCalculator } from "./IMonthEnergyCalculator";
import { EnergyCalcHelper } from "./EnergyCalcHelper";
import { HouseTypeHelper } from "../../../util/HouseTypeHelper";

export class WallEnergyCalculator extends IMonthEnergyCalculator {
  calcEnergy = (energyData, dataContext, monthInputData) => {
    const houseData = dataContext.houseData;
    const doorType = houseData.doorType;
    const doorSurface = this.calcDoorSurface(houseData.houseType, doorType);
    let roofAttic = 0;

    const hasRoof = HouseTypeHelper.hasRoof(houseData.houseType);
    if (hasRoof && houseData.roofData.attic) {
      roofAttic = EnergyCalcHelper.calcHeatTransfer(
        houseData.wallElement.uValue,
        houseData.surfaceData.atticWallSurface +
          houseData.surfaceData.roofWallSurface,
        houseData.tIn,
        monthInputData.tOut
      );
    }
    let nonInsulatedWallSurface = houseData.surfaceData.nonInsulatedWallSurface;
    let insulatedWallSurface = houseData.surfaceData.insulatedWallSurface;
    if (doorType.uValue > 0) {
      if (insulatedWallSurface >= 2) {
        insulatedWallSurface = insulatedWallSurface - doorSurface;
      } else {
        nonInsulatedWallSurface = nonInsulatedWallSurface - doorSurface;
      }
    }
    let enWallsInsulated = EnergyCalcHelper.calcHeatTransfer(
      houseData.wallElement.width > 0
        ? houseData.wallElement.uValue
        : houseData.wallElement.u0,
      insulatedWallSurface,
      houseData.tIn,
      monthInputData.tOut
    );
    let enWallsNonInsulated = EnergyCalcHelper.calcHeatTransfer(
      houseData.wallElement.u0,
      nonInsulatedWallSurface,
      houseData.tIn,
      monthInputData.tOut
    );

    if (doorType.uValue > 0) {
      const enDoor = EnergyCalcHelper.calcHeatTransfer(
        doorType.uValue,
        doorSurface,
        houseData.tIn,
        monthInputData.tOut
      );
      energyData.enDoor = enDoor;
    }
    if (enWallsNonInsulated < 0) {
      enWallsNonInsulated = 0;
    }
    if (enWallsInsulated < 0) {
      enWallsInsulated = 0;
    }
    energyData.enWalls = enWallsInsulated + enWallsNonInsulated + roofAttic;

    return energyData;
  };

  calcDoorSurface = (houseType, doorType) => 2;
}

export class CalcUtils {
  static NEW_MAX_POWER_THRESHOLD_DIFF = 0.8;

  static maxHeatingSourcePowerThresholdExceeded = (
    previousMaxPower,
    currentMaxPower
  ) => {
    return (
      currentMaxPower <
      CalcUtils.NEW_MAX_POWER_THRESHOLD_DIFF * previousMaxPower
    );
  };

  static maxValue = values => {
    let maxValue = Number.MIN_VALUE;
    for (let i = 0; i < values.length; i++) {
      if (values[i] > maxValue) {
        maxValue = values[i];
      }
    }
    return maxValue;
  };

  static minValue = temperatures => {
    let minValue = Number.MAX_VALUE;
    for (let i = 0; i < temperatures.length; i++) {
      if (temperatures[i] < minValue) {
        minValue = temperatures[i];
      }
    }
    return minValue;
  };

  static avgValue = temperatures => {
    let avgValue = 0;
    for (let i = 0; i < temperatures.length; i++) {
      avgValue += parseFloat(temperatures[i]);
    }

    avgValue = avgValue / temperatures.length;
    return Math.round(avgValue * 10) / 10;
  };

  static sum = temperatures => {
    let sum = 0;
    for (let i = 0; i < temperatures.length; i++) {
      sum += parseFloat(temperatures[i]);
    }
    return Math.round(sum * 10) / 10;
  };

  static floorZero = value => {
    return Math.max(value, 0);
  };
}

import { UPDATE_PHOTOVOLTAIC, UPDATE_PHOTOVOLTAIC_BY_ID } from "../actionTypes";

const initialState = {
  pv_type: 1,
  autoconsumption: 30,
  energy_network: 80,
  pv_power: 4.8,
  price: 0.62,
  energy_available: 222,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PHOTOVOLTAIC: {
      const data = action.payload.data;
      return {
        ...state,
        ...data,
      };
    }
    case UPDATE_PHOTOVOLTAIC_BY_ID: {
      const { id, value } = action.payload;
      return {
        ...state,
        [id]: value,
      };
    }

    default:
      return state;
  }
}

import { BaseComboObject } from "./BaseComboObject";

export class BuildingAge extends BaseComboObject {
  _uWalls;
  _uRoof;
  _uFloor;
  _uWindows;

  static items = [];

  static UP_TO_1984 = new BuildingAge("1", 1.4, 0.9, 0.5, 2.6);
  static BETWEEN_1984_1993 = new BuildingAge("2", 0.75, 0.5, 0.5, 2.6);
  static BETWEEN_1993_2004 = new BuildingAge("3", 0.65, 0.3, 0.5, 2);
  static BETWEEN_2004_2010 = new BuildingAge("4", 0.45, 0.3, 0.5, 1.6);
  static BETWEEN_2010_2013 = new BuildingAge("5", 0.35, 0.25, 0.5, 1.4);

  constructor(id, uWalls, uRoof, uFloor, uWindows) {
    super(id);
    this._uWalls = uWalls;
    this._uRoof = uRoof;
    this._uFloor = uFloor;
    this._uWindows = uWindows;
    BuildingAge.items.push(this);
  }

  static getBuildingAgeById = id => {
    for (let i = 0; i < BuildingAge.items.length; i++) {
      let buildingAge = BuildingAge.items[i];
      if (buildingAge.id === id) {
        return buildingAge;
      }
    }
    return null;
  };

  get uWalls() {
    return this._uWalls;
  }

  get uFloor() {
    return this._uFloor;
  }

  get uRoof() {
    return this._uRoof;
  }

  get uWindows() {
    return this._uWindows;
  }
}

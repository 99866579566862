import { HeatMasterResult } from "../HeatMasterResult";
import { PlotDataProvider } from "../plot/PlotDataProvider";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";

export class BasePlotPanel extends HeatMasterResult {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getComparingPlotArrayData = (refResultData, newResultData) => {
    let plotDataProvider = new PlotDataProvider();
    return plotDataProvider.getComparingPlotData(refResultData, newResultData);
  };

  getMonthlyPlotData = (resultData, monthList) => {
    const plotDataProvider = new PlotDataProvider();
    return plotDataProvider.getMonthlyPlotData(resultData);
  };

  getRawUnitName = (selectedUnit, currencyCode) => {
    if (ConversionUnits.isEmisionUnit(selectedUnit)) {
      return "CO2";
    } else if (ConversionUnits.isGJUnit(selectedUnit)) {
      return "GJ";
    } else if (ConversionUnits.isKiloWattsUnit(selectedUnit)) {
      return "kWh";
    } else if (ConversionUnits.isCostUnit(selectedUnit)) {
      return currencyCode;
    }
    return "W";
  };

  getLabelName = selectedUnit => {
    if (ConversionUnits.isEmisionUnit(selectedUnit)) {
      return "hm.label.emissions";
    } else if (ConversionUnits.isEnergyUnit(selectedUnit)) {
      return "hm.dropdown.energy.consumption";
    } else if (ConversionUnits.isCostUnit(selectedUnit)) {
      return "hm.dropdown.energy.cost";
    }
  };
}

export class ComparingProcessingResult {
  _refProcessingResult;
  _newProcessingResult;

  constructor(refProcessingResult, newProcessingResult) {
    this._refProcessingResult = refProcessingResult;
    this._newProcessingResult = newProcessingResult;
  }

  get refProcessingResult() {
    return this._refProcessingResult;
  }

  set refProcessingResult(value) {
    this._refProcessingResult = value;
  }

  get newProcessingResult() {
    return this._newProcessingResult;
  }

  set newProcessingResult(value) {
    this._newProcessingResult = value;
  }
}

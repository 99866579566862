export class CountryItemHelper {
  static getInitialConfigItemCode = () => "pl";
  static isInitialLocalCurrencySet = () => true;

  static getCountryElectricityPrice = (countryItem, ratio) => {
    return (
      countryItem.electricityPrice * countryItem.currencyExchangeRate * ratio
    );
  };

  static getInitialOrCachedRegionItem = (cached, cachedData, configItems) => {
    let itemCode = CountryItemHelper.getInitialConfigItemCode();
    if (cached) {
      itemCode = this.getConfigItemCode(cachedData);
    }
    return CountryItemHelper.getRegionByCodeFromCountryList(
      configItems,
      itemCode
    );
  };

  static getInitialOrCachedCountryItem(cached, cachedData, configItems) {
    let itemCode = this.getInitialConfigItemCode();
    if (cached) {
      itemCode = this.getConfigItemCountryCode(cachedData);
    }
    return CountryItemHelper.getCountryByCode(configItems, itemCode);
  }

  static getConfigItemCode = cachedData => {
    if (cachedData != null && cachedData.selectedCountryRegion != null) {
      return cachedData.selectedCountryRegion;
    }
    return this.getInitialConfigItemCode();
  };

  static getConfigItemCountryCode = cachedData => {
    if (cachedData != null && cachedData.countryCode != null) {
      return cachedData.countryCode;
    }
    return this.getInitialConfigItemCode();
  };

  static getRegionList = countryList => {
    let result = [];
    if (countryList != null && countryList.length > 0) {
      for (let i = 0; i < countryList.length; i++) {
        let countryItem = countryList[i];
        if (countryItem.regions.length > 0) {
          for (let j = 0; j < countryItem.regions.length; j++) {
            let regionItem = countryItem.regions[i];
            result.push(regionItem);
          }
        }
      }
    }
    return result;
  };

  static getCountryStandardList = countryList => {
    let result = [];
    if (countryList != null && countryList.length > 0) {
      for (let i = 0; i < countryList.length; i++) {
        let countryItem = countryList[i];
        if (countryItem.standardTypeConfiguration.length > 0) {
          for (
            let j = 0;
            j < countryItem.standardTypeConfiguration.length;
            j++
          ) {
            let item = countryItem.standardTypeConfiguration[i];
            result.push(item);
          }
        }
      }
    }
    return result;
  };

  static getCountryByCode(configItems, code) {
    let countryItem = null;
    for (let i = 0; i < configItems.length; i++) {
      let configItem = configItems[i];
      if (configItem.code === code) {
        countryItem = configItem;
        break;
      }
    }
    return countryItem;
  }

  static getRegionByCode = (countryItem, code) => {
    let regionItemRes = null;
    for (let i = 0; i < countryItem.regions.length; i++) {
      let regionItem = countryItem.regions[i];
      if (regionItem.code === code) {
        return regionItem;
      }
    }
    return regionItemRes;
  };

  static getRegionByCodeFromCountryList = (countryList, code) => {
    for (let i = 0; i < countryList.length; i++) {
      let configItem = countryList[i];

      let regionItem = CountryItemHelper.getRegionByCode(configItem, code);
      if (regionItem != null) {
        return regionItem;
      }
    }
    return null;
  };

  static getCountryByCountryRegionCode = (configItems, countryRegionCode) => {
    let countryItem = CountryItemHelper.getCountryByCode(
      configItems,
      countryRegionCode
    );
    if (countryItem != null) {
      return countryItem;
    }
    let regionItem = CountryItemHelper.getRegionByCodeFromCountryList(
      configItems,
      countryRegionCode
    );
    return regionItem != null ? regionItem.country : null;
  };
}

export class ConvertedResult {
  _enWalls = 0;
  _enDoor = 0;
  _enBasement = 0;
  _enRoof = 0;
  _enFloor = 0;
  _enFoundations = 0;
  _enWindows = 0;
  _enVent = 0;
  _enRecuperator = 0;
  _enHumidification = 0;
  _enAir = 0;
  _enTightness = 0;
  _enWarmWater = 0;
  _enPersonGain = 0;
  _enLivingNeeds = 0;
  _enWarmWaterGain = 0;
  _enSolGain = 0;
  _enCollectorSolarGain = 0;
  _enPhotovoltaicGain = 0;
  _enPhotovoltaicGainNormalized = 0;
  _enElectricityGain = 0;
  _enElectricDevicesEnergyLoss = 0;
  _enElectricDevicesEnergyLossNormalized = 0;
  _enElectricVentilationEnergyLoss = 0;
  _enWaterElectricDevicesEnergyLoss = 0;
  _electricDevicesEnergySum = 0;

  _heatingSourceLoss = 0;
  _warmWaterHeatingSourceLoss = 0;
  _enHouseBalance = 0.0;

  _enWarmWaterNormalized = 0;
  _enWarmWaterNormalizedHeatingSourceLoss = 0;

  _enSolGainAggregated = 0;
  _enHeatingProductAggregated = 0;

  _airConditioningLoss = 0;
  _heatingDeviceEnergy = 0;
  _heatingWaterDeviceEnergy = 0;
  _airConditioningDeviceEnergy = 0;

  toString = () =>
    "enWalls: " +
    this._enWalls +
    " enFloor: " +
    this._enFloor +
    "\n" +
    "enRoof " +
    this._enRoof +
    "enWindows: " +
    this._enWindows +
    " enVent: " +
    this._enVent +
    "\n" +
    "enAir: " +
    this._enAir +
    " enPersonGain: " +
    this._enPersonGain +
    "\n" +
    "enSolGain: " +
    this._enSolGain +
    " enElectricityGain: " +
    this._enElectricityGain;

  getBalanceValue = () => {
    return (
      Math.max(this.getHeatingBalance(), 0) +
      this.getWarmWaterBalance() +
      this.getElectricDevices()
    );
  };

  getElectricDevices = () => {
    return (
      this._airConditioningDeviceEnergy +
      this._enRecuperator +
      this._enHumidification +
      this._enElectricVentilationEnergyLoss +
      this._enElectricDevicesEnergyLossNormalized +
      this._enWaterElectricDevicesEnergyLoss
    );
  };

  getEfficiencyAwareBalanceValue = () => {
    return Math.max(
      0,
      this.getBalanceValue() +
        this._heatingSourceLoss +
        this._warmWaterHeatingSourceLoss
    );
  };

  getPhotovoltaicBalanceValue = () => {
    return this._electricDevicesEnergySum - this._enPhotovoltaicGainNormalized;
  };

  getEfficiencyAwareHeatingBalanceValue = () => {
    const heatingBalance = Math.max(0, this.getHeatingBalance(), 0);
    return Math.max(0, heatingBalance + this._heatingSourceLoss);
  };

  getEfficiencyAwareWarmWaterBalanceValue = () => {
    return Math.max(
      0,
      this.getWarmWaterBalance() + this._warmWaterHeatingSourceLoss
    );
  };

  getEfficiencyAndEnergyClassAwareWarmWaterBalanceValue = () => {
    return Math.max(
      0,
      this.getWarmWaterNormalizedBalance() +
        this._enWarmWaterNormalizedHeatingSourceLoss
    );
  };

  getHeatingBalance = () => {
    return this.getHeatingLosses() - this.getHeatingGains();
  };

  getACBalance = () => {
    return Math.min(0, this.getEnLossesForAC() - this.getHeatingGains());
  };

  getHeatingBalanceWithoutAiring = () =>
    this.getHeatingLossesWithoutAiring() - this.getHeatingGains();

  getAllEnLosses = () => {
    return (
      this.getEnLosses() +
      this.getEnHeatingSourceLoss() +
      this.getElectricDevices()
    );
  };

  getEnLosses = () => {
    return (
      Math.max(this._enWalls, 0) +
      Math.max(this._enDoor, 0) +
      Math.max(this._enRoof, 0) +
      Math.max(this._enFloor, 0) +
      Math.max(0, this._enFoundations) +
      Math.max(this._enWindows, 0) +
      Math.max(this._enVent, 0) +
      Math.max(this._enAir, 0) +
      Math.max(this._enTightness, 0) +
      Math.max(this._enWarmWater, 0)
    );
  };

  getEnLossesForArrows = () => {
    return (
      Math.max(this._enWalls, 0) +
      Math.max(this._enDoor, 0) +
      Math.max(this._enRoof, 0) +
      Math.max(this._enFloor, 0) +
      Math.max(0, this._enFoundations) +
      Math.max(this._enWindows, 0) +
      Math.max(this._enVent, 0) +
      Math.max(this._enAir, 0) +
      Math.max(this._enTightness, 0) +
      Math.max(this._enWarmWater, 0) +
      Math.max(this._enRecuperator, 0) +
      Math.max(this._enHumidification, 0)
    );
  };

  getEnLossesForAC = () =>
    this._enWalls +
    this._enDoor +
    this._enRoof +
    this._enFloor +
    this._enFoundations +
    this._enWindows +
    this._enVent +
    this._enAir +
    this._enTightness +
    this._enWarmWater;

  getHeatingLosses = () =>
    Math.max(this._enWalls, 0) +
    Math.max(this._enDoor, 0) +
    Math.max(this._enRoof, 0) +
    Math.max(this._enFloor, 0) +
    Math.max(0, this._enFoundations) +
    Math.max(this._enWindows, 0) +
    Math.max(this._enVent, 0) +
    Math.max(this._enAir, 0) +
    Math.max(this._enTightness, 0);

  getHeatingGains = () => {
    return (
      this._enElectricityGain +
      this._enPersonGain +
      this._enSolGain +
      this._enWarmWaterGain
    );
  };

  getHeatingLossesWithoutAiring() {
    return this.getHeatingLosses() - Math.max(this._enAir, 0);
  }

  getWarmWaterBalance() {
    return Math.max(0, this._enWarmWater - this._enCollectorSolarGain);
  }

  getWarmWaterNormalizedBalance() {
    return Math.max(
      0,
      this._enWarmWaterNormalized - this._enCollectorSolarGain
    );
  }

  getEnGains = () =>
    this._enElectricityGain +
    this._enPersonGain +
    this._enSolGain +
    this._enWarmWaterGain +
    Math.max(Math.min(this._enCollectorSolarGain, this._enWarmWater), 0);

  getEnHeatingSourceLoss = () => {
    return Math.max(
      0,
      this._heatingSourceLoss + this._warmWaterHeatingSourceLoss
    );
  };

  getEnHeatingSourceGains = () => {
    return Math.max(
      0,
      -(this._heatingSourceLoss + this._warmWaterHeatingSourceLoss)
    );
  };

  getYearlyAggregatedGains = () => {
    return (
      this._enSolGainAggregated +
      this._enHeatingProductAggregated +
      Math.min(this._enCollectorSolarGain, this._enWarmWater)
    );
  };

  get enBasement() {
    return this._enBasement;
  }

  set enBasement(value) {
    this._enBasement = value;
  }

  get enWalls() {
    return this._enWalls;
  }

  set enWalls(value) {
    this._enWalls = value;
  }

  get enDoor() {
    return this._enDoor;
  }

  set enDoor(value) {
    this._enDoor = value;
  }

  get enRoof() {
    return this._enRoof;
  }

  set enRoof(value) {
    this._enRoof = value;
  }

  get enFloor() {
    return this._enFloor;
  }

  set enFloor(value) {
    this._enFloor = value;
  }

  get enFoundations() {
    return this._enFoundations;
  }

  set enFoundations(value) {
    this._enFoundations = value;
  }

  get enWindows() {
    return this._enWindows;
  }

  set enWindows(value) {
    this._enWindows = value;
  }

  get enVent() {
    return this._enVent;
  }

  set enVent(value) {
    this._enVent = value;
  }

  get enRecuperator() {
    return this._enRecuperator;
  }

  set enRecuperator(value) {
    this._enRecuperator = value;
  }

  get enHumidification() {
    return this._enHumidification;
  }

  set enHumidification(value) {
    this._enHumidification = value;
  }

  get enAir() {
    return this._enAir;
  }

  set enAir(value) {
    this._enAir = value;
  }

  get enTightness() {
    return this._enTightness;
  }

  set enTightness(value) {
    this._enTightness = value;
  }

  get enWarmWater() {
    return this._enWarmWater;
  }

  set enWarmWater(value) {
    this._enWarmWater = value;
  }

  get enPersonGain() {
    return this._enPersonGain;
  }

  set enPersonGain(value) {
    this._enPersonGain = value;
  }

  get enWarmWaterGain() {
    return this._enWarmWaterGain;
  }

  set enWarmWaterGain(value) {
    this._enWarmWaterGain = value;
  }

  get enSolGain() {
    return this._enSolGain;
  }

  set enSolGain(value) {
    this._enSolGain = value;
  }

  get enCollectorSolarGain() {
    return this._enCollectorSolarGain;
  }

  set enCollectorSolarGain(value) {
    this._enCollectorSolarGain = value;
  }

  get enPhotovoltaicGain() {
    return this._enPhotovoltaicGain;
  }

  set enPhotovoltaicGain(value) {
    this._enPhotovoltaicGain = value;
  }

  get enPhotovoltaicGainNormalized() {
    return this._enPhotovoltaicGainNormalized;
  }

  set enPhotovoltaicGainNormalized(value) {
    this._enPhotovoltaicGainNormalized = value;
  }

  get enElectricityGain() {
    return this._enElectricityGain;
  }

  set enElectricityGain(value) {
    this._enElectricityGain = value;
  }

  get heatingSourceLoss() {
    return this._heatingSourceLoss;
  }

  set heatingSourceLoss(value) {
    this._heatingSourceLoss = value;
  }

  get warmWaterHeatingSourceLoss() {
    return this._warmWaterHeatingSourceLoss;
  }

  set warmWaterHeatingSourceLoss(value) {
    this._warmWaterHeatingSourceLoss = value;
  }

  get enHouseBalance() {
    return this._enHouseBalance;
  }

  set enHouseBalance(value) {
    this._enHouseBalance = value;
  }

  get enWarmWaterNormalized() {
    return this._enWarmWaterNormalized;
  }

  set enWarmWaterNormalized(value) {
    this._enWarmWaterNormalized = value;
  }

  get enWarmWaterNormalizedHeatingSourceLoss() {
    return this._enWarmWaterNormalizedHeatingSourceLoss;
  }

  set enWarmWaterNormalizedHeatingSourceLoss(value) {
    this._enWarmWaterNormalizedHeatingSourceLoss = value;
  }

  get enHeatingProductAggregated() {
    return this._enHeatingProductAggregated;
  }

  set enHeatingProductAggregated(value) {
    this._enHeatingProductAggregated = value;
  }

  get airConditioningLoss() {
    return this._airConditioningLoss;
  }

  set airConditioningLoss(value) {
    this._airConditioningLoss = value;
  }

  get heatingDeviceEnergy() {
    return this._heatingDeviceEnergy;
  }

  set heatingDeviceEnergy(value) {
    this._heatingDeviceEnergy = value;
  }

  get heatingWaterDeviceEnergy() {
    return this._heatingWaterDeviceEnergy;
  }

  set heatingWaterDeviceEnergy(value) {
    this._heatingWaterDeviceEnergy = value;
  }

  get airConditioningDeviceEnergy() {
    return this._airConditioningDeviceEnergy;
  }

  set airConditioningDeviceEnergy(value) {
    this._airConditioningDeviceEnergy = value;
  }

  get enLivingNeeds() {
    return this._enLivingNeeds;
  }

  set enLivingNeeds(value) {
    this._enLivingNeeds = value;
  }

  get enElectricDevicesEnergyLoss() {
    return this._enElectricDevicesEnergyLoss;
  }

  set enElectricDevicesEnergyLoss(value) {
    this._enElectricDevicesEnergyLoss = value;
  }

  get enElectricDevicesEnergyLossNormalized() {
    return this._enElectricDevicesEnergyLossNormalized;
  }

  set enElectricDevicesEnergyLossNormalized(value) {
    this._enElectricDevicesEnergyLossNormalized = value;
  }

  get enElectricVentilationEnergyLoss() {
    return this._enElectricVentilationEnergyLoss;
  }

  set enElectricVentilationEnergyLoss(value) {
    this._enElectricVentilationEnergyLoss = value;
  }

  get enWaterElectricDevicesEnergyLoss() {
    return this._enWaterElectricDevicesEnergyLoss;
  }

  set enWaterElectricDevicesEnergyLoss(value) {
    this._enWaterElectricDevicesEnergyLoss = value;
  }

  get electricDevicesEnergySum() {
    return this._electricDevicesEnergySum;
  }

  set electricDevicesEnergySum(value) {
    this._electricDevicesEnergySum = value;
  }
}

import React from "react";
import "./PlotPanel.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import { BasePlotPanel } from "./BasePlotPanel";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";
import connect from "react-redux/es/connect/connect";
import cx from "classnames";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComparingProcessingResult } from "../../core/state/ComparingProcessingResult";
import { CalcUtils } from "../../core/calc/utils/CalcUtils";
import { CustomizedAxisTick } from "./AggregatedEnergyChart";

class ComparingPlotPanel extends BasePlotPanel {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  plotDataProvider = [];
  lineSeriesDataProvider1 = [];
  lineSeriesDataProvider2 = [];
  refStateFormattedValue;
  refStateValue = 0;
  newStateFormattedValue;
  newStateValue = 0;
  refMaxPower;
  newMaxPower;
  showMaxPowerWarningInfo = false;

  warmWaterIncluded = false;
  heatingSourceLossIncluded = false;
  heatingSourceGainsIncluded = false;

  refProcessingResult;
  newProcessingResult;

  refHeatingSourceName;
  newHeatingSourceName;

  comparingResultCalculated = (comparingResult, month) => {
    this.refProcessingResult = comparingResult.refProcessingResult;
    this.newProcessingResult = comparingResult.newProcessingResult;

    this.refHeatingSourceName = this.refProcessingResult.dataContext.heatingData.heatingType.name;
    this.newHeatingSourceName = this.newProcessingResult.dataContext.heatingData.heatingType.name;

    this.doDrawComparingPlot(
      new ComparingProcessingResult(
        this.refProcessingResult,
        this.newProcessingResult
      )
    );
  };

  drawComparingPlot = calculationState => {
    this.refProcessingResult = calculationState.refProcessingResult;
    this.newProcessingResult = calculationState.newProcessingResult;
    this.refHeatingSourceName = this.refProcessingResult.dataContext.heatingData.heatingType.name;
    this.newHeatingSourceName = this.newProcessingResult.dataContext.heatingData.heatingType.name;
    this.doDrawComparingPlot(calculationState);
  };

  doDrawComparingPlot = calculationState => {
    let conversionData = calculationState.refProcessingResult.conversionData;
    let refDataContext = calculationState.refProcessingResult.dataContext;
    let newDataContext = calculationState.newProcessingResult.dataContext;

    this.refMaxPower =
      calculationState.refProcessingResult.wattsEnergyResult
        .heatingSourceMaxPower / 1000;
    this.newMaxPower =
      calculationState.newProcessingResult.wattsEnergyResult
        .heatingSourceMaxPower / 1000;

    this.setMaxPowerWarningInfoVisibility(
      this.refMaxPower,
      this.newMaxPower,
      refDataContext,
      newDataContext
    );

    this.currencyCode =
      calculationState.refProcessingResult.dataContext.currencyLocaleCode;
    this.rawUnitName = this.getRawUnitName(
      conversionData.selectedUnit,
      this.currencyCode
    );

    let plotObject = this.getComparingPlotArrayData(
      calculationState.refProcessingResult,
      calculationState.newProcessingResult
    );
    let plotColumnData = plotObject.columnData;
    this.plotDataProvider = new Array(plotColumnData);
    this.lineSeriesDataProvider1 = new Array(plotObject.lineData1);
    this.lineSeriesDataProvider2 = new Array(plotObject.lineData2);

    let refSplitColumnData = plotColumnData[1].columnData;
    let newSplitColumnData = plotColumnData[2].columnData;

    let newHeatingSourceGainsIncluded =
      refSplitColumnData.enHeatingSourceGain > 0 ||
      newSplitColumnData.enHeatingSourceGain > 0;
    let newWarmWaterIncluded =
      refDataContext.includeWarmWater || newDataContext.includeWarmWater;

    if (this.warmWaterIncluded !== newWarmWaterIncluded) {
      this.warmWaterIncluded = newWarmWaterIncluded;
    }
    if (newHeatingSourceGainsIncluded !== this.heatingSourceGainsIncluded) {
      this.heatingSourceLossIncluded = newHeatingSourceGainsIncluded;
    }

    this.refStateValue = this.lineSeriesDataProvider1[0].netLoss;
    this.refStateFormattedValue = this.formatHMValue(this.refStateValue);
    this.newStateValue = this.lineSeriesDataProvider2[0].netLoss;
    this.newStateFormattedValue = this.formatHMValue(this.newStateValue);
  };

  horizontalAxisLabelFunction = (labelValue, previousLabelValue, axis) => {
    if (labelValue === "1") {
    }
    if (labelValue === "2") {
    }
    return "";
  };

  setMaxPowerWarningInfoVisibility = () => {
    if (
      !isNaN(
        this.props.refProcessingResult.wattsEnergyResult.heatingSourceMaxPower /
          1000
      ) &&
      !isNaN(
        this.props.newProcessingResult.wattsEnergyResult.heatingSourceMaxPower /
          1000
      )
    ) {
      if (
        this.props.refProcessingResult.dataContext.heatingData.heatingType ===
        this.props.newProcessingResult.dataContext.heatingData.heatingType
      ) {
        return CalcUtils.maxHeatingSourcePowerThresholdExceeded(
          this.props.refProcessingResult.wattsEnergyResult
            .heatingSourceMaxPower / 1000,
          this.props.newProcessingResult.wattsEnergyResult
            .heatingSourceMaxPower / 1000
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  getAdjustedEnergyUnitName = (value, selectedUnit) => {
    if (ConversionUnits.isGJUnit(selectedUnit)) {
      let prefixJ = "G";
      let rJ = value / 1000000;
      rJ = Math.pow(10, rJ);
      if (rJ >= 10) {
        prefixJ = "T";
      }
      if (rJ >= 1000) {
        prefixJ = "P";
      }
      return `${prefixJ}J`;
    } else if (ConversionUnits.isEmisionUnit(selectedUnit)) {
      return "CO2";
    } else if (ConversionUnits.isCostUnit(selectedUnit)) {
      return "CO2";
    } else {
      let prefix = "k";
      let r = value / 1000000;
      if (r >= 1) {
        prefix = "M";
      }
      if (r >= 1000) {
        prefix = "G";
      }
      if (r > 1000000) {
        prefix = "T";
      }
      return `${prefix}Wh`;
    }
  };

  getRefDataForChart = () => {
    let values = this.getMonthlyPlotData(
      this.props.refProcessingResult,
      this.props.month
    ).map(value => {
      return {
        ...value,
        name:
          this.props.intl.formatMessage({
            id: "hm.dropdown.month_" + (value.name + 1),
          }) +
          " " +
          (value.electricDevices +
            value.heatingDeviceEnergy +
            value.heatingWaterDeviceEnergy +
            value.airConditioningDeviceEnergy +
            value.enPhotovoltaicGain +
            value.enCollectorsGain) +
          " " +
          this.getRawUnitName(
            this.props.refProcessingResult.conversionData.selectedUnit,
            this.props.refProcessingResult.conversionData.currencyName
          ),
        sum:
          value.electricDevices +
          value.heatingDeviceEnergy +
          value.heatingWaterDeviceEnergy +
          value.airConditioningDeviceEnergy +
          value.enPhotovoltaicGain +
          value.enCollectorsGain,
      };
    });

    return values;
  };

  getNewDataForChart = () => {
    let values = this.getMonthlyPlotData(
      this.props.newProcessingResult,
      this.props.month
    ).map(value => {
      return {
        ...value,
        name:
          this.props.intl.formatMessage({
            id: "hm.dropdown.month_" + (value.name + 1),
          }) +
          " " +
          (value.electricDevices +
            value.heatingDeviceEnergy +
            value.heatingWaterDeviceEnergy +
            value.airConditioningDeviceEnergy +
            value.enPhotovoltaicGain +
            value.enCollectorsGain) +
          " " +
          this.getRawUnitName(
            this.props.newProcessingResult.conversionData.selectedUnit,
            this.props.newProcessingResult.conversionData.currencyName
          ),
        sum:
          value.electricDevices +
          value.heatingDeviceEnergy +
          value.heatingWaterDeviceEnergy +
          value.airConditioningDeviceEnergy +
          value.enPhotovoltaicGain +
          value.enCollectorsGain,
      };
    });

    return values;
  };

  getDataForChart = () => {
    let values = this.getComparingPlotArrayData(
      this.props.refProcessingResult,
      this.props.newProcessingResult
    );
    values[0].name = this.props.intl.formatMessage({
      id: "hm.state.reference",
    });
    values[1].name = this.props.intl.formatMessage({ id: "hm.state.new" });

    return values;
  };

  render() {
    const unit = this.getRawUnitName(
      this.props.refProcessingResult.conversionData.selectedUnit,
      this.props.refProcessingResult.conversionData.currencyName
    );
    const refValues = this.getRefDataForChart();
    const newValues = this.getNewDataForChart();
    const refMax = Math.max.apply(
      Math,
      refValues.map(function(o) {
        return o.sum;
      })
    );
    const newMax = Math.max.apply(
      Math,
      newValues.map(function(o) {
        return o.sum;
      })
    );
    const max = refMax > newMax ? refMax : newMax;
    return (
      <div id={"plotContainer"}>
        <FontAwesomeIcon
          icon="times"
          onClick={e => this.props.toggleComparingPlotPanel(false)}
        />
        <FormattedMessage id={"hm.header.comparing-plot"} tagName={"h1"} />
        <div className={"d-flex flex-wrap"}>
          <div className={"chart col-12"}>
            <FormattedMessage id={"hm.state.reference"} tagName={"h2"}>
              {message => (
                <h2>
                  {message}{" "}
                  <span className={"text-medium"}>
                    <FormattedMessage
                      id={
                        this.props.refProcessingResult.dataContext.heatingData
                          .heatingSource
                      }
                    >
                      {message => <span>{message}, </span>}
                    </FormattedMessage>
                    {this.props.refProcessingResult.dataContext.heatingData
                      .heatingType && (
                      <FormattedMessage
                        id={
                          this.props.refProcessingResult.dataContext.heatingData
                            .heatingType
                        }
                      >
                        {message => <span>{message}, </span>}
                      </FormattedMessage>
                    )}
                    <FormattedMessage
                      id={"hm.label.heating.source.max-power"}
                      tagName={"span"}
                    />
                    &nbsp;
                    {this.formatHMValue(
                      this.props.refProcessingResult.wattsEnergyResult
                        .heatingSourceMaxPower / 1000,
                      1
                    )}{" "}
                    kW
                  </span>
                </h2>
              )}
            </FormattedMessage>
            <ResponsiveContainer>
              <ComposedChart
                margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                width={900}
                height={500}
                data={refValues}
                stackOffset="sign"
                maxBarSize={50}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tick={<CustomizedAxisTick unit={unit} />}
                ></XAxis>
                <YAxis
                  interval={0}
                  unit={unit}
                  type="number"
                  domain={["auto", Math.ceil(max / 100) * 100 + 100]}
                />
                <Tooltip />
                <ReferenceLine y={0} stroke="#000" />
                <Legend
                  layout={"vertical"}
                  align={"right"}
                  verticalAlign="top"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.technicalSystems",
                  })}
                  dataKey="_electricDevices"
                  stackId="a"
                  fill="#888888"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.warm_water",
                  })}
                  dataKey="_heatingWaterDeviceEnergy"
                  stackId="a"
                  fill="#ff4b4b"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.heating",
                  })}
                  dataKey="_heatingDeviceEnergy"
                  stackId="a"
                  fill="#ec6d11"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.cooling",
                  })}
                  dataKey="_airConditioningDeviceEnergy"
                  stackId="a"
                  fill="#0088FE"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.photovoltaic",
                  })}
                  dataKey="_enPhotovoltaicGain"
                  stackId="a"
                  fill="#ffe400"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.solar_collectors",
                  })}
                  dataKey="_enCollectorsGain"
                  stackId="a"
                  fill="#ff9797"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div className={"chart col-12"}>
            <FormattedMessage id={"hm.state.new"} tagName={"h2"}>
              {message => (
                <h2>
                  {message}{" "}
                  <span className={"text-medium"}>
                    <FormattedMessage
                      id={
                        this.props.newProcessingResult.dataContext.heatingData
                          .heatingSource
                      }
                    >
                      {message => <span>{message}, </span>}
                    </FormattedMessage>
                    {this.props.newProcessingResult.dataContext.heatingData
                      .heatingType && (
                      <FormattedMessage
                        id={
                          this.props.newProcessingResult.dataContext.heatingData
                            .heatingType
                        }
                      >
                        {message => <span>{message}, </span>}
                      </FormattedMessage>
                    )}
                    <FormattedMessage
                      id={"hm.label.heating.source.max-power"}
                      tagName={"span"}
                    />
                    &nbsp;
                    {this.formatHMValue(
                      this.props.newProcessingResult.wattsEnergyResult
                        .heatingSourceMaxPower / 1000,
                      1
                    )}{" "}
                    kW
                  </span>
                </h2>
              )}
            </FormattedMessage>
            <ResponsiveContainer>
              <ComposedChart
                margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                width={900}
                height={500}
                data={newValues}
                stackOffset="sign"
                maxBarSize={50}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tick={<CustomizedAxisTick unit={unit} />}
                ></XAxis>
                <YAxis
                  interval={0}
                  unit={unit}
                  type="number"
                  domain={["auto", Math.ceil(max / 100) * 100 + 100]}
                />
                <Tooltip />
                <ReferenceLine y={0} stroke="#000" />
                <Legend
                  layout={"vertical"}
                  align={"right"}
                  verticalAlign="top"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.technicalSystems",
                  })}
                  dataKey="_electricDevices"
                  stackId="a"
                  fill="#888888"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.warm_water",
                  })}
                  dataKey="_heatingWaterDeviceEnergy"
                  stackId="a"
                  fill="#ff4b4b"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.heating",
                  })}
                  dataKey="_heatingDeviceEnergy"
                  stackId="a"
                  fill="#ec6d11"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.cooling",
                  })}
                  dataKey="_airConditioningDeviceEnergy"
                  stackId="a"
                  fill="#0088FE"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.photovoltaic",
                  })}
                  dataKey="_enPhotovoltaicGain"
                  stackId="a"
                  fill="#ffe400"
                />
                <Bar
                  unit={` ${unit}`}
                  name={this.props.intl.formatMessage({
                    id: "hm.label.solar_collectors",
                  })}
                  dataKey="_enCollectorsGain"
                  stackId="a"
                  fill="#ff9797"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div
          className={cx({ "d-none": !this.setMaxPowerWarningInfoVisibility() })}
        >
          <FormattedMessage
            id={"hm.info.max_power.threshold_exceeded"}
            tagName={"h3"}
            className={"text-warning"}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {};
};

export default injectIntl(connect(mapStateToProps)(ComparingPlotPanel));

import React from "react";
import { connect } from "react-redux";
import {
  updateCosts,
  updatePersons,
  updatePhotovoltaic,
  updatePhotovoltaicById,
} from "../../redux/actions";
import {
  getPV,
  getHouse,
  getConversion,
  getPersons,
  getInsolation,
} from "../../redux/selectors";

import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";

import { DropDownList } from "../Helpers/DropDownList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";
import { HeatMaster } from "../HeatMaster";
import NumericInput from "react-numeric-input";
import { Directions } from "../../constants/Directions";
import { OptimizerResultsState } from "../../core/optimizer/OptimizerResultsState";
import { Thermoderm } from "../Helpers/Thermoderm";
import { FormatNumericInput } from "../Helpers/FormatNumericInput";
import { PVOptimizer } from "../../core/optimizer/selectors/PVOptimizer";
import { getPvEnergyCalculated } from "../../util/getPvEnergyCalculated";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";
import { Popup } from "../Helpers/Popup";
class PhotovoltaicPopup extends HeatMaster {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      showBaloon: window.innerWidth >= 1024,
    };
  }

  handleClick = () => {
    this.setState({ show: !this.state.show });
  };

  handleBaloonClick = () => {
    this.setState({ showBaloon: !this.state.showBaloon });
  };

  installationList = [
    {
      name: "hm.label.photovoltaic.installation.none",
      data: { id: 1 },
    },
    {
      name: "hm.label.photovoltaic.installation.roof",
      data: { id: 2 },
    },
    {
      name: "hm.label.photovoltaic.installation.solar_tracker",
      data: { id: 3 },
    },
  ];

  thermalBridgesList = {};

  livingNeedsChanged = e => {
    this.props
      .dispatch(
        updatePersons({
          livingNeeds: e,
          livingNeedsChanged: true,
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  pvChanged = (id, e) => {
    this.props
      .dispatch(
        updatePhotovoltaicById({
          id: id,
          value: e,
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  pvTypeChanged = e => {
    this.props
      .dispatch(
        updatePhotovoltaicById({
          id: "pv_type",
          value: e,
        })
      )
      .then(() => {
        if (e > 1) {
          const dataContext = this.props.dataResults.dataContext;
          const data = PVOptimizer(
            "pvType",
            false,
            dataContext,
            this.props.heatMasterController,
            dataContext.houseData.pvData.pv_power,
            e,
            5,
            0,
            80
          );
          this.props.dispatch(updatePhotovoltaic({ data: data })).then(() => {
            this.props.writeCache();
          });
        } else {
          this.props.writeCache();
        }
        this.props
          .dispatch(
            updateCosts({
              pvType_material: undefined,
              pvType_work: undefined,
            })
          )
          .then(() => {
            this.props.writeCache();
          });
      });
  };

  pvPowerChanged = e => {
    this.props
      .dispatch(
        updatePhotovoltaic({
          data: {
            pv_power: e,
            energy_network: e > 10 ? 70 : 80,
          },
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  toggleFirstCalculation = () => {
    this.props.toggleFirstCalculation("firstCalculationStep3");
    this.props.toggleFirstCalculation("firstCalculation");
    document
      .querySelectorAll("#PhotovoltaicPopup1 .collapse")[0]
      .classList.remove("show");
    document
      .querySelectorAll("#PhotovoltaicPopup2 .collapse")[0]
      .classList.remove("show");
    document
      .querySelectorAll("#HousePopup1 .collapse")[0]
      .classList.add("show");
    document
      .querySelectorAll("#HousePopup2 .collapse")[0]
      .classList.add("show");
    this.props.toggleBackdrop();
  };

  render() {
    let worse;
    let better;
    if (this.props.invalidDataOptimizer) {
      worse = this.props.dataOptimizer.map(
        item => item.state === OptimizerResultsState.WORSE
      );
      worse = worse.includes(true);
      better = this.props.dataOptimizer.map(
        item => item.state === OptimizerResultsState.BETTER
      );
      better = better.includes(true);
    }

    const pvCalc = getPvEnergyCalculated(
      this.props.dataResults,
      this.props.persons.livingNeedsChanged,
      this.props.persons.livingNeeds
    );
    const livingNeeds = pvCalc.enLivingNeeds;
    const costInEuro = ConversionUnits.isEuroCostUnit(
      this.props.conversion.selectedUnit
    );
    const costUnit = ConversionUnits.isCostUnit(
      this.props.conversion.selectedUnit
    );
    return (
      <Card
        id={this.props.id}
        className={cx(
          "popup photovoltaic-popup",
          { firstCalculationStep3: this.props.firstCalculationStep3 },
          { warning: this.props.invalidDataOptimizer && worse },
          { success: this.props.invalidDataOptimizer && better && !worse }
        )}
      >
        <Accordion.Toggle as={Card.Header} eventKey="12">
          <div
            className={
              "d-flex align-items-center pointer justify-content-between"
            }
          >
            <div className={"d-flex align-items-center"}>
              <IcomoonReact
                className={"custom-icon mr-2"}
                iconSet={iconSet}
                size={16}
                icon="electricity_icon-color"
              />
              <FormattedMessage
                id="hm.label.photovoltaic.electric_energy"
                defaultMessage=""
                tagName={"h3"}
              >
                {message => {
                  return <h4 className={"m-0"}>{message}</h4>;
                }}
              </FormattedMessage>
            </div>
            <FontAwesomeIcon
              icon="caret-down"
              className={cx("icon-caret-toggle ml-1", {
                reversed: !this.state.show,
              })}
            />
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="12">
          <Card.Body>
            <div className={"content row heatPopupMainGroup"}>
              <div
                className={cx("col-12 d-none", {
                  "warning-alert d-block":
                    this.props.insolation.shading &&
                    (this.props.insolation.buildings ||
                      this.props.insolation.trees_coniferous ||
                      this.props.insolation.trees_leafy ||
                      this.props.insolation.trees_mixed),
                })}
              >
                <FormattedMessage
                  id="hm.label.shading.pv.warning"
                  defaultMessage=""
                >
                  {message => <p className={"mb-0 d-inline"}>{message}</p>}
                </FormattedMessage>
              </div>
              <div className={cx("col-12")}>
                <div
                  className={cx(
                    "mb-1 d-flex align-items-center justify-content-between"
                  )}
                >
                  <FormattedMessage
                    id="hm.label.photovoltaic.electric_energy.usage.co"
                    defaultMessage=""
                  >
                    {message => (
                      <p
                        className={"mb-0 lh-100"}
                        style={{ maxWidth: "166px" }}
                      >
                        {message}
                      </p>
                    )}
                  </FormattedMessage>
                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={0}
                      max={1000}
                      step={1}
                      value={pvCalc.energyCo}
                      precision={0}
                      className={
                        "energy_usage_co photovoltaic-input medium-input "
                      }
                      disabled={true}
                    />
                    <div className={"text-small unit-small ml-1"}>kWh</div>
                  </div>
                </div>
                <div
                  className={cx(
                    "mb-1 d-flex align-items-center justify-content-between"
                  )}
                >
                  <FormattedMessage
                    id="hm.label.photovoltaic.electric_energy.usage.house"
                    defaultMessage=""
                  >
                    {message => (
                      <p className={"mb-0"}>
                        {message}
                        <FormattedMessage
                          id="hm.label.photovoltaic.electric_energy.usage.house.title"
                          defaultMessage=""
                        >
                          {message => (
                            <Popup
                              iconClassName="ml-2"
                              show
                              message={message}
                              placement={"left"}
                            />
                          )}
                        </FormattedMessage>
                      </p>
                    )}
                  </FormattedMessage>
                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={0}
                      max={99999}
                      step={1}
                      value={livingNeeds}
                      precision={0}
                      onChange={e => this.livingNeedsChanged(e)}
                      className={
                        "energy_usage_house photovoltaic-input medium-input "
                      }
                    />
                    <div className={"text-small unit-small ml-1"}>kWh</div>
                  </div>
                </div>

                <div
                  className={cx(
                    "mb-1 d-flex align-items-center justify-content-between"
                  )}
                >
                  <FormattedMessage
                    id="hm.label.photovoltaic.electric_energy.usage.sum"
                    defaultMessage=""
                  >
                    {message => <p className={"mb-0"}>{message}</p>}
                  </FormattedMessage>
                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={0}
                      max={1000}
                      step={1}
                      value={pvCalc.sum}
                      precision={0}
                      className={
                        "energy_usage_sum photovoltaic-input medium-input "
                      }
                      disabled={true}
                    />
                    <div className={"text-small unit-small ml-1"}>kWh</div>
                  </div>
                </div>

                <div
                  className={cx(
                    "d-flex mb-1 align-items-center justify-content-between"
                  )}
                >
                  <FormattedMessage
                    id="hm.label.photovoltaic.kwh_cost"
                    defaultMessage=""
                  >
                    {message => <p className={"mb-0"}>{message}</p>}
                  </FormattedMessage>
                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={0.01}
                      max={400}
                      step={0.01}
                      precision={2}
                      value={
                        costUnit && costInEuro
                          ? this.props.conversion.electricityPricePerKWh
                          : this.props.conversion.electricityPricePerKWhLocal
                      }
                      parse={e => e.replace(",", ".")}
                      onChange={e => this.props.electricityPriceChanged(e)}
                      className={"price photovoltaic-input  small-input "}
                      format={FormatNumericInput}
                    />
                    <div className={"text-small unit-small ml-1"}>
                      {this.props.conversion.currencyName}
                    </div>
                  </div>
                </div>
              </div>
              <FormattedMessage
                id="hm.label.photovoltaic"
                defaultMessage=""
                tagName={"h5"}
              >
                {message => {
                  return <h5 className={cx("col-12")}>{message}</h5>;
                }}
              </FormattedMessage>
              <div className={cx("col-4 pr-0 align-items-center mb-2")}>
                <FormattedMessage
                  id="hm.label.photovoltaic.installation.type"
                  defaultMessage=""
                >
                  {message => <p className={"mb-0 lh-100"}>{message}</p>}
                </FormattedMessage>
              </div>
              <div className={cx("col-8 align-items-center mb-2")}>
                <DropDownList
                  id="installationTypeDropDownList"
                  dataProvider={this.installationList}
                  labelField="name"
                  selectedIndex={this.props.pv.pv_type}
                  onChange={e => this.pvTypeChanged(parseInt(e))}
                />
              </div>
              <div
                className={cx(
                  "col-4 pr-0 align-items-center mb-2",
                  { "d-none": this.props.pv.pv_type !== 2 },
                  { "d-flex": !(this.props.pv.pv_type !== 2) }
                )}
              >
                <FormattedMessage
                  id="hm.label.photovoltaic.roof.orientation"
                  defaultMessage=""
                >
                  {message => <p className={"mb-0"}>{message}</p>}
                </FormattedMessage>
              </div>
              <div
                className={cx(
                  "col-8 align-items-center mb-2",
                  { "d-none": this.props.pv.pv_type !== 2 },
                  { "d-flex": !(this.props.pv.pv_type !== 2) }
                )}
              >
                <select
                  className={"roofDirectionListform-control"}
                  onChange={e =>
                    this.props.roofChanged("direction", e.target.value)
                  }
                  value={this.props.house.roofData.direction}
                >
                  {Directions.getDirections().map(value => {
                    if (
                      value !== Directions.NORTHWEST &&
                      value !== Directions.NORTHEAST &&
                      value !== Directions.NORTH
                    ) {
                      return (
                        <FormattedHTMLMessage
                          id={value}
                          key={value}
                          defaultMessage=""
                        >
                          {message => (
                            <option key={value} value={value}>
                              {" "}
                              {message}
                            </option>
                          )}
                        </FormattedHTMLMessage>
                      );
                    }
                    return false;
                  })}
                </select>
              </div>
              <div className={"col-12"}>
                <div
                  className={cx(
                    "mb-1 align-items-center justify-content-between",
                    { "d-none": this.props.pv.pv_type !== 2 },
                    { "d-flex": this.props.pv.pv_type === 2 }
                  )}
                >
                  <FormattedMessage
                    id="hm.label.roof.slope.angle"
                    defaultMessage=""
                  >
                    {message => <p className={"mb-0"}>{message}</p>}
                  </FormattedMessage>
                  <div
                    className={"d-flex align-items-center justify-content-end"}
                  >
                    <NumericInput
                      min={10}
                      max={40}
                      step={5}
                      value={this.props.house.roofData.angle}
                      onChange={e => this.props.roofChanged("angle", e)}
                      className={"windowsSurfaceN percent-input small-input"}
                    />
                    <p
                      className={cx(
                        "my-0 d-inline-block text-small  unit-small ml-1"
                      )}
                    >
                      <sup>o</sup>
                    </p>
                  </div>
                </div>
              </div>

              <FormattedMessage
                id="hm.label.photovoltaic.spec"
                defaultMessage=""
                tagName={"h5"}
              >
                {message => {
                  return (
                    <h5
                      className={cx("col-12", {
                        "d-none": this.props.pv.pv_type === 1,
                      })}
                    >
                      {message}
                    </h5>
                  );
                }}
              </FormattedMessage>

              <div
                className={cx("col-12", {
                  "d-none": this.props.pv.pv_type === 1,
                })}
              >
                <div
                  className={cx(
                    "mb-1 align-items-center justify-content-between",
                    { "d-flex": this.props.pv.pv_type !== 1 },
                    { "d-none": this.props.pv.pv_type === 1 }
                  )}
                >
                  <FormattedMessage
                    id="hm.label.photovoltaic.installation.pv_power"
                    defaultMessage=""
                  >
                    {message => <p className={"mb-0"}>{message}</p>}
                  </FormattedMessage>
                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={0}
                      max={200}
                      step={0.1}
                      precision={1}
                      parse={e => e.replace(",", ".")}
                      value={this.props.pv.pv_power}
                      onChange={e => this.pvPowerChanged(e)}
                      className={"pv_power photovoltaic-input small-input "}
                    />
                    <div className={"text-small unit-small ml-1"}>kWp</div>
                  </div>
                </div>
                <FormattedMessage
                  id="hm.label.photovoltaic.estimated_area"
                  defaultMessage=""
                >
                  {message => (
                    <p className={"mb-1 estimated_area text-center text-small"}>
                      {message} {parseInt(this.props.pv.pv_power * 7)} m
                      <sup>2</sup>
                    </p>
                  )}
                </FormattedMessage>
                <div
                  className={cx(
                    "mb-1 align-items-center justify-content-between",
                    { "d-flex": this.props.pv.pv_type !== 1 },
                    { "d-none": this.props.pv.pv_type === 1 }
                  )}
                >
                  <FormattedMessage
                    id="hm.label.photovoltaic.energy.produced"
                    defaultMessage=""
                  >
                    {message => <p className={"mb-0"}>{message}</p>}
                  </FormattedMessage>
                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={0}
                      max={1000}
                      step={1}
                      value={pvCalc.energyProduced}
                      precision={0}
                      className={
                        "energy_produced photovoltaic-input medium-input "
                      }
                      disabled={true}
                    />
                    <div className={"text-small unit-small ml-1"}>kWh</div>
                  </div>
                </div>
                <div
                  className={cx(
                    "mb-1 align-items-center justify-content-between",
                    { "d-flex": this.props.pv.pv_type !== 1 },
                    { "d-none": this.props.pv.pv_type === 1 }
                  )}
                >
                  <FormattedMessage
                    id="hm.label.photovoltaic.energy_network"
                    defaultMessage=""
                  >
                    {message => <p className={"mb-0 lh-100"}>{message}</p>}
                  </FormattedMessage>
                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={0}
                      max={100}
                      value={this.props.pv.energy_network}
                      onChange={e => this.pvChanged("energy_network", e)}
                      className={"percent-input small-input"}
                    />
                    <div className={"text-small unit-small ml-1"}>%</div>
                  </div>
                </div>
                <div
                  className={cx(
                    "mb-1 align-items-center justify-content-between",
                    { "d-flex": this.props.pv.pv_type !== 1 },
                    { "d-none": this.props.pv.pv_type === 1 }
                  )}
                >
                  <FormattedMessage
                    id="hm.label.photovoltaic.autoconsumption"
                    defaultMessage=""
                  >
                    {message => <p className={"mb-0 lh-100"}>{message}</p>}
                  </FormattedMessage>

                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={0}
                      max={100}
                      value={this.props.pv.autoconsumption}
                      onChange={e => this.pvChanged("autoconsumption", e)}
                      className={"percent-input small-input"}
                    />
                    <div className={"text-small unit-small ml-1"}>%</div>
                  </div>
                </div>

                <div
                  className={cx(
                    "mb-1 align-items-center justify-content-between",
                    { "d-flex": this.props.pv.pv_type !== 1 },
                    { "d-none": this.props.pv.pv_type === 1 }
                  )}
                >
                  <FormattedMessage
                    id="hm.label.photovoltaic.energy.available"
                    defaultMessage=""
                  >
                    {message => <p className={"mb-0"}>{message}</p>}
                  </FormattedMessage>
                  <div className={"d-flex align-items-center"}>
                    <NumericInput
                      min={0}
                      max={1000}
                      step={1}
                      precision={0}
                      value={pvCalc.basicEnergy}
                      className={
                        "energy_available photovoltaic-input medium-input "
                      }
                      disabled={true}
                    />
                    <div className={"text-small unit-small ml-1"}>kWh</div>
                  </div>
                </div>
                <FormattedMessage
                  id="hm.label.photovoltaic.energy.balance"
                  defaultMessage=""
                  tagName={"h5"}
                >
                  {message => {
                    return <h5 className={""}>{message}</h5>;
                  }}
                </FormattedMessage>

                {pvCalc.energyAvailable > 0 && (
                  <div
                    className={cx(
                      "mb-1 d-flex align-items-center justify-content-between"
                    )}
                  >
                    <FormattedMessage
                      id="hm.label.photovoltaic.energy.surplus"
                      defaultMessage=""
                    >
                      {message => (
                        <p className={"mb-0 text-success"}>{message}</p>
                      )}
                    </FormattedMessage>
                    <div className={"d-flex align-items-center"}>
                      <NumericInput
                        min={0}
                        max={1000}
                        step={1}
                        precision={0}
                        value={pvCalc.energyAvailable}
                        className={
                          "energy_surplus photovoltaic-input medium-input "
                        }
                        disabled={true}
                      />
                      <div className={"text-small unit-small ml-1"}>kWh</div>
                    </div>
                  </div>
                )}
                {pvCalc.energyToBuy > 0 && (
                  <div
                    className={cx(
                      "mb-1 d-flex align-items-center justify-content-between"
                    )}
                  >
                    <FormattedMessage
                      id="hm.label.photovoltaic.energy.buy"
                      defaultMessage=""
                    >
                      {message => (
                        <p className={"mb-0 text-danger"}>{message}</p>
                      )}
                    </FormattedMessage>
                    <div className={"d-flex align-items-center"}>
                      <NumericInput
                        min={0}
                        max={1000}
                        step={1}
                        precision={0}
                        value={pvCalc.energyToBuy}
                        className={
                          "energy_buy photovoltaic-input medium-input "
                        }
                        disabled={true}
                      />
                      <div className={"text-small unit-small ml-1"}>kWh</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={cx("row justify-content-center", {
                "d-none": !this.props.firstCalculationStep3,
              })}
            >
              <div
                className={"btn btn-primary"}
                onClick={() => this.toggleFirstCalculation()}
              >
                OK
              </div>
            </div>
            {this.props.invalidDataOptimizer && better && !worse && (
              <Thermoderm
                data={this.props.dataOptimizer}
                className={"photovoltaic-installation"}
                writeCache={() => this.props.writeCache()}
              />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}
const mapStateToProps = state => {
  const insolation = getInsolation(state);
  const house = getHouse(state);
  const persons = getPersons(state);
  const pv = getPV(state);
  const conversion = getConversion(state);
  return { insolation, persons, house, pv, conversion };
};
export default injectIntl(connect(mapStateToProps)(PhotovoltaicPopup));

import { VentilationFrequency } from "../../../constants/combo/VentilationFrequency";
import { VentilationMethod } from "../../../config/dictionary/model/VentilationMethod";
import { AirTightness } from "../../../config/dictionary/model/AirTightness";
import { VentilationReducer } from "./VentilationData";
import { fromWattsToKwhRatio } from "../../../components/Helpers/ConvertToUnit";
import { projectedCashFlowHM } from "../../../util/IrrCounter";
import { HouseTypeHelper } from "../../../util/HouseTypeHelper";
import { RecuperatorLocation } from "../../../constants/combo/RecuperatorLocation";
import { EnergyCalcHelper } from "../../calc/monthly/EnergyCalcHelper";

export function VentilationOptimizer(
  type,
  cancel,
  refDataContext,
  newDataContext,
  heatMasterController,
  reports,
  dictionaryConfig,
  isInRefState = false
) {
  const elementList =
    dictionaryConfig.ventilationTypeConfiguration.ventilationTypes;
  const ventData = refDataContext.houseData.ventilationData;
  const newHouseData = newDataContext.houseData;
  const buildingType = newHouseData.buildingType;
  const houseType = newHouseData.houseType;
  const personNumber = newHouseData.personNumber;
  const newElectricityPricePerKWh =
    newDataContext.conversionData.electricityPricePerKWhLocal;
  const refHeatingEff = refDataContext.conversionData.houseHeatingEfficiency;
  const refPricePerKWh =
    refDataContext.conversionData.pricePerKWh / refHeatingEff;
  const surface = newHouseData.surfaceData.totalSurface;

  const ventilation = VentilationMethod.getVentilationMethodById(
    ventData.ventilationMethodObject.id
  );
  let refEnergyData = heatMasterController.performCalculationForOptimizer(
    refDataContext
  );
  const { yearlyAggregatedConvertedData } = refEnergyData;
  const refVentEnergy =
    (yearlyAggregatedConvertedData["_enVent"] +
      yearlyAggregatedConvertedData["_enTightness"]) *
    fromWattsToKwhRatio();

  const refElectricEnergyData =
    (yearlyAggregatedConvertedData["_enRecuperator"] +
      yearlyAggregatedConvertedData["_enHumidification"] +
      yearlyAggregatedConvertedData["_enElectricVentilationEnergyLoss"]) *
    fromWattsToKwhRatio();

  let bestOption = {};
  let maxNPV = Number.MIN_SAFE_INTEGER;
  if (!ventilation.recuperator || buildingType === "new") {
    VentilationReducer().map(item => {
      const ventobj = VentilationMethod.getVentilationMethodById(
        item.ventilation
      );
      const atobj = AirTightness.getAirTightnessById(item.airTightness);

      newDataContext.houseData.ventilationData.ventilationMethodObject = ventobj;
      newDataContext.houseData.ventilationData.airTightness = atobj;
      newDataContext.houseData.ventilationData.n50 = item.n50;
      newDataContext.houseData.ventilationData.humidification =
        ventobj.humidification;

      const newEnergyData = heatMasterController.performCalculationForOptimizer(
        newDataContext
      );
      const { yearlyAggregatedConvertedData } = newEnergyData;
      const newVentEnergy =
        (yearlyAggregatedConvertedData["_enVent"] +
          yearlyAggregatedConvertedData["_enTightness"]) *
        fromWattsToKwhRatio();

      const newElectricEnergyData =
        (yearlyAggregatedConvertedData["_enRecuperator"] +
          yearlyAggregatedConvertedData["_enHumidification"] +
          yearlyAggregatedConvertedData["_enElectricVentilationEnergyLoss"]) *
        fromWattsToKwhRatio();

      const energySaved = (refData, newData) => Math.round(refData - newData);
      const electricEnergy = energySaved(
        refElectricEnergyData,
        newElectricEnergyData
      );
      const electricEnergyCost = electricEnergy * newElectricityPricePerKWh;
      const energyCost =
        refVentEnergy * refPricePerKWh - newVentEnergy * refPricePerKWh;

      const device = elementList.filter(dev => dev.id === item.ventilation)[0];

      const newCosts = selectProperDevice(
        ventData,
        device,
        houseType,
        surface,
        personNumber
      );

      let tightnessCostNew = 0;
      if (
        buildingType === "current" &&
        item.airTightness === "hm.tightness.standard_passive" &&
        ventData.airTightness.id !== "hm.tightness.standard_passive"
      ) {
        tightnessCostNew = 20 * surface + 2000;
      }

      const costs = newCosts.price + newCosts.work * surface + 600;
      const newNPV = projectedCashFlowHM(
        costs + tightnessCostNew,
        20,
        energyCost + electricEnergyCost,
        reports.irr,
        reports.irrPriceChange
      );

      if (maxNPV < newNPV) {
        maxNPV = newNPV;
        bestOption = item;
      }
      return item;
    });

    return {
      ventilation: !cancel
        ? bestOption.ventilation
        : ventData.ventilationMethodObject.id,
      airTightness: !cancel
        ? bestOption.airTightness
        : ventData.airTightness.id,
      co2Sensor: !cancel ? true : ventData.co2Sensor,
      gwcSet: !cancel ? false : ventData.gwcSet,
      isMechanical: !cancel
        ? true
        : ventilation.type === VentilationMethod.MECHANICAL,
      ventilationFreq: VentilationFrequency.STANDARD,
      n50: !cancel ? bestOption.n50 : ventData.n50,
    };
  }
}
const selectProperDevice = (
  ventData,
  devicePricesList,
  houseType,
  surface,
  personNumber
) => {
  let airVolume =
    EnergyCalcHelper.calcASHRAEStandardVentilationVolume(
      surface,
      personNumber
    ) * 3600;

  if (ventData.co2Sensor) {
    airVolume = 15.75 * personNumber;
  }

  let costs = {
    price: 0,
    work: 0,
  };
  const isMulti = HouseTypeHelper.isMultiFamilyType(houseType);

  if (typeof devicePricesList.versions !== "undefined") {
    for (let i = 0; i < devicePricesList.versions.length; i++) {
      const version = devicePricesList.versions[i];
      costs.work = version.work;
      if (!isMulti) {
        if (
          version.air_volume > airVolume &&
          typeof version.single_house !== "undefined" &&
          version.single_house
        ) {
          costs.price = version.price;
          break;
        }
      } else {
        if (
          ventData.recuperatorLocation === RecuperatorLocation.INDIVIDUAL &&
          typeof version.multi_house !== "undefined" &&
          version.multi_house
        ) {
          costs.price = version.price;
          break;
        } else if (
          ventData.recuperatorLocation === RecuperatorLocation.AGGREGATED &&
          typeof version.multi_house_aggregated !== "undefined" &&
          version.multi_house_aggregated
        ) {
          costs.work = version.work;
          costs.price = version.price * surface;
          break;
        }
      }
    }
  }
  return costs;
};

import { OptimizerResultsState } from "../OptimizerResultsState";

export class WindowOptimizer {
  constructor(
    isInReferenceState,
    dictionary,
    buildingType,
    refPricePerKWh,
    newPricePerKWh
  ) {
    this.elementList = dictionary;
    this.buildingType = buildingType;
    this.refPricePerKWh = refPricePerKWh;
    this.newPricePerKWh = newPricePerKWh;
    this.isInReferenceState = isInReferenceState;
  }
  elementList;

  validate = (
    type,
    result,
    element,
    newElement,
    surface,
    refCosts,
    newCosts
  ) => {
    result.state = OptimizerResultsState.THE_SAME;

    result.refItem = this.elementList.filter(
      item => item.id === element.windowsType.id
    )[0];
    result.newItem = this.elementList.filter(
      item => item.id === newElement.windowsType.id
    )[0];
    result.obsolescence = result.newItem.obsolescence;

    if (element.uValue < newElement.uValue) {
      result.state = OptimizerResultsState.WORSE;
    } else if (element.uValue > newElement.uValue) {
      result.state = OptimizerResultsState.BETTER;

      result.refPricePerKWh = this.refPricePerKWh;
      result.newPricePerKWh = this.newPricePerKWh;

      let materialCost = result.refItem.price;
      let newMaterialCost = result.newItem.price;
      let workCost = result.refItem.work;
      let newWorkCost = result.newItem.work;

      if (refCosts[type + "_material_changed"]) {
        materialCost = refCosts[type + "_material"];
      }
      if (newCosts[type + "_material_changed"]) {
        newMaterialCost = newCosts[type + "_material"];
      }
      if (refCosts[type + "_work_changed"]) {
        workCost = refCosts[type + "_work"];
      }
      if (newCosts[type + "_work_changed"]) {
        newWorkCost = newCosts[type + "_work"];
      }

      result.workPrice = this.isInReferenceState ? workCost : newWorkCost;
      result.materialPrice = this.isInReferenceState
        ? materialCost
        : newMaterialCost;

      result.work =
        this.buildingType === "current"
          ? this.calculateWork(newWorkCost, surface)
          : 0;
      result.material =
        this.buildingType === "current"
          ? this.calculateMaterial(newMaterialCost, surface)
          : this.calculateMaterialDifference(
              materialCost,
              newMaterialCost,
              surface
            );
      result.cost = result.work + result.material;
      result.costView =
        result.workPrice * surface + result.materialPrice * surface;
      result.service = 0;
    }

    return result;
  };

  calculateMaterial = (price, surface) => {
    return price * surface;
  };

  calculateWork = (work, surface) => {
    return work * surface;
  };

  calculateWorkDifference = (work, newWork, surface) => {
    return (newWork - work) * surface;
  };

  calculateMaterialDifference = (price, newPrice, surface) => {
    return newPrice * surface - price * surface;
  };
}

import { BaseComboObject } from "./BaseComboObject";

export class SolarCollectorType extends BaseComboObject {
  static items = [];
  static valueMap = [];

  static NONE_TYPE = 0;
  static FLAT_TYPE = 1;
  static VACUUM_TYPE = 2;

  _type;
  _efficiency;
  _unitSurface;
  _personPerCollector;

  constructor(id, type, efficiency, unitSurface, personPerCollector) {
    super(id);
    this._type = type;
    this._efficiency = efficiency;
    this._unitSurface = unitSurface;
    this._personPerCollector = personPerCollector;
    SolarCollectorType.items.push(this);
    SolarCollectorType.valueMap[id] = this;
  }

  static NONE = new SolarCollectorType(
    "1",
    SolarCollectorType.NONE_TYPE,
    0,
    1,
    0
  );
  static FLAT = new SolarCollectorType(
    "2",
    SolarCollectorType.FLAT_TYPE,
    0.3,
    1.0,
    1
  );
  static VACUUM = new SolarCollectorType(
    "3",
    SolarCollectorType.VACUUM_TYPE,
    0.5,
    2.0,
    2
  );

  static getSolarCollectorTypeById = id => {
    for (let i = 0; i < SolarCollectorType.items.length; i++) {
      let solarCollectorType = SolarCollectorType.items[i];
      if (solarCollectorType.id === id) {
        return solarCollectorType;
      }
    }
    return null;
  };

  isFlatCollector = () => this._type === SolarCollectorType.FLAT_TYPE;

  isVacuumCollector = () => this._type === SolarCollectorType.VACUUM_TYPE;

  get personPerCollector() {
    return this._personPerCollector;
  }

  get unitSurface() {
    return this._unitSurface;
  }

  get efficiency() {
    return this._efficiency;
  }

  get type() {
    return this._type;
  }
}
